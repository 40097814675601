<template>
  <div class="container">
    <h5 class="card-title">Please enter the client's details</h5>
    <div class="form-group">
      <label for="txtName">Name</label>
      <input
        type="text"
        class="form-control"
        :class="stateClass(nameValid)"
        id="txtName"
        placeholder="Name"
        v-model="client.name"
        :aria-invalid="!nameValid"
        aria-required="true"
      />
      <div class="invalid-feedback">Please provide a name</div>
    </div>
    <div class="form-group" v-if="introducerFeatureOn || introducerOptions.length > 0">
      <label for="txtRecommender">Introducer (optional, for wholesale)</label>
      <multiselect
        v-model="client.introducer"
        :options="introducerOptions"
        track-by="id" 
        label="name"
        :show-labels="false"
        placeholder="Pick the introducer. You can add new introducers on the wholesale page"
      >
      </multiselect>
    </div>
    <div class="form-group">
      <label for="txtDesc">Description (optional)</label>
      <textarea
        class="form-control"
        id="txtDesc"
        placeholder="Enter a description"
        rows="2"
        v-model="client.description"
      />
      <small id="txtDescHelp" class="form-text text-muted">A brief description of the client</small>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      client: {
        name: '',
        description: '',
        introducer: ''
      },
      isSubmitted: false,
      introducerOptions: [],
      introducerFeatureOn: false,
    };
  },
  computed: {
    nameValid() {
      const isValid = this.client.name.length > 2 && !this.$utils.str.isEmpty(this.client.name);
      this.$emit('nameChange', isValid);
      return isValid;
    }
  },
  async beforeMount() {
    let featureReq = await this.$api.utils.featureIntroducerBroker();
    this.introducerFeatureOn = featureReq.data;
    if (this.$debug) 
    {
      console.log('introducerFeatureOn', this.introducerFeatureOn);
    }

    if(this.introducerFeatureOn){
      this.getAllIntroducers();
    }
  },
  methods: {
    stateClass(computedItem) {
      if (!this.isSubmitted) return '';

      return computedItem ? 'is-valid' : 'is-invalid';
    },
    getAllIntroducers() {
      if (this.$debug) console.log('getAllIntroducers');
      this.$api.wholesale.getAllIntroducersForDropdown().then(res => {
        this.introducerOptions = res.data;
      });
    },
  },
  watch: {
    $route: 'fetchData'
  },
};
</script>

<style></style>
