<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="lay-card mt-5 mb-5">
          <div class="row text-center pt-3">
            <div class="col-sm">
              <h3>Create New Proposal & Client</h3>
            </div>
          </div>
          <div class="lay-card-top">
            <ClientDetailsForm
              ref="clientDetails"
              @nameChange="isNameValid" 
            />
          </div>
          <div>
            <VChooseTemplate
              header="Choose a template"
              :templates="templates"
              @onSelectedTemplate="onSelectedTemplate"
              v-if="nameValid"
            />
          </div>
          <div class="lay-card-bottom">
            <VEditButton class="eb-subtle text-danger" type="button" @click="handleCancel">Cancel</VEditButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientDetailsForm from '@broker/clientProposals/ui/ClientDetailsForm.vue';
import VChooseTemplate from '@broker/clientProposals/ui/VChooseTemplate.vue';

export default {
  components: {
    ClientDetailsForm,
    VChooseTemplate
  },
  data() {
    return {
      isSubmitted: false,
      templates: [],
      nameValid: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    isNameValid(isValid) {
      this.nameValid = isValid;
    },
    handleCancel() {
      this.$router.push(`/broker/clients`);
    },
    getAllIntroducers() {
      if (this.$debug) console.log('getAllIntroducers');
      this.$api.wholesale.getAllIntroducersForDropdown().then(res => {
        this.introducerOptions = res.data;
      });
    },
    async onSelectedTemplate(templateId){
      if(this.$debug) console.log('onSelectedTemplate', templateId)

      if(this.isSubmitted === true){
        return;
      }

      this.isSubmitted = true;
      if (!this.$refs.clientDetails.nameValid) {
         return;
      }

      const clientRes = await this.$api.company.postNewCompany(this.$refs.clientDetails.client);
      const clientId = clientRes.data;

      await this.$api.company.postAddFormToClient(clientId, templateId);

      const clientProposalsResponse = await this.$api.company.getProposalsForClient(clientId);
      const proposal = clientProposalsResponse.data.proposals[0]; // new client will only have 1 proposal at this point

      if (proposal && proposal.fullLink) {
        window.location.replace(proposal.fullLink);
      } else {
        this.$router.push({ path: `/broker/client/${clientId}/proposals` });
      }
    },
    fetchData() {
      this.$api.template.getAll()
        .then(res => {
          this.templates = res.data;
        });
    },
  }
};
</script>

<style></style>
