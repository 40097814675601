<template>
  <fieldset>
    <legend tabindex='-1' class='question-label col-form-label pt-0  bv-no-focus-ring'>{{question}}</legend>
    <div :id='elementId' role='radiogroup' tabindex='-1'>
      <div v-for='(option, index) in options' :key='index' tabindex='-1' class='bv-no-focus-ring custom-control custom-radio' >
        <input
          :id="optionId(index)"
          class="custom-control-input"
          type="radio"
          :value="option"
          v-model="selected"
          :name="elementId"
          autocomplete="off"
        />
        <label :for='optionId(index)' class='custom-control-label'>
          <span>{{option}}</span>
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script>

export default {
  props: {
    id: [Number, String],
    question: String,
    options: Array,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    selected: {
      get(){
        return this.$store.getters['editor/answer'](this.id);
      },
      set(value){
        let payload = {id: this.id, value: value}
        this.$store.commit('editor/setAnswer', payload)
      }
    }
  },
  methods: {
    optionId(idx) {
      return this.elementId + '_' + idx;
    }
  }
};
</script>

<style scoped>
</style>