export function visibleFilter(item, state) {
  if (!item.ifQ) {
    return true;
  }

  return isAnswerSet(state, item.ifQ.id, item.ifQ.option);
}

function isAnswerSet(state, id, expected) {
  if (state.answers[id] == null) {
    return false;
  }

  if (Array.isArray(state.answers[id])) {
    const answer = state.answers[id].indexOf(expected);
    return answer >= 0;
  }

  return state.answers[id] === expected;
}


export function cloneObject(editorJsObj) {
  const clone = JSON.parse(JSON.stringify(editorJsObj));
  return clone
}