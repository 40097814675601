import TableBlock from '@broker/blockFormEditor/ui/editor/contentEditable/table/table.js';
import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';

import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
import { createConditionalRenderTune } from '@broker/editorV3/tools/utils/conditionalRenderTune.js';

class eQTable {
  static get toolbox() {
    return {
      title: 'Table',
      icon: toolsInfo.find((tool) => tool.type === 'qtable').icon,
    };
  }

  constructor({ block, data, config, api, readOnly }) {
    const tableData = data.tableData ? data.tableData : {};
    this.qQuestion = new eQSimpleQuestion({ api, data, block });
    this.table = new TableBlock({ data: tableData, config, api, readOnly });

    this.isAddRowsEnabled = false;
    this.block = block;
    this.api = api;
    this.wrapper = document.createElement('div');
  }


  render() {
    const questionAndIconContainer = document.createElement('div');
    questionAndIconContainer.classList.add('question-icon-container-div');
    const { questionDiv, tableDiv } = this.createQuestionAndTableDivs();

    questionAndIconContainer.appendChild(questionDiv);
    this.wrapper.appendChild(questionAndIconContainer);
    this.wrapper.appendChild(tableDiv);

    return this.wrapper;
  }

  createQuestionAndTableDivs() {
    const tableDiv = this.table.render();
    tableDiv.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        e.stopPropagation();
      }
    });

    const questionDiv = this.qQuestion.render();
    questionDiv.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        const cell = tableDiv.querySelector('.tc-cell');
        cell.focus();
        e.preventDefault();
        e.stopPropagation();
      }
    });

    return {
      questionDiv,
      tableDiv,
    };
  }

  renderSettings() {
    const addOrRemoveRowsText = `<label class="addRemoveRowsLabel" style="cursor:pointer;margin-bottom:0;" title="Toggle add/remove rows">Toggle add/remove rows</label>`;

    const settings = [
      createConditionalRenderTune(this.api),
      {
        label: addOrRemoveRowsText,
        icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M192 64C86 64 0 150 0 256S86 448 192 448H384c106 0 192-86 192-192s-86-192-192-192H192zm192 96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>`,
        onActivate: () => {
          this.isAddRowsEnabled = !this.isAddRowsEnabled;
        },
      },
    ];

    return settings;
  }

  save(blockContent) {
    const questionBlock = blockContent.querySelector('.editor-question-block');

    const tableData = this.table.save();
    const questionData = this.qQuestion.save(questionBlock);

    tableData.content.forEach((row, index) => {
      row.forEach((cell, indexTwo) => {
        tableData.content[index][indexTwo] = cell.replaceAll('&nbsp;', '').trim();
      });
    });

    const saveData = {
      tableData,
      ...questionData,
      isAddRowsEnabled: this.isAddRowsEnabled,
    };

    return saveData;
  }

  static get isReadOnlySupported() {
    return true;
  }

  validate(savedData) {
    const checkValidity = savedData.tableData.content.map((row, index) => {
      row.map((_, indexTwo) => {
        if (savedData.tableData.content[index][indexTwo].includes('&nbsp;')) {
          return false;
        }
      });
    });
    return checkValidity;
  }
}

export default eQTable;
