export function percOfFormCompleted(form, answers, files, shouldDisplayFn) {
  let filled = 0;
  let empty = 0;

  for (let index = 0; index < form.sections.length; index++) {
    const curSection = form.sections[index];
    if (!shouldDisplayFn(curSection)) continue;

    if (curSection.sections) {
      curSection.sections.forEach(question => {
        let res = 'optional';
        if (question.question) {
          const answer = answers[question.id];
          res = questionIsCompleted(question, answer, files, shouldDisplayFn);
        } else if (question.type === 'subsection') {
          res = subQuestionIsCompleted(question, answers, files, shouldDisplayFn);
        }

        if (res === 'empty') {
          empty++;
        }else if (res === 'filled'){
          filled++;
        }
      });
    }
  }

  const total = filled + empty;
  if (total === 0)
    return 100;

  let perc = ((filled / total) * 100)

  // handle case where impossible to get 100%
  if(perc >= 96)
    return "100";

  return perc.toFixed(0);
}

function subQuestionIsCompleted(subsection, answers, files, shouldDisplayFn) {
  if (!shouldDisplayFn(subsection)) {
    return 'optional';
  }

  let atLeastOneFilled = false;

  for (let index = 0; index < subsection.sections.length; index++) {
    const subQuestion = subsection.sections[index];
    const answer = answers[subQuestion.id];
    let res = questionIsCompleted(subQuestion, answer, files, shouldDisplayFn);
    if (res === 'empty') {
      return 'empty';
    } else if (res === 'filled') {
      atLeastOneFilled = true;
    }
  }

  return atLeastOneFilled ? 'filled' : 'optional';
}

export function questionIsCompleted(question, answer, files, shouldDisplayFn) {
  if (!shouldDisplayFn(question)) {
    return 'optional';
  }
  if (question.optional && question.optional === true) {
    return 'optional';
  }
  if(question.type === 'qtable'){
    return 'optional';
  }

  let isEmpty = answer == null; // meant to be ==
  if(question.type === 'qupload'){
    isEmpty = !files.some((x) => x.questionId === question.id)
  }

  if (isEmpty) {
    return 'empty';
  } else {
    return 'filled';
  }
}
