// Libray imports
import Vue from 'vue';
import App from '@broker/App.vue';
import router from '@broker/router.js';
import store from '@broker/store/broker-store.js';
import BootstrapVue from 'bootstrap-vue';
import Toast from 'vue-toastification';
import VModal from 'vue-js-modal'
import VueSignaturePad from 'vue-signature-pad';


// Auth0
import { domain, clientId, audience } from "@config/auth_config.json";
import { Auth0Plugin } from '@shared/services/auth/auth.js';

// style sheets
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'animate.css/animate.css';
import "vue-toastification/dist/index.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";


// My imports
import { BrokerApisPlugin } from '@broker/services/api/apis.js';
import { UtilsApisPlugin } from '@shared/services/utils/utils.js';


// Register Global components
import '@shared/baseComponentReg.js'
import '@broker/brokerBaseComponentReg.js'
import PrintItemHolder from '@broker/puppet/questions/ui/PrintItemHolder.vue';


// Register global libraries
Vue.use(Toast, { position: "bottom-center" });
Vue.use(BootstrapVue);
Vue.use(VModal, { dialog: true })
Vue.use(VueSignaturePad);
Vue.component('PrintItemHolder', PrintItemHolder)

// Install the Auth0 authentication plugin
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.prototype.$debug = Object.freeze(process.env.NODE_ENV !== 'production');

// Setup $api & $http
Vue.use(BrokerApisPlugin)

// setup $utils
Vue.use(UtilsApisPlugin)

Vue.config.productionTip = false;

// init store
store.dispatch('features/INIT_FEATURES');

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
