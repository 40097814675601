import '@broker/editorV3/tools/utils/icon.css';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';

export default function createIcon(type) {
  const icon = document.createElement('div');

  const foundTool = toolsInfo.find((tool) => tool.type === type);

  icon.innerHTML = foundTool.icon;
  icon.classList.add('icon-next-to-block');
  return icon;
}
