import '@broker/editorV3/tools/index.css';

import { LayoutBlockTool } from 'editorjs-layout';
import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';

import EditorJS from '@editorjs/editorjs';
import BlockTune from '@broker/editorV3/tools/blockTune.js';
import { containerStyle } from '@broker/editorV3/tools/utils/editorConfig.js';
import { defaultTools } from '@broker/editorV3/tools/utils/editorConfig.js';

import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
import { cloneObject } from '@shared/store/utils/common.js';

const defaultData = {

  layout: {
      type: "container",
      id: "",
      className: "subsection-container",
      style: "\n  margin-top: 0.5rem;\n  margin-bottom: 2rem;\n  padding: 2rem;\n  box-shadow: 0px 0px 6px #B9C9FF;\n  ",
      children: [
          {
              type: "item",
              id: "",
              className: "subsection",
              style: "width:100%; display: inline-block; ",
              itemContentId: "1"
          }
      ]
  },
  itemContent: {
      1: {
          blocks: [
          ]
      }
  }
}

class eQSubsection {
  static get toolbox() {
    return {
      title: 'Subsection',
      icon: toolsInfo.find((tool) => tool.type === 'qsubsection').icon,
    };
  }

  constructor({ block, data, api, readOnly }) {
    this.block = block;

    this.qQuestion = new eQSimpleQuestion({ data, api, block });
  
    this.layoutConfig = this.setupLayoutConfig()
    this.layoutData = data.layout && data.itemContent ? cloneObject({layout:data.layout, itemContent:data.itemContent}) : defaultData;
    this.layout = new LayoutBlockTool({
      data: this.layoutData,
      config: this.layoutConfig,
      api,
      readOnly,
    });
  }


  render() {
    const questionAndIconContainer = document.createElement('div');
    questionAndIconContainer.classList.add('question-icon-container-div');
       
    const layoutDiv = this.layout.render();
    const questionDiv = this.qQuestion.render();

    const wrapper = document.createElement('div');

    questionAndIconContainer.appendChild(questionDiv);
    wrapper.appendChild(questionAndIconContainer);
    wrapper.appendChild(layoutDiv);

    document.addEventListener('subsection-changed', (event) => {
      if(event.detail.id === this.block.id) this.updateData(event.detail.data)
    });

    return wrapper;
  }

  updateData(data){
    this.layoutData.itemContent["1"].blocks = data.blocks
    this.block.dispatchChange()
  }

  save(blockContent) {
    const questionBlock = blockContent.querySelector('.editor-question-block');
    const questionData = this.qQuestion.save(questionBlock);
    const saveData = {
      ...cloneObject(this.layoutData),
      ...questionData,
    };

    return saveData;
  }

  validate(savedData){
    const hasItemsInSubsection = savedData.itemContent["1"].blocks.length > 0
    
    return hasItemsInSubsection;
  }

  static get isReadOnlySupported() {
    return true;
  }

  setupLayoutConfig(){
    return {
      EditorJS,
      editorJSConfig: this.setupEditorJSConfig(),
      enableLayoutEditing: false,
      enableLayoutSaving: true,
      initialData: {
        itemContent: {
          1: {
            blocks: [],
          },
        },
        layout: {
          type: 'container',
          id: '',
          className: 'subsection-container',
          style: containerStyle,
          children: [
            {
              type: 'item',
              id: '',
              className: 'subsection',
              style: 'width:100%; display: inline-block; ',
              itemContentId: '1',
            },
          ],
        },
      },
    };
  }

  setupEditorJSConfig(){
    return {
      defaultBlock: 'qshort',
      tools: {
        ...defaultTools,
        tune: { class: BlockTune, config: { isSubsection: true } },
      },
      tunes: ['tune'],
      onReady: () => {
        try {
          let toolEl = document.querySelector(`div[data-item-name="paragraph"]`);
          toolEl.style.display = 'none';
        } catch {
          return;
        }
      },
      onChange: async (api) =>{
        const editorJSData = await api.saver.save();
        document.body.dispatchEvent(new CustomEvent("subsection-changed", {bubbles: true, detail:{id:this.block.id, data:editorJSData}}) )
      }
    };
  }
}

export default eQSubsection;
