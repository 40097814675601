import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';

/**
 * Block tune class that swaps the selected block to the tool of choice
 *
 * @public
 */
export default class BlockTune {
  static get isTune() {
    return true;
  }
  constructor({ api, config }) {
    this.api = api;
    this.config = config;
    if (this.config.isSubsection) {
      this.toolsInfo = toolsInfo.filter((item) => {
        if(item.type === 'qsubsection' || item.type === 'section'){
          return false;
        }
        return true;
      });
    } else {
      this.toolsInfo = toolsInfo;
    }
  }

  render() {
    return this.toolsInfo.map((tool) => {
      return {
        icon: tool.icon,
        label: tool.label,
        closeOnActivate: true,
        onActivate: async () => {
          const index = parseInt(this.api.blocks.getCurrentBlockIndex());
          const block = this.api.blocks.getBlockByIndex(index);
          const blockData = await block.save();
          const type = tool.type;
          let data = blockData.data;

          if (blockData.tool === type) {
            return;
          }
          this.api.blocks.insert(type, data, {}, index, true, true);
        },
      };
    });
  }
}
