import { render, staticRenderFns } from "./VNotAllowed.vue?vue&type=template&id=07ca1c66&scoped=true"
import script from "./VNotAllowed.vue?vue&type=script&lang=js"
export * from "./VNotAllowed.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ca1c66",
  null
  
)

export default component.exports