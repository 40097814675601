const debug = process.env.NODE_ENV !== 'production';

import { findRecursively } from '@shared/store/utils/recursiveFind.js';
import questionsHelper from '@shared/components/questions/utils/questionsHelper.js';
import { visibleFilter } from '@shared/store/utils/common.js';

export function initialState() {
  return {
    answers: {}, // Dict for answers {1:'No'}
    form: { title: '', sections: [] }, // tree structure object for form
    meta: {}, 
    isLoading: false,
    formExists: false,
    tableOfContents: [],
    files: [],
    proposalUid: '',
    comments: [], // needs to be array so that changes to it are observed. Tried dictionary and changes not observed
    printAppendix: false, // If comments should be in line or Appendix section

  };
}
const state = initialState();

export const getters = {
  tableOfContents:(state) => state.tableOfContents,
  answer: (state) => (id) => {
    return state.answers[id];
  },
  uploadedFiles: (state) => (id) => {
    return state.files.filter((x) => x.questionId === id);
  },
  shouldDisplay: (state) => (id) => {
    var item = findRecursively(state.form, id);
    return visibleFilter(item, state);
  },
  shouldDisplayElem: (state) => (element) => {
    return visibleFilter(element, state);
  },
  filteredSections: (state) => {
    return state.form.sections.filter((x) => visibleFilter(x, state));
  },
  filteredItems: (state) => (id) => {
    var item = findRecursively(state.form, id);
    return item.sections.filter((x) => visibleFilter(x, state));
  },
  comments: (state) => (questionId) => {
    return state.comments.filter((x) => x.questionId === questionId);
  },
};

export const mutations = {
  setIsLoading(state){
    state.isLoading = true;
  },
  dbComments(state, payload) {
    if (debug) console.log('comment.dbComments ', payload);

    state.comments = payload;
  },
  setForm(state, payload) {
    if (debug) console.log('setForm ', payload);

    state.form = payload.form;
    state.answers = payload.answers;
    state.meta = payload.meta; // colour will be in meta.sidebarColour. If null default to #1985a1
    state.files = payload.files;
    state.formExists = true;
    state.isLoading = false;
    state.tableOfContents = questionsHelper.tableOfContents(state.form, (x) => visibleFilter(x, state));
  },
  setFormLoadError(state){
    state.isLoading = false;
    state.formExists = false;
    state.form = { title: '', sections: [] };
    state.meta = {};
    state.tableOfContents = [];
  },
  resetState (state) {
    Object.assign(state, initialState())
  },
  setMode(state, payload){
    if (debug) console.log('setMode triggered with', payload);
    state.proposalUid = payload.proposalUid;
  },
};

export const actions = {
  async INIT({ commit }, payload) {
    if (debug) console.log('INIT:', payload);

    commit('resetState');
    commit('setMode', payload);
    commit('setIsLoading');

    try {
      let res = await this._vm.$api.printApi.getProposalFill(state.proposalUid);
      commit('setForm', res.data);
      let comments = await this._vm.$api.printApi.getProposalComments(state.proposalUid)
      commit('dbComments', comments.data);

    } catch (err) {
      if (debug) console.log(err);
      if (err.response && err.response.status == 404){
        commit('setFormLoadError')
      }
      throw err;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
