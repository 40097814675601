<template>
  <div :id="elementId">
    <label :for="elementId + '_in'" class="question-label">{{ question }}</label>
    <div>
      <date-picker
        :id="elementId + '_in'"
        v-model="dateValue"
        type="date"
        value-type="format"
        format="DD-MMM-YYYY"
        @focus="commitFocus"
        :class="validatedClass"
      ></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { DatePicker },
  props: {
    id: [Number, String],
    question: String,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    dateValue: {
      get() {
        if (this.$debug) console.log('Date', this.$store.getters['fill/answer'](this.id));
        return this.$store.getters['fill/answer'](this.id);
      },
      set(value) {
        let payload = { id: this.id, value: value };
        this.$store.dispatch('fill/SAVE_ANSWER', payload);
      }
    },
    validatedClass() {
      return this.$store.getters['fill/validationState'](this.id);
    }
  },
  methods: {
    commitFocus() {
      this.$store.commit('fill/setFocus', this.id);
    }
  }
};
</script>

<style>
.is-invalid .mx-input {
  border-color: #f04124 !important;
}
.is-valid .mx-input {
  border-color: #28a745 !important;
}
</style>
