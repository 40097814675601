<template>
  <div class="form-group" v-on:click="notifyComponentClicked" :id="'preview' + proposalItem.type + proposalItem.id">
    <component :is="componentType" v-bind="proposalItem"></component>
    <h6 v-if="proposalItem.ifQ">Visible if *{{ proposalItem.ifQ.option }}* selected for question: '{{ elementTitle }}'</h6>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import renderHelper from '@broker/editorV3/utils/editorV3RenderHelper.js';

// These are common across displaying and editing
import VText from '@shared/components/questions/ui/VText.vue';
import VNone from '@shared/components/questions/ui/VNone.vue';

// Specific for displaying purposes.
// Decided to leave here so can see where these are used by doing a search
import VQShort from '@shared/components/editorQuestions/ui/VQShort.vue';
import VQYesNo from '@shared/components/editorQuestions/ui/VQYesNo.vue';
import VQLong from '@shared/components/editorQuestions/ui/VQLong.vue';
import VQTable from '@shared/components/editorQuestions/ui/VQTable.vue';
import VQOption from '@shared/components/editorQuestions/ui/VQOption.vue';
import VQDate from '@shared/components/editorQuestions/ui/VQDate.vue';
import VQCheck from '@shared/components/editorQuestions/ui/VQCheck.vue';
import VQSelect from '@shared/components/editorQuestions/ui/VQSelect.vue';
import VQUpload from '@shared/components/editorQuestions/ui/VQUpload.vue';
import VQSign from '@shared/components/editorQuestions/ui/VQSign.vue';
import VNotAllowed from '@shared/components/questions/ui/VNotAllowed.vue';
import VQCompanyHouseName from '@shared/components/editorQuestions/ui/VQCompanyHouseName.vue';
import VQCharityName from '@shared/components/editorQuestions/ui/VQCharityName.vue';
import VQNumber from '@shared/components/editorQuestions/ui/VQNumber.vue';

import EV3VQuestionSubSection from '@broker/editorV3/ui/render/EV3VQuestionSubSection.vue';
import VSectionTitle from '@shared/components/questions/ui/VSectionTitle.vue';

import scrollMeTo from '@shared/components/stepper/utils/scrollMeTo.js';

export default {
  name: 'EV3EditorViewItemHolder',
  components: {
    VNone,
    VText,
    VQShort,
    VQYesNo,
    VQLong,
    VQTable,
    VQOption,
    VQDate,
    VQCheck,
    VQSelect,
    VQUpload,
    VQSign,
    EV3VQuestionSubSection,
    VSectionTitle,
    VNotAllowed,
    VQCompanyHouseName,
    VQCharityName,
    VQNumber,
  },
  props: {
    proposalItem: Object,
    allowSections: Boolean,
  },
  computed: {
    ...mapGetters('editorV3', ['getQuestionInDbRepresentation']),
    componentType() {
      if (!this.allowSections && (this.proposalItem.type === 'section' || this.proposalItem.type === 'subsection')) {
        return 'VNotAllowed';
      }

      return renderHelper.component(this.proposalItem.type);
    },
    elementTitle() {
      let dependentQuestion = this.getQuestionInDbRepresentation(this.proposalItem.ifQ.id);
      return dependentQuestion.question;
    },
  },
  methods: {
    notifyComponentClicked() {
      if (this.$debug) console.log('Scrolling to', this.proposalItem.type);
      scrollMeTo.onEditor('editor' + this.proposalItem.type + this.proposalItem.id);
    },
  },
};
</script>

<style scoped>
h6 {
  font-size: 85%;
  font-style: italic;
}
</style>
