<template>
  <div v-if="willRestrict">
    <VBrokerRestrictCard :restrictedEmails="restrictedEmails" @ok="onRestrict" @cancel="$emit('close')"></VBrokerRestrictCard>
  </div>
  <div v-else>
    <VBrokerUnrestrictCard @ok="onUnrestrict" @cancel="$emit('close')"></VBrokerUnrestrictCard>
  </div>  
</template>

<script>
import VBrokerRestrictCard from '@broker/clientProposals/ui/VBrokerRestrictCard.vue';
import VBrokerUnrestrictCard from '@broker/clientProposals/ui/VBrokerUnrestrictCard.vue';

export default {  
  components: {
    VBrokerRestrictCard,
    VBrokerUnrestrictCard
  },
  props: {
    lockStatus: Boolean,
    name: String,
    proposalId: Number,
    restrictedEmails:Array
  },
  computed: {
    restrictHeaderTxt() {
      return this.willRestrict === true ? "You will restrict access to the factfinder" : "Anyone with the link will be able access to the factfinder"
    },
    willRestrict(){
      return this.lockStatus === false;
    }
  },
  methods: {
    onRestrict(){
      if (this.$debug) console.log('onRestrict');

      this.toggleClientLock(this.proposalId, true);
      this.$emit('close')
    },
    onUnrestrict(){
      if (this.$debug) console.log('onRestrict');
      
      this.toggleClientLock(this.proposalId, false);
      this.$emit('close')
    },
    toggleClientLock(proposalId, newState) {
      this.$api.restrictApi.postRestrictProposal(proposalId, newState);
    },
  },
};
</script>
<style>
</style>
