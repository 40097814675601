import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
class eQDate extends eQSimpleQuestion {
  static get toolbox() {
    return {
      title: 'Date',
      icon: toolsInfo.find((tool) => tool.type === 'qdate').icon,
    };
  }
}

export default eQDate;
