<template>
  <p :id='elementId'>{{textComputed}}</p>
</template>

<script>
export default {
  props: {
    id: [Number, String],
    text: String,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    textComputed(){
      return this.text ? this.text : 'Please enter text...'
    }
  }
};
</script>

<style scoped>
</style>