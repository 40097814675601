<template>
  <div class='container'>
    <div class='lay-options-right'>
      <!-- <VEditButton class='eb-sec' @click='onAddForm'>New Template</VEditButton> -->
      <VEditButton class='eb-sec' @click='onAddAITemplate'>AI Template Upload</VEditButton>
    </div>
    <hr />
    <div class='row text-center pt-3'>
      <div class='col-sm'>
        <h3>Form Templates Library</h3>
      </div>
    </div>
    <div class='list-group pt-3'>
      <VFormTemplateListItem v-for='form in forms' :key='form.id' v-bind='form' @delete='onDelete(form.id)' :prefillMapsFeatureOn="prefillMapsFeatureOn"></VFormTemplateListItem>
    </div>
    <v-dialog></v-dialog>
  </div>
</template>

<script>
import VFormTemplateListItem from '@broker/formsLibrary/ui/VFormTemplateListItem.vue';
import deleteTemplateDialog from '@broker/formsLibrary/ui/deleteTemplateDialog.js'

export default {
  components: {
    VFormTemplateListItem,
  },
  data() {
    return {
      forms: [],
      prefillMapsFeatureOn: false
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    async fetchData() {
      let featReq = this.$api.utils.featurePrefillMaps()
      
      let templateRes = await this.$api.template.getAll()
      this.forms = templateRes.data; 
      let featRes = await featReq;
      this.prefillMapsFeatureOn = featRes.data;
    },
    onAddForm() {
      this.$router.push({ path: '/broker/proposalwriter/new' });
    },
    onAddAITemplate() {
      this.$router.push({ path: '/broker/aiTemplate/new' });
    },
    onDelete(id){
      deleteTemplateDialog(this.$modal, this.$api, id, () => {
            this.fetchData();
        });
    }
  }
};
</script>

<style>
</style>
