<template>
  <div class="container">
    <div class="lay-options-both">
      <VEditButton class="eb-fade" @click="onBack">Back</VEditButton>
      <VEditButton class="eb-success" @click="onCreateRenewal">Create Renewal</VEditButton>
    </div>
    <hr />
    <div v-if="this.compareResults.added.length > 0">
      <h3>Added Questions: {{ this.compareResults.added.length }}</h3>
      <div>
        <span v-for="item in this.compareResults.added" :key="item.id" class="question-ins question-line">
          {{ item.question }}
        </span>
      </div>
    </div>
    <div v-if="this.compareResults.removed.length > 0" class="mt-5">
      <h3>Removed Questions: {{ this.compareResults.removed.length }}</h3>
      <div>
        <span v-for="item in this.compareResults.removed" :key="item.id" class="question-del question-line">
          {{ item.question }}
        </span>
      </div>
    </div>
    <div v-if="this.compareResults.changed.length > 0" class="mt-5">
      <h3>Changed Questions: {{ this.compareResults.changed.length }}</h3>
      <div>
        <div v-for="item in this.compareResults.changed" :key="item.id">
          <span class="question-del question-line">{{ item.prop }}</span>
          <span class="question-ins question-line">{{ item.template }}</span>
          <span class="question-line"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import diff from '@broker/clientProposals/utils/diff.js';

export default {
  props: ['proposalId', 'templateId'],
  data() {
    return {
      compareResults: {added:[], removed:[], changed:[]}
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    async fetchData() {
      if (this.$debug) console.log('renewal', this.proposalId, this.templateId);

      const propReq = this.$api.getProposalApi().getProposalQuestions(this.proposalId);
      const templateReq = this.$api.template.getTemplate(this.templateId);

      let propQuestionsStr = (await propReq).data;
      let templateQuestionsStr = (await templateReq).data.form;

      if (this.$debug) console.log('onSelectedTemplate res', propQuestionsStr, templateQuestionsStr);
      this.compareResults = diff(propQuestionsStr, templateQuestionsStr);
    },
    onBack() {
      this.$router.go(-1);
    },
    async onCreateRenewal() {
      let res = await this.$api.getProposalApi().postRenewal(this.proposalId, this.templateId)
      if (this.$debug) console.log('Created Propsal with id', res.data.id);
      this.$router.push({ path: `/broker/client/${res.data.companyId}/proposals` });
    }
  }
};
</script>
<style scoped>
.question-ins {
  background-color: #dfd;
  border-color: #b4e2b4;
}
.question-del {
  background-color: #fee8e9;
  border-color: #e9aeae;
}
.question-line {
  word-wrap: normal;
  display: inline-block;
  padding: 0;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  vertical-align: middle;
  white-space: pre;
  width: 100%;
  font-family: Menlo, Consolas, monospace;
  font-size: 13px;
}
</style>
