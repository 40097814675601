<template>
  <h5 :id='elementId' style='color:red' class="mt-2 mb-2">{{msg}}</h5>
</template>

<script>
export default {
  props: {
    id: [Number, String],
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    msg(){
      return this.type === 'section'? 'A Section is not allowed to be within subsection. You can directly edit the title of the subsection':'A Subsection is not allowed to be within another subsection'
    }
  }
};
</script>

<style scoped>
</style>