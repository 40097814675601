import { IconTable } from '@codexteam/icons';

const toolsInfo = [
  {
    type: 'section',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M9 7L9 12M9 17V12M9 12L15 12M15 7V12M15 17L15 12"></path></svg>`,
    label: 'Heading',
  },
  {
    type: 'qshort',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M896 224H128c-35.2 0-64 28.8-64 64v448c0 35.2 28.8 64 64 64h768c35.2 0 64-28.8 64-64V288c0-35.2-28.8-64-64-64z m0 480c0 19.2-12.8 32-32 32H160c-19.2 0-32-12.8-32-32V320c0-19.2 12.8-32 32-32h704c19.2 0 32 12.8 32 32v384z" fill="#333333"/><path d="M224 352c-19.2 0-32 12.8-32 32v256c0 16 12.8 32 32 32s32-12.8 32-32V384c0-16-12.8-32-32-32z" fill="#333333"/></svg>',
    label: 'Short',
  },
  {
    type: 'qlong',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5rem; height: 1.5rem;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M896 160H128c-35.2 0-64 28.8-64 64v576c0 35.2 28.8 64 64 64h768c35.2 0 64-28.8 64-64V224c0-35.2-28.8-64-64-64z m0 608c0 16-12.8 32-32 32H160c-19.2 0-32-12.8-32-32V256c0-16 12.8-32 32-32h704c19.2 0 32 12.8 32 32v512z" fill="#333333"/><path d="M224 288c-19.2 0-32 12.8-32 32v256c0 16 12.8 32 32 32s32-12.8 32-32V320c0-16-12.8-32-32-32z m608 480c19.2 0 32-12.8 32-32V608L704 768h128z" fill="#333333"/></svg>',
    label: 'Multiline',
  },
  {
    type: 'qnumber',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M896 224H128c-35.2 0-64 28.8-64 64v448c0 35.2 28.8 64 64 64h768c35.2 0 64-28.8 64-64V288c0-35.2-28.8-64-64-64z m0 480c0 19.2-12.8 32-32 32H160c-19.2 0-32-12.8-32-32V320c0-19.2 12.8-32 32-32h704c19.2 0 32 12.8 32 32v384z"/><path d="M291.2 412.8c-12.8 6.4-22.4 12.8-35.2 16v41.6c22.4-6.4 41.6-16 54.4-28.8V640h38.4V393.6H320c-6.4 3.2-19.2 12.8-28.8 19.2zM489.6 560c22.4-16 41.6-28.8 48-38.4 16-16 22.4-38.4 22.4-57.6 0-22.4-6.4-38.4-22.4-54.4-16-12.8-35.2-19.2-57.6-19.2-25.6 0-48 9.6-60.8 25.6-16 16-22.4 38.4-22.4 67.2h38.4c0-19.2 3.2-35.2 12.8-44.8 6.4-9.6 19.2-16 32-16s25.6 3.2 32 9.6c6.4 6.4 9.6 16 9.6 28.8s-6.4 25.6-16 38.4c-6.4 6.4-16 16-32 28.8-28.8 19.2-44.8 35.2-54.4 44.8-16 19.2-22.4 41.6-22.4 64h166.4v-35.2H444.8c6.4-12.8 22.4-25.6 44.8-41.6z m233.6-54.4c25.6-9.6 38.4-25.6 38.4-54.4 0-22.4-6.4-38.4-22.4-51.2-16-12.8-35.2-19.2-57.6-19.2S640 387.2 624 400c-16 12.8-25.6 32-25.6 57.6h38.4c0-16 6.4-25.6 12.8-32 6.4-6.4 19.2-9.6 32-9.6s25.6 3.2 32 9.6c6.4 6.4 9.6 16 9.6 28.8s-3.2 22.4-9.6 28.8c-6.4 6.4-19.2 9.6-32 9.6h-16v28.8h16c16 0 25.6 3.2 35.2 9.6 9.6 6.4 12.8 16 12.8 32 0 12.8-3.2 22.4-12.8 28.8-9.6 9.6-19.2 12.8-35.2 12.8-12.8 0-25.6-3.2-32-12.8-9.6-9.6-12.8-22.4-16-38.4h-38.4c3.2 28.8 12.8 48 28.8 64 16 12.8 35.2 19.2 60.8 19.2 25.6 0 48-6.4 64-22.4 16-16 22.4-32 22.4-54.4 0-16-3.2-25.6-12.8-35.2-9.6-6.4-22.4-16-35.2-19.2z"/></svg>',
    label: 'Number',
  },
  {
    type: 'qyn',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="yes-no-icon" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg><svg xmlns="http://www.w3.org/2000/svg" class="yes-no-icon" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg>',
    label: 'Yes/No',
  },
  {
    type: 'qdate',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M256 192H160A96 96 0 0 0 64 288V320h896v-32A96 96 0 0 0 864 192H768v32a32 32 0 1 1-64 0V192H320v32a32 32 0 0 1-64 0V192z m64-64h384v-32a32 32 0 1 1 64 0V128h96A160 160 0 0 1 1024 288v512a160 160 0 0 1-160 160h-704A160 160 0 0 1 0 800v-512A160 160 0 0 1 160 128H256v-32a32 32 0 0 1 64 0V128z m640 256H64v416A96 96 0 0 0 160 896h704a96 96 0 0 0 96-96V384zM288 576a32 32 0 0 1 0-64h448a32 32 0 1 1 0 64h-448z m0 192a32 32 0 1 1 0-64h448a32 32 0 1 1 0 64h-448z"/></svg>',
    label: 'Date',
  },
  {
    type: 'qupload',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em;height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M400 317.7h73.9V656c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V317.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 163c-3.2-4.1-9.4-4.1-12.6 0l-112 141.7c-4.1 5.3-0.4 13 6.3 13z"/><path d="M878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"/></svg>',
    label: 'Upload',
  },
  {
    type: 'qsign',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M760.96 256h-256L288 538.56 526.4 928A137.6 137.6 0 0 0 608 987.84V644.48a103.36 103.36 0 0 1-64-90.24 96 96 0 1 1 192 0 103.68 103.68 0 0 1-64 90.24v343.36A138.88 138.88 0 0 0 754.24 928L992 538.56zM832 192l32-160H416l32 160zM376.96 896h-288a48 48 0 0 0 0 96h288a48 48 0 1 0 0-96z" fill="#231F20"/></svg>',
    label: 'Signature',
  },
  {
    type: 'text',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M554.666667 256v640h-85.333334V256H213.333333V170.666667h597.333334v85.333333z"/></svg>',
    label: 'Text',
  },
  {
    type: 'qcompanyhousename',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 0C21.5 0 0 21.5 0 48V464c0 26.5 21.5 48 48 48h96V432c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H48zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm112-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM80 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16zm80 16c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V112zM272 96h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16z"/></svg>',
    label: 'Company House Search',
  },
  {
    type: 'qcharityname',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M148 76.6C148 34.3 182.3 0 224.6 0c20.3 0 39.8 8.1 54.1 22.4l9.3 9.3 9.3-9.3C311.6 8.1 331.1 0 351.4 0C393.7 0 428 34.3 428 76.6c0 20.3-8.1 39.8-22.4 54.1L302.1 234.1c-7.8 7.8-20.5 7.8-28.3 0L170.4 130.7C156.1 116.4 148 96.9 148 76.6zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z"/></svg>',
    label: 'Charity Commission Search',
  },
  {
    type: 'qoption',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M782.848 162.816h-512a128 128 0 0 0-128 128v512a128 128 0 0 0 128 128h512a128 128 0 0 0 128-128v-512a128 128 0 0 0-128-128z m76.8 640a76.8 76.8 0 0 1-76.8 76.8h-512a76.8 76.8 0 0 1-76.8-76.8v-512a76.8 76.8 0 0 1 76.8-76.8h512a76.8 76.8 0 0 1 76.8 76.8z" fill=""/><path d="M731.648 358.4h-409.6a25.6 25.6 0 0 0 0 51.2h409.6a25.6 25.6 0 0 0 0-51.2zM322.048 576.512h409.6a25.6 25.6 0 0 0 0-51.2h-409.6a25.6 25.6 0 0 0 0 51.2zM731.648 693.76h-409.6a25.6 25.6 0 0 0 0 51.2h409.6a25.6 25.6 0 0 0 0-51.2z" fill=""/></svg>',
    label: 'Multiple Choice',
  },
  {
    type: 'qcheck',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M896 0H128a128 128 0 0 0-128 128v768a128 128 0 0 0 128 128h768a128 128 0 0 0 128-128V128a128 128 0 0 0-128-128z m42.666667 896a42.666667 42.666667 0 0 1-42.666667 42.666667H128a42.666667 42.666667 0 0 1-42.666667-42.666667V128a42.666667 42.666667 0 0 1 42.666667-42.666667h768a42.666667 42.666667 0 0 1 42.666667 42.666667z"/><path d="M479.957333 192a53.376 53.376 0 0 0-74.666666 10.666667L314.24 324.266667l-20.565333-20.522667a53.333333 53.333333 0 0 0-75.392 75.264l64 64a53.845333 53.845333 0 0 0 80.384-5.717333l128-170.666667a53.333333 53.333333 0 0 0-10.709334-74.624zM479.957333 576a53.376 53.376 0 0 0-74.666666 10.666667L314.24 708.266667l-20.565333-20.522667a53.333333 53.333333 0 1 0-75.392 75.434667l64 64a53.973333 53.973333 0 0 0 80.384-5.888l128-170.666667a53.333333 53.333333 0 0 0-10.709334-74.624zM789.333333 330.624H597.333333a53.333333 53.333333 0 0 0 0 106.666667h192a53.333333 53.333333 0 0 0 0-106.666667zM789.333333 671.957333H597.333333a53.333333 53.333333 0 0 0 0 106.666667h192a53.333333 53.333333 0 1 0 0-106.666667z"/></svg>',
    label: 'Checklist',
  },

  {
    type: 'qselect',
    icon: '<svg xmlns="http://www.w3.org/2000/svg" class="svg-icon" style="width: 1.5em; height: 1.5em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1"><path d="M680.1408 414.976c9.9328-8.704 24.2176-6.656 31.8976 4.608a27.8016 27.8016 0 0 1-4.096 35.84l-172.032 149.76a35.6352 35.6352 0 0 1-47.8208 0l-172.032-149.7088a27.8016 27.8016 0 0 1-4.096-35.9424c7.68-11.1616 22.016-13.2096 31.8976-4.608L512 561.3056l168.1408-146.2784z"/></svg>',
    label: 'Dropdown',
  },

  {
    type: 'qtable',
    icon: IconTable,
    label: 'Table',
  },

  {
    type: 'qsubsection',
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512">
      <rect x="48" y="48" width="176" height="176" rx="20" ry="20" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/>
      <rect x="288" y="48" width="176" height="176" rx="20" ry="20" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/>
      <rect x="48" y="288" width="176" height="176" rx="20" ry="20" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/>
      <rect x="288" y="288" width="176" height="176" rx="20" ry="20" style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:32px"/></svg>`,
    label: 'Subsection',
  },
];

export default toolsInfo;
