import { autoNumberBlocks, createOuterObj, removeNumberFromBlocks } from '@broker/editorV3/utils/autonumber.js';
import { cloneObject } from '@shared/store/utils/common.js';
import { flattenSections, cleanForSave, expandSections } from '@broker/store/formEditorStoreFns.js';
import { convertToEditorjs } from '@broker/editorV3/utils/formatToEditorJs.js';
import { convertToDbRepresentation } from '@broker/editorV3/utils/convertToDbRepresentatation.js';

import { $api } from '@broker/services/api/apis.js';

const debug = process.env.NODE_ENV !== 'production';

function initialState() {
  return {
    dbId: 0,
    saved: true,
    displayTitle: '',
    formName: '',
    editorJSData: {
      blocks: [],
    },
    ifQs: [],
    updateEditorView: false,  
    dbRepresentation: {
      sections: [],
    },
    lastDbForm: null,
  };
}

const state = initialState();

const getters = {
  getForm: (state) => {
    if (debug) console.log('getForm', state.dbRepresentation);
    return state.dbRepresentation;
  },

  // Alom This was nicely done. Better than my method
  getAllItemsOnFormWithNoHierachy: (state) => {
    return removeSubsectionBlocks(state.dbRepresentation.sections);
  },
  getSelectableQuestions: (_, getters) => (id) => {
    let itemsNoHierachy = getters.getAllItemsOnFormWithNoHierachy;
    // remove everything after the index of our item
    let indexOfId = itemsNoHierachy.findIndex((x) => x.id === id);
    let questionsBeforeId = itemsNoHierachy.slice(0, indexOfId);

    let selectableQuestions = [];
    for (let i = 0; i < questionsBeforeId.length; i++) {
      const item = questionsBeforeId[i];
      // duplicating this line from questionWithSelection. Can refactor into questionTypes once that code goes and we see if we need it
      if(item.type === 'qoption' || item.type === 'qcheck' || item.type === 'qselect' || item.type === 'qyn' ){
        selectableQuestions.push(item);
      }
    }
    return selectableQuestions;
  },
  getQuestionInDbRepresentation: (_, getters) => (id) => {
    let allQuestions = getters.getAllItemsOnFormWithNoHierachy;
    var result = allQuestions.find(x => x.id === id);
    return result;
  },
};

const mutations = {
  setEditorJSData(state, payload) {
    // Need to update both EditorJs & dbRepresentation
    state.editorJSData = payload;
    state.dbRepresentation = convertToDbRepresentation(payload.blocks, state.ifQs);
    state.updateEditorView = true;
    if (debug) console.log('setEditorJSDataMutation', state.editorJSData, state.dbRepresentation);
  },
  setFromDbForm(state, payload) {
    // Need to update both EditorJs & dbRepresentation
    state.editorJSData = payload.editorJSData;
    state.dbRepresentation = payload.dbRepresentation;
    state.ifQs = payload.ifQs;
    state.updateEditorView = true;
    if (debug) console.log('setFromDbForm', state.editorJSData, state.dbRepresentation, state.ifQs);
  },
  addIfQToStore(state, payload){
    if (debug) console.log('addIfQToStore', payload);
    if(!payload.targetId || !payload.triggerOption || !payload.triggerId )
      throw "Missing a parameter";

    const alreadyExistsIdx = state.ifQs.findIndex((ifQ) => ifQ.targetId === payload.targetId)
    
    if(alreadyExistsIdx >= 0){
      state.ifQs.splice(alreadyExistsIdx, 1)
    }
    
    state.ifQs.push(payload);
    state.dbRepresentation = convertToDbRepresentation(state.editorJSData.blocks, state.ifQs);
    if (debug) console.log('addIfQToStore', state.ifQs, state.dbRepresentation);
  },
  removeIfQs(state, targetId) {
    const found = this.ifQs.find((element) => element.targetId === targetId);
    if (found) {
      const indexToRemove = this.ifQs.indexOf(found);
      state.ifQs.splice(indexToRemove, 1);
      state.dbRepresentation = convertToDbRepresentation(state.editorJSData.blocks, state.ifQs);
      if (debug) console.log('removeIfQs', state.ifQs, state.dbRepresentation);
    }
  },
  setDbId(state, id) {
    state.dbId = id;
  },
  setSaved(state, payload) {
    state.saved = payload.saved;
  },
  resetState(state) {
    if (debug) console.log('resetState');
    Object.assign(state, initialState());
  },
  updateTitle(state, value) {
    if (debug) console.log('updateTitle', value);
    state.displayTitle = value;
  },
  updateFormName(state, value) {
    if (debug) console.log('updateFormName', value);
    state.formName = value;
  },
  setFormMeta(state, payload) {
    if (debug) console.log('setFormMeta triggered with ', payload);

    state.displayTitle = payload.meta.displayTitle;
    state.formName = payload.meta.name;
  },
  setLastDbForm(state, payload) {
    if (debug) console.log('setLastDbForm triggered with ', payload);
    
    let clone = cloneObject(payload.form);
    let flattened = flattenSections(clone.sections);

    state.lastDbForm = {sections: flattened};
  },
  setUpdateEditorView(state, payload){
    if(debug) console.log("Updating editor view", payload)
    state.updateEditorView = payload;
  },
};

const actions = {
  async SAVE_TO_STORE({ commit }, payload) {
    commit('setEditorJSData', payload);
  },
  async INIT_DB_TEMPLATE({ commit, dispatch }, formId) {
    if (debug) console.log('INIT_DB_TEMPLATE:', formId);

    commit('resetState');
    commit('setDbId', formId);
    
    await $api.template.getTemplate(formId).then((res) => {
      commit('setFormMeta', res.data);
      commit('setLastDbForm', res.data);
      dispatch('CHANGE_TO_EDITOR_FORMAT', res.data.form);
    });
  },

  async INIT_NEW_TEMPLATE({ commit, dispatch }) {
    if (debug) console.log('INIT_NEW_TEMPLATE');

    commit('resetState');

    await $api.template
      .getDefault()
      .then((res) => {
        commit('setFormMeta', res.data);
        dispatch('CHANGE_TO_EDITOR_FORMAT', res.data.form);
      })
      .catch((err) => {
        if (err.response && err.response.status == 404) {
          commit('setFormLoadError');
        }
      });
  },

  INIT_UPLOAD({ commit, dispatch }, uploadData) {
    if (debug) console.log('INIT:', uploadData);

    // We do not reset state in this case as we want to keep lastDbForm
    commit('setFormMeta', uploadData);
    dispatch('CHANGE_TO_EDITOR_FORMAT', uploadData.form);
  },

  async SAVE_TO_DATABASE({ state, commit, getters }) {
    if (debug) console.log('SAVE', state.dbId);

    let cleaned = cleanForSave(getters.getForm);

    // expand sections again before save
    let expandedSections = expandSections(cleaned);
    cleaned.sections = expandedSections;

    let data = {
      nextElementId: Math.floor(Math.random() * 1000000),
      form: cleaned,
      displayTitle: state.displayTitle,
      formName: state.formName,
    };

    let res = await $api.template.postEditTemplate(state.dbId, data);

    commit('setDbId', res.data);
    commit('setLastDbForm', data);

    if (state.saved === false) commit('setSaved', { saved: true });
  },
  CHANGE_TO_EDITOR_FORMAT({commit}, dbForm) {
    if (debug) console.log('CHANGING TO EDITOR FORMAT', dbForm);
    
    // We ensure it's flat before doing anything
    let flattened = flattenSections(dbForm.sections);

    const {form, ifQObjs} = convertToEditorjs(flattened);
    let combined = { editorJSData: form, dbRepresentation: { sections: flattened}, ifQs: ifQObjs}
    commit('setFromDbForm', combined);
  },
  AUTONUMBER_DEFAULT({state, commit}) {
    let blocksClone = cloneObject(state.editorJSData.blocks);
    let resetAfterSection = false;

    const newBlocks = autoNumberBlocks(blocksClone, resetAfterSection);
    let outerObject = createOuterObj(newBlocks, state.editorJSData.time, state.editorJSData.version);

    commit('setEditorJSData', outerObject);
  },
  AUTONUMBER_SECTIONAL({state, commit}) {
    let blocksClone = cloneObject(state.editorJSData.blocks);
    let resetAfterSection = true;

    const newBlocks = autoNumberBlocks(blocksClone, resetAfterSection);
    let outerObject = createOuterObj(newBlocks, state.editorJSData.time, state.editorJSData.version);
    
    commit('setEditorJSData', outerObject);
  },
  AUTONUMBER_REMOVE({state, commit}){
    let blocksClone = cloneObject(state.editorJSData.blocks);
    const newBlocks = removeNumberFromBlocks(blocksClone)
    let outerObject = createOuterObj(newBlocks, state.editorJSData.time, state.editorJSData.version);

    commit('setEditorJSData', outerObject);
  },
};

function removeSubsectionBlocks(arr) {
  let newArr = [];
  arr.map((item) => {
    if (item.type === 'subsection') {
      newArr.push(...item.sections);
    } else {
      newArr.push(item);
    }
  });
  return newArr;
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
