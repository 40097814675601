<template>
  <div :id="elementId">
    <label class="question-label">{{ question }}</label>
    <ag-grid-vue
      style="width: 100%;"
      class="qtable ag-theme-alpine"
      :class="validatedClass"
      domLayout="autoHeight"
      :gridOptions="gridOptions"
      :columnDefs="columns"
      :defaultColDef="defaultColDef"
      :rowSelection="rowSelection"
      v-model="rowData"
      @rowMultiSelectWithClick="true"
      @selection-changed="onSelectionChanged"
      @data-model-changed="updateStore"
      @cellFocused="commitFocus"
      @column-resized="onColumnResized"
    >
    </ag-grid-vue>
    <div v-if="isAddRowsEnabled">
      <VEditButton class="eb-sec mt-1" @click="addRow" v-if="columns.length > 0">Add Row</VEditButton>
      <VEditButton class="eb-sec mt-1" @click="removeSelectedRows" :disabled="isSelected == false">Remove Row</VEditButton>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    AgGridVue
  },
  props: {
    id: [Number, String],
    question: String,
    help: String,
    columns: Array,
    tableData: Array,
    type: String,
    isAddRowsEnabled: Boolean
  },
  data() {
    return {
      gridOptions: null,
      defaultColDef: null,
      rowData: null,
      rowSelection: null,
      selectedRows: null,
      isSelected: false
    };
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    validatedClass() {
      return this.$store.getters['fill/validationState'](this.id);
    }
  },
  beforeMount() {
    this.gridOptions = {};

    this.defaultColDef = {
      resizable: true,
      editable: true,
      autoHeight: true,
      wrapText: true,
      cellEditorSelector: function(params) {
        // Use larger text editor if the data in it is really large
        if (params.value && params.value.length && params.value.length > 100) {
          return { component: 'agLargeTextCellEditor', params: { maxLength: 1000 } };
        }

        return null;
      }
    };

    this.rowSelection = 'multiple';
  },
  mounted() {
    let data = this.$store.getters['fill/answer'](this.id);
    if (!data) data = this.tableData;

    this.rowData = Object.freeze(this.copyRowData(data));

    this.gridOptions.rowHeight = 35;
    this.gridOptions.api.sizeColumnsToFit();
  },
  methods: {
    updateStore(dataForTable) {
      let payload = { id: this.id, value: dataForTable };
      this.$store.dispatch('fill/SAVE_ANSWER', payload);
      this.gridOptions.api.resetRowHeights();
    },
    commitFocus() {
      this.$store.commit('fill/setFocus', this.id);
    },
    addRow() {
      var newItem = [{}];
      this.gridOptions.api.applyTransaction({
        add: newItem
      });
    },
    removeSelectedRows() {
      var selectedData = this.gridOptions.api.getSelectedRows();
      this.gridOptions.api.applyTransaction({ remove: selectedData });
      this.isSelected = false;
    },
    copyRowData(data) {
      return data.map(datum => {
        return {
          ...datum
        };
      });
    },
    onSelectionChanged(params) {
      var selectedRows = params.api.getSelectedRows();
      if (selectedRows.length > 0) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    },
    onColumnResized(params) {
      params.api.resetRowHeights();
    }
  }
};
</script>

<style>
.qtable .ag-cell:hover {
  cursor: text !important;
}
.qtable .ag-cell {
  cursor: text !important;
  font-size: 14px;
  line-height: 26px !important;
}

.qtable .ag-center-cols-clipper,
.qtable .ag-center-cols-container {
  min-height: 10px !important;
}

.qtable .ag-cell-inline-editing {
  height: 35px !important;
}

.is-invalid .ag-root-wrapper {
  border-color: #f04124 !important;
}
.is-valid .ag-root-wrapper {
  border-color: #28a745 !important;
}
</style>
