const simpleTypes = ['qshort', 'qlong', 'qnumber', 'qyn', 'qdate', 'qupload', 'qsign', 'qcompanyhousename', 'qcharityname'];
const textTypes = ['section', 'text'];
const optionTypes = ['qselect', 'qcheck', 'qoption'];

export const isSimpleType = (type) => {
  return simpleTypes.includes(type);
};

export const isOptionType = (type) => {
  return optionTypes.includes(type);
};
export const isTextType = (type) => {
  return textTypes.includes(type);
};

export const isQuestionType = (type) => {
  return isOptionType(type) || isSimpleType (type) || type === 'qtable'
}