import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
class eQNumber extends eQSimpleQuestion {
  static get toolbox() {
    return {
      title: 'Number',
      icon: toolsInfo.find((tool) => tool.type === 'qnumber').icon,
    };
  }
}

export default eQNumber;
