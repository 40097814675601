
import { isEmpty } from '@shared/services/utils/stringUtils.js';



// Create an object that can be used. 
export const $utils ={
  str: { isEmpty: isEmpty},
};


// Create a simple Vue plugin to expose the wrapper object throughout the application
export const UtilsApisPlugin = {
  install(Vue) {
    Vue.prototype.$utils = $utils;
  }
}
