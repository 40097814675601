<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="lay-card mt-5 mb-5">
          <form class="mt-3" @submit.prevent="handleSubmit" novalidate>
            <div class="lay-card-top">
              <h3 class="card-title">{{ newStateTitle }}</h3>
              <p class="card-text">{{ newStateDescription }}</p>
            </div>

            <div class="row" style="padding-top:40px;padding-bottom:40px;">
              <div class="col-1"></div>
              <div class="col-10">
            <div class="card  border-primary">
              <ul class="list-group ">
                <li class="list-group-item"><b>Name:</b> {{name}}</li>
                <li class="list-group-item"><b>Email:</b> {{email}}</li>
                <li class="list-group-item"><b>Company:</b> {{introducerCompany}}</li>
              </ul>
              <div class="col-1"></div>
            </div>

              </div>
            </div>
            <div class="lay-card-bottom">
              <VEditButton class="eb-subtle" type="Input" @click="handleCancel">Go back</VEditButton>
              <VEditButton v-if="active" class="eb-danger" type="Submit">Inactivate</VEditButton>
              <VEditButton v-else class="eb-prim" type="Submit">Activate</VEditButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// see https://www.digitalocean.com/community/tutorials/vuejs-vue-form-handling
export default {
  props: {
    id: Number,
    name: String,
    email: String,
    introducerCompany: String,
    active: Boolean
  },
  computed: {
    newStateIsActive() {
      return !this.active;
    },
    newStateTitle() {
      return this.newStateIsActive ? 'Activate Introducer' : 'Inactivate Introducer';
    },
    newStateDescription() {
      return this.newStateIsActive
        ? 'This will reactivate the introducer. The introducer will now be able to create application forms using their embedded link.'
        : 'This will make the introducer inactive. The introducer will no longer be able to create application forms or be added to a company.';
    }
  },
  methods: {
    handleSubmit() {
      this.$api.wholesale.postActivateIntroducer(this.id, this.newStateIsActive).then(() => {
        this.$router.push(`/wholesale`);
      });
    },
    handleCancel() {
      this.$router.push(`/wholesale`);
    }
  }
};
</script>

<style scoped></style>
