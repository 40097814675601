<template>
  <VQOption v-bind="bindingContext"></VQOption>
</template>

<script>
import VQOption from '@shared/components/editorQuestions/ui/VQOption.vue'
export default {
  components: {
    VQOption
  },
  props: {
    id: [Number, String],
    question: String,
    type: String,
  },
  data() {
    return {
      options: ['Yes', 'No']
    };
  },
  computed: {
    bindingContext() {
      return {
        id: this.id,
        options:  ['Yes', 'No'],
        question: this.question,
        type: this.type
      }
    }
  }
};
</script>

<style scoped>
</style>
