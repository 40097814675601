<template>
  <fieldset :id='elementId' @focus="commitFocus">
    <legend tabindex='-1' class='bv-no-focus-ring question-label col-form-label pt-0 '>{{question}}</legend>
    <div tabindex='-1'>
      <div v-for='(option, index) in options' :key='index' class='custom-control custom-checkbox bv-no-focus-ring' tabindex='-1'>
        <input :id='optionId(index)' type='checkbox' :value='option' v-model='selected' 
          class='custom-control-input'  :class="validatedClass" 
          :name='elementId' autocomplete='off' @focus='commitFocus' />
        <label :for='optionId(index)' class='custom-control-label'>
          <span>{{option}}</span>
        </label>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  props: {
    id: [Number, String],
    question: String,
    options: Array,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    selected: {
      get() {
        var it =  this.$store.getters['fill/answer'](this.id);
        return it || [];
      },
      set(value) {
        let payload = { id: this.id, value: value };

        this.$store.dispatch('fill/SAVE_ANSWER', payload);
        this.commitFocus();
      }
    },
    validatedClass(){
      return this.$store.getters['fill/validationState'](this.id);
    }
  },
  methods: {
    commitFocus() {
      this.$store.commit('fill/setFocus', this.id);
    },
    optionId(idx) {
      return this.elementId + '_' + idx;
    }
  }
};
</script>

<style scoped>
</style>