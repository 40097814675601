<template>
  <div class="page text-center">
    <h1 class="landing-title">401</h1>
    <h3 class="landing-subtitle">Unathorised: We were unable to log you in</h3>
    <div v-if="hasAuthErr">
      <p class="landing-reason mt-3">Reason: {{authorisationError}}</p>
    </div>
    <p class="landing-help text-center mt-5">You can contact support at support@brokit.co.uk</p>
  </div>
</template>

<script>
export default {
  created () {
    this.$auth.logout();
  },
  computed: {
    hasAuthErr() {
      return this.$auth.error !== null;
    },
    authorisationError() {
      if (this.$auth.error.error && this.$auth.error.errorDescription) {
        return this.$auth.error.errorDescription;
      }

      return this.$auth.error;
    }
  }
};
</script>

<style scoped>

.landing-reason {
  margin: 0;
  border: 1px solid #000;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.1;
}
.landing-help {
  margin: 0;
  font-size: 1rem;
  line-height: 1.1;
}
</style>
