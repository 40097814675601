export default {
  namespaced: true,
  state: {
    demo: false
  },
  getters: {
    // not even sure need this
    feature: (state) => (name) => {
      return state[name];
    },
  },
  mutations: {
    feature(state, payload) {
      state[payload.name] = payload.enabled;
    }
  },
  actions: {
    async INIT_FEATURES({ commit }) {
      const res = await this._vm.$api.utils.featureDemo();
      commit('feature', { name: "demo", enabled: res.data });
       //TODO: add try catch and Should mark state as dirty and attempt to save all on form exit/next save
    },
  }
};