import axios from '@shared/services/api/axiosConfig.js';
import { apiPathStart, rootUrl } from '@broker/services/api/brokerApiHelper.js'

import companyApi from '@broker/services/api/companyApi.js'
import proposalApi from '@broker/services/api/proposalApi.js'
import templateApi from '@broker/services/api/templateApi.js'
import commentsApi from '@broker/services/api/commentsApi.js';
import demoApi from '@broker/services/api/demoApi.js';
import brokerageApi from '@broker/services/api/brokerageApi.js';
import utilsApi from '@broker/services/api/utilsApi.js';
import wholesaleApi from '@broker/services/api/wholesaleApi.js';
import prefillApi from '@broker/services/api/brokerPrefillApi.js';
import excelApi from '@broker/services/api/excelApi.js';
import restrictApi from '@broker/services/api/restrictApi.js';
import printApi from '@broker/services/api/printApi.js';


// Create an object that can be used. 
export const $api = {
  company: companyApi,
  template: templateApi,
  demo: demoApi,
  utils: utilsApi,
  brokerage: brokerageApi,
  apiPathStart: apiPathStart,
  rootUrl: rootUrl,
  wholesale: wholesaleApi,
  prefill: prefillApi,
  excel: excelApi,
  restrictApi: restrictApi,
  getProposalApi: function(){
    return proposalApi;
  },
  getCommentsApi: function(){
    return commentsApi;
  },
  printApi: printApi,
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const BrokerApisPlugin = {
  install(Vue) {
    Vue.prototype.$api = $api;
    Vue.prototype.$http = axios;
  }
}
