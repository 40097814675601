<template>
  <div>
    <h3>{{ title }}</h3>
    <VEditButton class="eb-sec vedit-button" @click="update">Update</VEditButton>
    <div class="row">
      <div class="col">
        <textarea style="width: 100%; height: 70vh" v-model="internalForm"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      internalForm: '',
    };
  },
  props: {
    title: {
      required: false,
      type: String,
    },
    form: {
      required: true,
      type: Object ,
    },
  },
  mounted() {
    const jsonStr = JSON.stringify(this.form, null, 2);
    this.internalForm = jsonStr;
  },
  watch: {
    form(newValue) {
      const jsonStr = JSON.stringify(newValue, null, 2);
      this.internalForm = jsonStr;
    },
  },
  methods: {
    update() {
      let payload = JSON.parse(this.internalForm);
      this.$store.dispatch('editorV3/CHANGE_TO_EDITOR_FORMAT', payload);
    },
  },
};
</script>

<style scoped>
.vedit-button {
  margin-bottom: 1rem;
}
</style>
