import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';

class eQShort extends eQSimpleQuestion {
  static get toolbox() {
    return {
      title: 'Short',
      icon: toolsInfo.find((tool) => tool.type === 'qshort').icon,
    };
  }

  handlePaste = (event) => {
    event.preventDefault();

    const arrPastedText = event.clipboardData.getData('text').split('\n');
    const yesnoRegex = /\s*yes.*no\s*/gim;
    const blockIndex = this.api.blocks.getCurrentBlockIndex();

    for (let index = 0; index < arrPastedText.length; index++) {
      let currentSentence = arrPastedText[index].trim();
      let blockPlacementIndex = blockIndex + index;

      if (yesnoRegex.test(currentSentence)) {
        this.insert('qyn', { text: currentSentence }, blockPlacementIndex, index === 0 ? true : false);
      } else if (bulletRegex.test(currentSentence)) {
        const items = getBulletSentences(arrPastedText.slice(index));

        this.insert('qoption', { items: items }, blockPlacementIndex, index === 0 ? true : false);
        index += items.length;
      } else if (arrPastedText[index + 1] && bulletRegex.test(arrPastedText[index + 1])) {
        const items = getBulletSentences(arrPastedText.slice(index + 1));

        this.insert('qquestion', { text: currentSentence }, blockPlacementIndex, index === 0 ? true : false);
        this.insert('qoption', { items: items }, blockPlacementIndex + 1, index === 0 ? true : false);
        index += items.length;
      } else {
        this.insert('qshort', { text: currentSentence }, blockPlacementIndex, index === 0 ? true : false);
      }
    }
  };

  insert(type, data, index, replace) {
    this.api.blocks.insert(type, data, {}, index, replace);
    if (replace) {
      this.api.blocks.delete(index + 1);
    }
  }
}

const bulletRegex = /• \w+/;
function removeBullet(text) {
  return text.replace(/•\s*/, '');
}
function getBulletSentences(array) {
  let count = 0;
  let items = [];

  while (bulletRegex.test(array[count])) {
    let listItem = array[count];
    listItem = removeBullet(listItem);
    items.push(listItem);
    count++;
  }

  return items;
}

export default eQShort;
