<template>
  <div class="list-group-item list-group-item-action flex-column">
    <div class="media pt-2 pb-2">
      <img
        v-if="hasLandingImage"
        class="bd-placeholder-img mr-2 rounded"
        :src="landingImageSrc"
        alt="Landing Image"
        style="height: 85px; width: 150px"
      />
      <svg
        v-else
        class="bd-placeholder-img mr-2 rounded"
        width="150"
        height="85"
        xmlns="http://www.w3.org/2000/svg"
        focusable="false"
        role="img"
        aria-label="Placeholder"
      >
        <title>Placeholder</title>
        <rect width="100%" height="100%" :fill="fillColour" />
      </svg>
      <div class="media-body mb-0 ml-2" style="height: 100%">
        <div class="d-flex justify-content-between">
          <h5 v-if="state === 'Frozen'">{{ name }}</h5>
          <VH5Edit v-else
            :text="name"
            :allowEmpty="false"
            @text-updated="onProposalNameUpdated"
            :formBadgeStatus="formBadgeStatus"
            :stateMessage="stateMessage"
          ></VH5Edit>

          <div class="d-flex justify-content-between">
            <i class="fas fa-trash fa-lg action-icon-danger cool-danger" @click="onDelete" title="Delete Proposal"></i>
          </div>
        </div>
        <p class="mb-0 text-muted">
          <small>Title: {{ displayTitle }}</small>
          <!-- <span class="pr-2 pl-2"> | </span>
          <small v-if="restricted === true">Sharing: <a style="color: #1E7EAE; font-weight: bold;" @click="onRestrictProposalModal()">Restricted access email list</a></small>
          <small v-else>Sharing: <a style="color: #1E7EAE; font-weight: bold;" @click="onRestrictProposalModal()">Anyone with the link</a></small> -->
        </p>
        <div style="display:flex; justify-content: space-between; align-items:flex-end">
          <!-- Links -->
          <VProposalListItemLinks v-bind="$props" 
            @renewal="$emit('renewal', id)" 
            @download="$emit('download', id)"
            @importAnswers="$emit('importAnswers', id)"
            >
          </VProposalListItemLinks>

          <!-- Buttons -->
          <VEditButton class="eb-prim" title="View Answers" @click="onViewAnswers">View Answers</VEditButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VProposalListItemLinks from '@broker/clientProposals/ui/VProposalListItemLinks.vue';
import generateCoolour from '@shared/services/utils/coolourHelper.js'

export default {
  components: {
    VProposalListItemLinks,
  },
  props: {
    id: Number,
    name: String,
    comments: Number,
    description: String,
    state: String,
    uniqueLink: String,
    pdfGenerating: Boolean,
    displayTitle: String,
    fullLink: String,
    clientLink: String,
    hasDownloads: Boolean,
    landingImage: String,
    restricted: Boolean,
  },
  computed: {
    formBadgeStatus() {
      switch (this.state) {
        case 'Active':
          return 'badge-primary';
        case 'Completed':
          return 'badge-success';
        default:
          return 'badge-light';
      }
    },
    stateMessage() {      
      switch (this.state) {
        case 'Active':
          if(this.restricted){
            return "ACTIVE - Can only be filled in by people on the restricted access list";
          }else{
            return 'ACTIVE - Can be filled in by anyone with the link';
          }
        case 'Completed':
          return 'COMPLETED - Submitted by client';
        default:
          return 'DRAFT - Can be edited. NOT visible to the client';
      }
    },
    viewLink() {
      return `/broker/proposal/${this.uniqueLink}/fill`;
    },
    renameFormLink() {
      return `/broker/proposal/${this.id}/editMeta`;
    },
    editFormLink() {
      return `/broker/proposal/${this.id}/edit`;
    },
    fillColour() {
      var colour = generateCoolour(this.uniqueLink);
      return colour;
    },
    hasLandingImage() {
      return this.landingImage != null;
    },
    landingImageSrc() {
      return this.$api.apiPathStart() + `/api/proposal/landingimage/${this.landingImage}`;
    },
    proposalLink(){
      return this.$utils.str.isEmpty(this.clientLink) ? this.fullLink : this.clientLink;
    }
  },
  methods: {
    onDelete() {
      this.$emit('delete', this.id);
    },
    onActivate() {
      this.$emit('activate', this.id);
    },
    onDraft() {
      this.$emit('draft', this.id);
    },
    onFreeze() {
      this.$emit('freeze', this.id);
    },
    onDefrost() {
      this.$emit('defrost', this.id);
    },
    onProposalNameUpdated(name) {
      this.$emit('rename', { id: this.id, name: name });
    },
    onRestrictProposalModal() {
      this.$emit('toggleRestrict', this.id)
    },
    onViewAnswers(){
      window.open(this.proposalLink, "_blank");
    }
  },
};
</script>

<style scoped>
.badge-notify {
  position: relative;
  top: -10px;
  left: -10px;
}

.icon-holder {
  width: 50px;
  margin-right: -12px;
}

.title-badge {
  font-size: 0.5em;
  vertical-align: top;
  margin-left: 0.5em;
}

.proposalInfo {
  padding-right: 0.5rem;
}
</style>
