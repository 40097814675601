import { findRecursively } from '@shared/store/utils/recursiveFind.js';
import proposalEditHelper from '@broker/store/ProposalEditHelper.js';
import _ from 'lodash'


export function cleanForSave(form){
  let cleaned = cleanForSaveRecursively(form)
  cleaned = fixDuplicateIds(cleaned);
  return cleaned;
}

export function cleanForSaveRecursively(curItem){
  let toReturn = cleanElementForSave(curItem);

  if(curItem.sections) {
    // recursively clean children
    toReturn.sections = curItem.sections.map(elem => {
      if (curItem.type === "subsection" && (elem.type === "subsection" || elem.type === "section")){
        // not allowed to have subsection or section within a subsection
        return null;
      }
      return cleanForSaveRecursively(elem);
    }).filter(x => x !== null);
  }

  return toReturn;
}

function cleanElementForSave(item){
  let clone = Object.assign({}, item);

  delete clone.tableEditData;

  if(clone.type !== "qtable"){
    delete clone.columns;
    delete clone.isAddRowsEnabled;
    delete clone.tableData;
  }
  if(!proposalEditHelper.questionWithOptions(clone.type)){
    delete clone.options;
  }
  if(proposalEditHelper.isNotQuestion(clone.type)){
    delete clone.question;
  }else{
    delete clone.text;
  }

  return clone;
}

/*
* We convert the sections into a flattened structure (preserving subsections) to make editing easier
*/
export function flattenSections(sections){
  let flattened = []
  for (let index = 0; index < sections.length; index++) {
    const element = sections[index];
    flattened.push(element)

    if(element.type === "section" && element.sections){
      element.sections.forEach(child => {
        flattened.push(child)
      });

      delete element.sections;
    }
  }
  
  return flattened;
}

/*
* Sections and questions are on the same level. So we add back in the hierachical structure before we save
*/
export function expandSections(form){
  let expanded = []
  let lastSection = null;
  let newNextId = findNextId(form);
  let sections = form.sections

  for (let index = 0; index < sections.length; index++) {
    const element = sections[index];

    if(element.type === "section"){
      element.sections = [];
      lastSection = element;
      expanded.push(element);
    }else{
      if(lastSection === null){
        // init a first section
        newNextId = newNextId++;
        lastSection = { id: newNextId, type: 'section', text: 'Introduction', sections: [] };
        expanded.push(lastSection);
      }
      lastSection.sections.push(element);
    }
  }
  
  return expanded
}

export function findNextId(form){
  let allIds = findIdsRecursively(form);
  let allNumberIds = allIds.filter((id => !isNaN(id)));
  
  let result = 1;
  if(allNumberIds.length > 0){
    result = Math.max(...allNumberIds) + 1;
  }
  return result
}

// https://github.com/lodash/lodash/issues/4852#issuecomment-666366511
const uniques = (arr) => _.xor(...arr.map(a => [a]))
const duplicates = (arr) => _.xor(arr, uniques(arr));

export function fixDuplicateIds(form){
  let allIds = findIdsRecursively(form);
  let dups = duplicates(allIds);
  if(dups.length === 0){
    return form;
  }

  let nextId = Math.max( ...allIds ) + 1;

  // this is slow and inefficent but I didn't really want to write something efficent and complex so...
  for (let index = 0; index < dups.length; index++) {
    const id = dups[index];
    let firstInstance = findRecursively(form, id);
    firstInstance.id = nextId++
  }

  return form;
}

function findIdsRecursively(curItem){
  let toReturn = [curItem.id];
  if(curItem.sections) {
    for (let index = 0; index < curItem.sections.length; index++) {
      const element = curItem.sections[index];
      let childrenIds = findIdsRecursively(element);
      toReturn = [...toReturn, ...childrenIds];
    }
  }

  return toReturn;
}
