import { isQuestionType } from '@broker/editorV3/utils/questionTypes.js';

import { cloneObject } from '@shared/store/utils/common.js';

let letterCount = 0;

let questionCount = 1;

const alhpaNumberRegex = /^\d+.([a-z].)*/;

const letterCounts = {};

for (let i = 0; i < 26; i++) {
  const letter = String.fromCharCode(97 + i);
  letterCounts[letter] = { count: i, letter: letter };
}

function getLetter(count) {
  const letterObj = Object.values(letterCounts).find((obj) => obj.count === count);
  return letterObj ? letterObj.letter : 'Create new subsection';
}

export function autoNumberBlocks(blocks, resetAfterSection) {
  questionCount = 1;

  let newBlocks = [];

  for (let i = 0; i < blocks.length; i++) {
    const obj = processAutoNumberBlock(blocks[i], resetAfterSection);
    newBlocks.push(obj);
  }
  return newBlocks;
}

function processAutoNumberBlock(block, resetAfterSection) {
  const { type, data } = block;
  const { text } = data;

  let clone = cloneObject(block);

  if (isQuestionType(type)) {
    clone.data.text = autoNumberText(text);
    questionCount++;
  } else if (type === 'section' && resetAfterSection) {
    questionCount = 1;
  } else if (type === 'qsubsection') {
    let blocks = clone.data.itemContent[1].blocks;

    letterCount = 0;

    clone.data.text = autoNumberText(clone.data.text);

    blocks.forEach((block) => {
      if (block.type !== 'text') {
        block.data.text = `${questionCount}.${getLetter(letterCount)}. ${removeNumberingFromText(block.data.text)}`;
        letterCount++;
      }
    });
    questionCount++;
  }

  return clone;
}

function autoNumberText(text) {
  let textWithoutNo = removeNumberingFromText(text);
  return `${questionCount}. ${textWithoutNo}`;
}

function removeNumberingFromText(text) {
  return text.replace(alhpaNumberRegex, '').trim();
}

export function removeNumberFromBlocks(block) {
  const clone = cloneObject(block);

  for (let index = 0; index < clone.length; index++) {
    const element = clone[index];
    removalFunction(element);
    if (element.type === 'qsubsection' && element.data.itemContent[1].blocks.length > 0) {
      element.data.itemContent[1].blocks = removeNumberFromBlocks(element.data.itemContent[1].blocks);
    }
  }

  return clone;
}

function removalFunction(element) {
  element.data.text = removeNumberingFromText(element.data.text);
  return element;
}

export function createOuterObj(blocks, time, version){
  return {
    blocks: blocks,
    time: time,
    version: version,
    
  }
}
