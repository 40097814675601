<template>
  <div class="form-group">
    <div :class="isQuestionType ? 'question-group-print' : ''">
      <component :is="componentType" v-bind="proposalItem"></component>
      <QuestionCommentHolder v-if="isQuestionType" :questionId="proposalItem.id"></QuestionCommentHolder>
      <PreFillOptions v-if="isPrefillEnabled" :questionId="proposalItem.id"></PreFillOptions>
    </div>
  </div>
</template>

<script>
import questionsHelper from '@shared/components/questions/utils/questionsHelper.js';

import QShort from '@shared/components/questions/ui/QShort.vue';
import QYesNo from '@shared/components/questions/ui/QYesNo.vue';
import QLong from '@shared/components/questions/ui/QLong.vue';
import QTable from '@shared/components/questions/ui/QTable.vue';
import QOption from '@shared/components/questions/ui/QOption.vue';
import QDate from '@shared/components/questions/ui/QDate.vue';
import QCheck from '@shared/components/questions/ui/QCheck.vue';
import QSelect from '@shared/components/questions/ui/QSelect.vue';
import QUpload from '@shared/components/questions/ui/QUpload.vue';
import QNumber from '@shared/components/questions/ui/QNumber.vue';
import QSign from '@shared/components/questions/ui/QSign.vue';
import QuestionSubSection from '@shared/components/questions/ui/QuestionSubSection.vue';
import QuestionCommentHolder from '@shared/components/comments/QuestionCommentHolder.vue';
import PreFillOptions from '@shared/components/prefill/PreFillOptions.vue';
import QCompanyHouseName from '@shared/components/questions/ui/QCompanyHouseName.vue';
import QCharityName from '@shared/components/questions/ui/QCharityName.vue';

export default {
  components: {
    QShort,
    QYesNo,
    QLong,
    QTable,
    QOption,
    QDate,
    QCheck,
    QSelect,
    QUpload,
    QNumber,
    QSign,
    QuestionSubSection,
    QuestionCommentHolder,
    PreFillOptions,
    QCompanyHouseName,
    QCharityName
  },
  props: {
    proposalItem: Object
  },
  computed: {
    isQuestionType() {
      return questionsHelper.isQuestionType(this.proposalItem.type);
    },
    isPrefillEnabled() {
      return (
        questionsHelper.isQuestionType(this.proposalItem.type) &&
        this.proposalItem.type != 'qtable' &&
        this.proposalItem.type != 'qupload' &&
        this.proposalItem.type != 'qsign'
      );
    },
    componentType() {
      let components = questionsHelper.componentsDict();
      if (this.proposalItem.type in components) return components[this.proposalItem.type];

      return 'VNone';
    }
  }
};
</script>

<style scoped></style>
