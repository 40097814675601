<template>
  <div :id="elementId" class="table-readonly">
    <label class="question-label">{{ question }}</label>
    <ag-grid-vue
      style="width: 1000px;"
      class="ag-theme-alpine"
      domLayout="autoHeight"
      :gridOptions="gridOptions"
      :columnDefs="internalColumns"
      :defaultColDef="defaultColDef"
      v-model="rowData"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    AgGridVue
  },
  props: {
    id: Number,
    question: String,
    help: String,
    columns: Array,
    tableData: Array,
    type: String
  },
  data() {
    return {
      defaultColDef: null,
      rowData: null,
      internalColumns: null
    };
  },
  computed: {
    elementId() {
      return this.type + this.id;
    }
  },
  beforeMount() {
    this.gridOptions = {};
    this.defaultColDef = {
      autoHeight: true,
      wrapText: true
    };
  },
  mounted() {
    let data = this.$store.getters['print/answer'](this.id);
    if (!data) data = this.tableData;

    this.rowData = Object.freeze(this.copyRowData(data));
    this.gridOptions.rowHeight = 35;

    this.workOutColumnWidths();

    this.$nextTick(() => {
      this.gridOptions.api.resetRowHeights();
    });
  },
  methods: {
    copyRowData(data) {
      return data.map(datum => {
        return {
          ...datum
        };
      });
    },
    workOutColumnWidths() {
      let colMaxLengths = calculateLargestLengthForEachCol(this.columns, this.rowData);

      let maxLen = Math.max(...colMaxLengths);
      let minSize = maxLen / 4;

      // if any column is less than 25% of the biggest column we bump them up to 25% of the size of the biggest (so we don't get tiny rows)
      for (let i = 0; i < colMaxLengths.length; i++) {
        if (colMaxLengths[i] < minSize) {
          colMaxLengths[i] = minSize;
        }
      }

      let lengthSum = colMaxLengths.reduce((a, b) => a + b, 0);

      this.internalColumns = [];
      for (let i = 0; i < this.columns.length; i++) {
        let colDef = Object.assign({}, this.columns[i]);
        colDef.width = (colMaxLengths[i] / lengthSum) * 998;
        this.internalColumns.push(colDef);
      }
    }
  }
};

function calculateLargestLengthForEachCol(columns, rowData) {
  let colMaxLengths = [];
  // do column headers first
  for (let i = 0; i < columns.length; i++) {
    let len = columns[i].headerName.length ? columns[i].headerName.length : 0;
    colMaxLengths.push(len);
  }

  // now do rows
  for (let rowIdx = 0; rowIdx < rowData.length; rowIdx++) {
    for (let colIdx = 0; colIdx < columns.length; colIdx++) {
      let colField = columns[colIdx].field;
      let value = rowData[rowIdx][colField];
      if (value && value.length && value.length > colMaxLengths[colIdx]) {
        colMaxLengths[colIdx] = value.length;
      }
    }
  }

  return colMaxLengths;
}

</script>
<style>
.table-readonly .ag-header-cell {
  border-left: 1px solid #181d1f !important;
}
.table-readonly .ag-cell {
  border-left: 1px solid #181d1f !important;
  font-size: 14px;
  line-height: 26px !important;
}

.table-readonly .ag-row-odd {
  background-color: #fff !important;
}

.table-readonly .ag-header-row {
  border-right: 1px solid #181d1f !important;
  border-top: 1px solid #181d1f !important;
  border-bottom: 2px solid #181d1f !important;
}
.table-readonly .ag-row {
  border-right: 1px solid #181d1f !important;
  border-bottom: 1px solid #181d1f !important;
}

.table-readonly .ag-center-cols-clipper,
.table-readonly .ag-center-cols-container {
  min-height: 10px !important;
}

.table-readonly .ag-cell-wrap-text {
  word-break: break-word;
}
</style>
