<template>
  <div :id="elementId">
    <label :for="elementId" class="question-label">{{ question }}</label>

    <div class="form-check col-s-12 col-md-6 col-lg-4">
      <input
        value="search"
        v-model="selected"
        class="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="radioOptionSearch"
      />
      <label class="form-check-label" for="radioOptionSearch"> Search Companies House </label>
    </div>
    <div class="form-check col-s-12 col-md-6 col-lg-4">
      <input
        value="manual"
        v-model="selected"
        class="form-check-input"
        type="radio"
        name="flexRadioDefault"
        id="radioOptionManual"
      />
      <label class="form-check-label" for="radioOptionManual"> Enter Company Name Manually </label>
    </div>

    <div v-if="selected === 'search'" class="col-s-12 col-md-6 col-lg-4" style="padding: 0; height: 40px;">
      <multiselect
        v-model.lazy="searchValue"
        :options="options"
        placeholder="Search Companies House"
        label="title"
        track-by="companyNumber"
        :close-on-select="true"
        :loading="isLoading"
        :hide-selected="true"
        @search-change="asyncFind"
      ></multiselect>
    </div>

    <div v-if="selected === 'manual'" class="col-s-12 col-md-6 col-lg-4" style="padding: 0; height: 40px;">
      <input type="text" class="form-control" :id="elementId" :ref="elementId" v-model.lazy="manualValue" />
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components: {
    Multiselect,
  },
  props: {
    id: [Number, String],
    question: String,
    type: String,
  },
  mounted() {
    this.checkRadioBox();
  },
  data() {
    return {
      selected: 'search',
    };
  },
  computed: {
    options() {
      return this.$store.state.preFill.companyNameSearchResults;
    },
    elementId() {
      return this.type + this.id;
    },
    searchValue: {
      get() {
        let val = this.$store.getters['fill/answer'](this.id);
        return { title: val, companyNumber: '0000' };
      },
      set(searchValue) {
        if (this.$debug) console.log('selected', searchValue.companyNumber);
        this.$store.dispatch('preFill/SELECT_COMPANY_RESULT', searchValue.companyNumber);

        let payload = { id: this.id, value: searchValue.title };
        this.$store.dispatch('fill/SAVE_ANSWER', payload);
      },
    },
    manualValue: {
      get() {
        return this.$store.getters['fill/answer'](this.id);
      },
      set(manualValue) {
        let payload = { id: this.id, value: manualValue };
        this.$store.dispatch('fill/SAVE_ANSWER', payload);
      },
    },
    validatedClass() {
      return this.$store.getters['fill/validationState'](this.id);
    },
    isLoading() {
      return this.$store.state.preFill.isLoading;
    },
  },
  methods: {
    commitFocus() {
      this.$store.commit('fill/setFocus', this.id);
    },
    async asyncFind(query) {
      if (query.Length !== 0) {
        await this.$store.dispatch('preFill/SEARCH_COMPANY_NAME', query);
      }
    },
    checkRadioBox() {
      if (this.searchValue !== null && this.manualValue !== null) {
        this.selected = 'manual';
      } else {
        this.selected = 'search';
      }
    },
  },
};
</script>

<style scoped>
</style>
