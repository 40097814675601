export function commentsAppendixData(form, commentsArr, shouldDisplayFn) {
  const appendixArr = [];
  const commentsDict = asDict(commentsArr)

  for (let index = 0; index < form.sections.length; index++) {
    const section = form.sections[index];
    if (!shouldDisplayFn(section)) continue;

    if (section.sections) {
      var itemsInSection = appendixForSection(section.sections, shouldDisplayFn, commentsDict, section.text)
      itemsInSection.forEach(x => appendixArr.push(x));
    }
  }
  
  return appendixArr;
}

function appendixForSection(sectionItems, shouldDisplayFn, commentsDict, sectionName) {
  const toReturn = [];

  sectionItems.forEach((question) => {
    if (question.question && question.id in commentsDict && shouldDisplayFn(question)){
        let commentsForQ = { section: sectionName, subsection: null, question: question.question, questionId: question.id, comments: commentsDict[question.id] };
        toReturn.push(commentsForQ);
    } else if (question.type === 'subsection' && shouldDisplayFn(question)) {
      var itemsInSubsection = appendixForSubSection(question.sections, shouldDisplayFn, commentsDict, sectionName, question.text)
      itemsInSubsection.forEach(x => toReturn.push(x));
    }
  });

  return toReturn;
}

function appendixForSubSection(subsectionItems, shouldDisplayFn, commentsDict, sectionName, subsectionName){
  const toReturn = [];

  subsectionItems.forEach((item) => {
    if (item.question && item.id in commentsDict && shouldDisplayFn(item)) {
        let commentsForQ = { section: sectionName, subsection: subsectionName, question: item.question, questionId: item.id, comments: commentsDict[item.id] };
        toReturn.push(commentsForQ);
    }
  });

  return toReturn;
}
 
function asDict(commentsArr){
  if(!Array.isArray(commentsArr))
    throw "Parameter to method is not an array"

  const dict = {};

  commentsArr.forEach(comment => {
    if(comment.questionId in dict){
      dict[comment.questionId].push(comment); 
    }else{
      dict[comment.questionId] = [comment];
    }
  })

  return dict;
}
