const debug = process.env.NODE_ENV !== 'production';

import { findRecursively, hierarchyRecursivelyIds } from '@shared/store/utils/recursiveFind.js';
import { percOfFormCompleted, questionIsCompleted } from '@shared/store/utils/formProgress.js';
import questionsHelper from '@shared/components/questions/utils/questionsHelper.js';
import { visibleFilter } from '@shared/store/utils/common.js';

let proposalApi = null;
// for testing
export function setTestProposalApi(api){
  proposalApi = api;
}

export function initialState() {
  return {
    answers: {}, // Dict for answers {1:'No'}
    form: { title: '', sections: [] }, // tree structure object for form
    focus: [],
    meta: {}, // TODO: check if still need this. Can get from moduleProposal meta
    isLoading: false,
    formExists: false,
    tableOfContents: [],
    files: [],
    isEditable: false,
    componentsMode: '',
    proposalIdOrUid: '',
    wasValidated: false,
    answersQueue: [],
    offline: false,
    retryTimerId: null,
    saving: false,
    retryAttemptWhileInProgress: false,
  };
}
const state = initialState();

// Changed mind: should prefer using state if getter will just directly access state
export const getters = {
  tableOfContents:(state) => state.tableOfContents,
  answer: (state) => (id) => {
    return state.answers[id];
  },
  uploadedFiles: (state) => (id) => {
    return state.files.filter((x) => x.questionId === id);
  },
  shouldDisplay: (state) => (id) => {
    var item = findRecursively(state.form, id);
    return visibleFilter(item, state);
  },
  shouldDisplayElem: (state) => (element) => {
    return visibleFilter(element, state);
  },
  validationState: (state) => (id) => {
    if (!state.wasValidated) return '';

    let question = findRecursively(state.form, id);
    let answer = state.answers[id];
    let completedState = questionIsCompleted(question, answer, state.files, (x) => visibleFilter(x, state))

    switch (completedState) {
      case 'filled':
        return 'is-valid'      
      case 'empty':
        return 'is-invalid'
      default:
        return ''
    }
  },
  filteredSections: (state) => {
    return state.form.sections.filter((x) => visibleFilter(x, state));
  },
  filteredItems: (state) => (id) => {
    var item = findRecursively(state.form, id);
    return item.sections.filter((x) => visibleFilter(x, state));
  },
  percCompleted: (state) => {
    return percOfFormCompleted(state.form, state.answers, state.files, (x) => visibleFilter(x, state))
  }
};

export const mutations = {
  setIsLoading(state){
    state.isLoading = true;
  },
  setForm(state, payload) {
    if (debug) console.log('setForm ', payload);

    state.form = payload.form;
    state.answers = payload.answers;
    state.meta = payload.meta; // colour will be in meta.sidebarColour. If null default to #1985a1
    state.files = payload.files;
    state.formExists = true;
    state.isLoading = false;
    state.tableOfContents = questionsHelper.tableOfContents(state.form, (x) => visibleFilter(x, state));
  },
  setFormLoadError(state){
    state.isLoading = false;
    state.formExists = false;
    state.form = { title: '', sections: [] };
    state.meta = {};
    state.tableOfContents = [];
  },
  setFiles(state, payload){
    if (debug) console.log('setFiles ', payload);

    state.files = payload;
  },
  removeFile(state, supportingDocId){
    if (debug) console.log('removeFile ', supportingDocId);

    const newArray = state.files.filter(obj => obj.id != supportingDocId);
    state.files = newArray;
  },
  setAnswer(state, payload) {
    if (debug) console.log('setAnswer triggered with', payload);

    if(state.isEditable){
      if("" === payload.value)
        payload.value = null;

      var updatedAnswers = Object.assign({}, state.answers);
      updatedAnswers[payload.id] = payload.value;
      state.answers = updatedAnswers;
      state.tableOfContents = questionsHelper.tableOfContents(state.form, (x) => visibleFilter(x, state));
    }
  },
  setFocus(state, payload){
    if(state.isEditable){
      if (debug) console.log('setFocus triggered with', payload);
      var hierarchy = hierarchyRecursivelyIds(state.form, payload);
      state.focus = hierarchy;
    }
  },
  resetState (state) {
    Object.assign(state, initialState())
  },
  setMode(state, payload){
    if (debug) console.log('setMode triggered with', payload);
    state.isEditable = payload.editable || false;
    state.componentsMode = payload.componentsMode || '';
    state.proposalIdOrUid = payload.proposalIdentifier;

    proposalApi = this._vm.$api.getProposalApi(payload.isRestrictedPage || false );
  },
  setValidated(state){
    if (debug) console.log('setValidated');
    state.wasValidated = true;
  },
  setSaving(state){
    if (debug) console.log('setSaving');
    state.saving = true;
  },
  setSaveDone(state){
    if (debug) console.log('setSaveDone');
    state.saving = false;
  },
  setOffline(state, answerPayload){
    if (debug) console.log('setOffline');

    state.offline = true;
    state.answersQueue.push(answerPayload);
  },
  setOnline(state){
    if (debug) console.log('setOnline');

    state.offline = false;
    state.answersQueue = [];
  },
  replaceRetryTimer(state, payload){
    if (debug) console.log('replaceRetryTimer', payload);
    
    if(state.retryTimerId !== null){
      clearTimeout(state.retryTimerId);
    }
    state.retryTimerId = payload;
  },
  setRetryAttemptWhileInProgress(state, payload){
    if (debug) console.log('setRetryAttemptWhileInProgress', payload);

    state.retryAttemptWhileInProgress = payload;
  },
};

export const actions = {
  async INIT({ commit }, payload) {
    if (debug) console.log('INIT:', payload);

    commit('resetState');
    commit('setMode', payload);
    commit('setIsLoading');

    // use this._vm as api could be external or the proposal api. But this._vm will have correct one on it.
    try {
      let res = await proposalApi.getProposalFill(payload.proposalIdentifier);
      commit('setForm', res.data);

    } catch (err) {
      console.log(err);
      if (err.response && err.response.status == 404){
        commit('setFormLoadError')
      }
      throw err;
    }
  },
  async SAVE_ANSWER({ commit, state, dispatch }, payload) {
    if (debug) console.log("SAVE_ANSWER. editable: ", state.isEditable)
    if(state.isEditable){
      commit('setAnswer', payload);

      if(state.offline === false){
        try {
          commit('setSaving')
          await proposalApi.postAnswer(state.proposalIdOrUid, payload)
          commit('setSaveDone')
        } catch (error) {
          if (debug) console.log("We are offline")
          if (debug) console.log(error)

          commit('setSaveDone')
          commit('setOffline', payload);
          await dispatch('RETRY_OFFLINE');
        }
      }else{
        commit('setOffline', payload);
        await dispatch('RETRY_OFFLINE');
      }
    }
  },
  async RETRY_OFFLINE({ commit, state }){
    if (debug) console.log("RETRY_OFFLINE")
    const toast = this._vm.$toast;

    // clear existing timer
    if(state.retryTimerId){
      commit("replaceRetryTimer", null)
    }
    
    const retry = async function() {
      if (state.saving === true) {
        if (debug) console.log("Network req already in progress")
        commit('setRetryAttemptWhileInProgress', true)
        return;
      }

      try {
        if (debug) console.log("retry()", state.proposalIdOrUid, state.answersQueue)
        commit('setSaving')
        await proposalApi.postRetryAnswers(state.proposalIdOrUid, state.answersQueue);
        if(state.retryAttemptWhileInProgress === true){
          // a retry was done while we were waiting so may have additional elements, so we retry that before we 'release'
          commit('setRetryAttemptWhileInProgress', false)
          await proposalApi.postRetryAnswers(state.proposalIdOrUid, state.answersQueue);
        }

        commit("setOnline", state.retryAttemptWhileInProgress)
        commit('setSaveDone')
      } catch (error) {
        commit('setSaveDone')

        // == on purpose
        if (error.response && error.response.status == 422){
          toast.error("Unable to save. Please refresh the page, Contact your broker if the error persists.");
        }

        if (debug) console.log(error)
        if (debug && error.response && error.response.data) console.log(error.response.data)

        const retryTimerId = setTimeout(retry, 10 * 1000);
        commit("replaceRetryTimer", retryTimerId)
      }
    }

    retry();    // retry request immediately
  },
  async REMOVE_SUPPORT_DOC({ commit, state }, payload){
    if (debug) console.log("REMOVE_SUPPORT_DOC")

    if(state.isEditable){
      commit('removeFile', payload.supportingDocId);

      await proposalApi.deleteSupportingDoc(state.proposalIdOrUid, payload.supportingDocId);
      const res = await proposalApi.getProposalFill(state.proposalIdOrUid);

      commit('setFiles', res.data.files);
    }
  },
  async FETCH_SUPPORT_DOCS({ commit }){
    if (debug) console.log("FETCH_SUPPORT_DOCS")
    const res = await proposalApi.getProposalFill(state.proposalIdOrUid);
    commit('setFiles', res.data.files);
  },
  async SUBMIT({ commit }){
    if (debug) console.log("SUBMIT")
    commit('setValidated')

    await proposalApi.postSubmitForm(state.proposalIdOrUid);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
