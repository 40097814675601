import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';
import { apiPathStart } from '@broker/services/api/brokerApiHelper.js'
import {  downloadToComputer } from '@broker/services/api/brokerApiHelper.js'

const debug = process.env.NODE_ENV !== 'production';


export default {
  async getTemplate(id) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/formTemplate/${id}`, headers)
    if (debug) console.log('getTemplate', res);
    return res;
  },
  async getDefault() {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/formTemplate/default`, headers)
    if (debug) console.log('getTemplate', res);
    return res;
  },
  async getAll() {
    const headers = await getAuthHeaders();
    const res = await axios.get('/api/formTemplate/all/', headers)
    if (debug) console.log('template.getAll', res);
    return res;
  },
  async postEditTemplate(id, data) {
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/formTemplate/${id}`, data, headers)
    if (debug) console.log('postEditTemplate', res);
    return res;
  },
  async deleteTemplate(id) {
    const headers = await getAuthHeaders();
    const res = await axios.delete(`/api/formTemplate/${id}`, headers)
    if (debug) console.log('deleteTemplate', res);
    return res;
  },
  urlAiImportUpload() {
    let path = `/api/formTemplate/aiImport`;
    let fullPath = apiPathStart() +path
    return fullPath;
  },
  async getAiTemplate(id) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/formTemplate/ai/${id}`, headers)
    if (debug) console.log('getAiTemplate', res);
    return res;
  },
  async postAiEditTemplate(id, data) {
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/formTemplate/ai/${id}`, data, headers)
    if (debug) console.log('postAiEditTemplate', res);
    return res;
  },
  async postFixIds(baml) {
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/formTemplate/aiIdFix`, {data: baml}, headers)
    if (debug) console.log('postFixIds', res);
    return res;
  },
  async getAiBamlWithId(id) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/formTemplate/ai/${id}/bamlIds`, headers)
    let filename = `Template Export.txt`

    downloadToComputer(res.data, 'application/txt', filename );
    return res;
  },

}
