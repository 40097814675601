import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
class eQLong extends eQSimpleQuestion {
  static get toolbox() {
    return {
      title: 'Multiline',
      icon: toolsInfo.find((tool) => tool.type === 'qlong').icon,
    };
  }
}

export default eQLong;
