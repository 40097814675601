<template>
  <div>
      <vue-dropzone
        id="uploader"
        :options="dropzoneOptions"
        @vdropzone-success-multiple="onUploadComplete"
        @vdropzone-success="onUploadComplete"
        @vdropzone-error-multiple="onUploadFailed"
        @vdropzone-error="onUploadFailed"
        @vdropzone-sending="sendingEvent"
      ></vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';


// Leaving as distinct for the time being but could be refactored into a generic component
// by passing in url & changing so have object with params to be passed as part of sendingEvent
export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: [
    'templateId',
    'clientId',
  ],
  data() {
    return {
      headers: {
          'Access-Control-Allow-Origin': 'Access-Control-Allow-Methods: GET, POST'
        },
    }
  },
  async created(){
    const headers = await getAuthHeaders();
    this.headers.Authorization = headers.headers.Authorization;
  },
  computed: {
    uploadUrl() {
      let fullPath = this.$api.demo.acturisAnswerImportUpload();
      console.log('test',fullPath)
      return fullPath;
    },
    dropzoneOptions() {
      return {
        url: this.uploadUrl,
        thumbnailWidth: 150,
        maxFilesize: 10,
        dictDefaultMessage: "<i class='fa fa-upload'></i> Drop your files here or click to upload a file",
        headers: this.headers,
        maxFiles: 1
      };
    },
  },
  methods: {
    onUploadComplete(data) {
      if (this.$debug) console.log('Uploading Files complete ');
      this.$emit("upload", JSON.parse(data.xhr.response))
    },
    onUploadFailed() {
      if (this.$debug) console.log('Uploading Files failed ');
      this.$toast.error("We were unable to upload the file. Please retry later.");
    },      
    sendingEvent (file, xhr, formData) {
        formData.append('templateId', this.templateId);
        formData.append('clientId', this.clientId);
      }
  },
};
</script>

<style scoped></style>