import eQHeader from '@broker/editorV3/tools/eQHeader.js';
import eQShort from '@broker/editorV3/tools/eQShort.js';
import eQLong from '@broker/editorV3/tools/eQLong.js';
import eQDate from '@broker/editorV3/tools/eQDate.js';
import eQYN from '@broker/editorV3/tools/eQYn.js';
import eQNumber from '@broker/editorV3/tools/eQNumber.js';
import eQSignature from '@broker/editorV3/tools/eQSignature.js';
import eQUpload from '@broker/editorV3/tools/eQUpload.js';
import eQText from '@broker/editorV3/tools/eQText.js';
import eQCompanyHouseName from '@broker/editorV3/tools/eQCompanyHouseName.js';
import eQCharityName from '@broker/editorV3/tools/eQCharityName.js';
import eQOption from '@broker/editorV3/tools/eQOption.js';
import eQCheck from '@broker/editorV3/tools/eQCheck.js';
import eQSelect from '@broker/editorV3/tools/eQSelect.js';
import BlockTune from '@broker/editorV3/tools/blockTune.js';
import eQTable from '@broker/editorV3/tools/eQTable.js';
import eQSubsection from '@broker/editorV3/tools/eQSubsection.js';

const containerStyle = `
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding: 2rem;
  box-shadow: 0px 0px 6px #B9C9FF;
  `
  
export const defaultTools = {
  qshort: eQShort,
  qlong: eQLong,
  qnumber: eQNumber,
  qyn: eQYN,
  qdate: eQDate,
  qupload: eQUpload,
  qsign: eQSignature,
  text: eQText,
  qcompanyhousename: eQCompanyHouseName,
  qcharityname: eQCharityName,
  qoption: eQOption,
  qcheck: eQCheck,
  qselect: eQSelect,
  qtable: {
    class: eQTable,
    config: {
      allowEmptyRows: true,
      withHeadings: true,
    },
  },
};



const editorjsConfig = {
  defaultBlock: 'qshort',
  tools: {
    section: eQHeader,
    ...defaultTools,
    paragraph: false,
    qsubsection: eQSubsection,
    tune: BlockTune,
  },
  tunes: ['tune'],
};

export { editorjsConfig , containerStyle };
