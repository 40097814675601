import Vue from 'vue'

import VEditButton from '@shared/components/common/VEditButton.vue';
import VLabelEdit from '@shared/components/common/VLabelEdit.vue';
import VHeaderEdit from '@shared/components/common/VHeaderEdit.vue';
import VH5Edit from '@shared/components/common/VH5Edit.vue';
import VH5BadgeOnly from '@shared/components/common/VH5BadgeOnly.vue';
import VSubtitle from '@shared/components/questions/ui/VSubtitle.vue';
import VText from '@shared/components/questions/ui/VText.vue';
import VNone from '@shared/components/questions/ui/VNone.vue';
import ProposalItemHolder from '@shared/components/questions/ui/ProposalItemHolder.vue';

import NavBar from '@shared/components/coreUi/NavBar.vue';
import Page404 from '@shared/components/coreUi/Page404.vue';
import PageLoading from '@shared/components/coreUi/PageLoading.vue';
import Page403Forbidden from '@shared/components/coreUi/Page403Forbidden.vue';

// Reusable Component registration
Vue.component('VEditButton', VEditButton)
Vue.component('VSubtitle', VSubtitle)
Vue.component('VText', VText)
Vue.component('VNone', VNone)
Vue.component('NavBar', NavBar)
Vue.component('VHeaderEdit', VHeaderEdit)
Vue.component('VLabelEdit', VLabelEdit)
Vue.component('VH5Edit', VH5Edit)
Vue.component('VH5BadgeOnly', VH5BadgeOnly)
Vue.component('Page404', Page404)
Vue.component('PageLoading', PageLoading)
Vue.component('ProposalItemHolder', ProposalItemHolder)
Vue.component('Page403Forbidden', Page403Forbidden)
