
import questionsHelper from '@shared/components/questions/utils/questionsHelper.js';

export default function diff(propQuestionsStr, templateQuestionsStr){
  let propQuestions = questionsHelper.extractQuestions(propQuestionsStr);
  let templateQuestions = questionsHelper.extractQuestions(templateQuestionsStr);

  const removed = [];
  const added = [];
  const changed = [];
  
  propQuestions.forEach(propQ => {
    let match = templateQuestions.filter(x => x.id === propQ.id);
    if(match.length === 1) {
      if(match[0].question != propQ.question){
        changed.push({ id: propQ.id, prop: propQ.question, template: match[0].question})
      }
    } else {
      removed.push(propQ);
    }
  });

  templateQuestions.forEach(templateQ => {
    let match = propQuestions.filter(x => x.id === templateQ.id);
    if(match.length === 0) {
      added.push(templateQ)
    }
  });

  return { removed, added, changed}
}