<template>
  <div class="v-header-edit">
    <h5 class="v-header-edit-label">
      {{ internalText }}<span class="v-header-edit-badge"><i class="fas"></i></span>
      <span class="badge title-badge" :class="formBadgeStatus">{{ stateMessage }}</span>
    </h5>
  </div>
</template>

<script>
// Credit: https://github.com/myokyawhtun/label-edit/blob/master/src/LabelEdit.vue
// TODO: USE SLOTS & Styling class as appropriate

export default {
  name: 'VHeaderEdit',
  props: {
    // parent should provide :text or :placeholder
    text: String,
    placeholder: {
      type: String,
      default: 'Enter some text'
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    stateMessage: String,
    formBadgeStatus: String,
  },
  data: function() {
    return {
      edit: false, // define whether it is in edit mode or not
      internalText: '', // v-bind data model for input text
      allowEmit: true
    };
  },
  computed: {
    vplaceholder: function() {
      return this.placeholder;
    }
  },
  methods: {
    initText(value) {
      if (this.allowEmpty === false && (value == undefined || isEmpty(value))) {
        this.internalText = this.vplaceholder;
      } else {
        this.internalText = value;
      }
    }
  },
  // Whenever updated lifecyle force it to focus on the element. This is important to make sure the blur event works as expected
  updated() {
    var el = this.$refs.headeredit;
    if (el != null) {
      el.focus();
    }
  },
  mounted() {
    this.initText(this.text);
  },
  watch: {
    text: function(value) {
      this.initText(value);
    }
  }
};


function isEmpty(str){
  return (str.length === 0 || !str.trim());
}
</script>

<style scoped>
.v-header-edit {
  width: 100%;
}

.v-header-edit-badge {
  font-size: 0.5em !important;
  margin-left: 0.5em;
  position: relative;
  top: -10px;
  display: none;
}

.badge-notify {
  position: relative;
  top: -10px;
  left: -10px;
}

.icon-holder {
  width: 50px;
  margin-right: -12px;
}

.title-badge {
  font-size: 0.5em;
  vertical-align: top;
  margin-left: 0.5em;
}

.width-120 {
  width: 120px;
}
</style>
