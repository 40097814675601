<template>
  <div>
    <p :id="elementId" class="question-label">{{ text }}</p>
    <div class="question-section">
      <div v-for="content in sections" :key="content.id" class="form-group question-group-print">
        <EV3EditorViewItemHolder :proposalItem="content" :allowSections="true"></EV3EditorViewItemHolder>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EV3VQuestionSubSection',
  props: {
    id: String,
    text: String,
    sections: Array,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    }
  },
};
</script>

<style scoped>
.question-section >>> .question-label {
  font-size: 1rem !important;
}

.question-section >>> .subtitle-label {
  font-size: 1rem !important;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.5;
}
</style>
