import '@broker/editorV3/tools/index.css';
import '@broker/editorV3/tools/eQHeader.css';

import { IconH2, IconHeading } from '@codexteam/icons';
import createIcon from '@broker/editorV3/tools/utils/icon.js';
import { createConditionalRenderTune } from '@broker/editorV3/tools/utils/conditionalRenderTune.js';

export default class eQHeader {
  constructor({ block, data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      block: this.api.styles.block,
      wrapper: 'ce-header',
    };

    this._settings = config;

    this._data = this.normalizeData(data);

    this._element = this.getTag();

    this.block = block;
  }

  normalizeData(data) {
    const newData = {};

    if (typeof data !== 'object') {
      data = {};
    }

    newData.text = data.text || '';
    newData.level = 2;

    return newData;
  }

  getBlockName() {
    return this.block.name;
  }

  render() {
    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('header-container-div');
    this._icon = this._icon = createIcon(this.getBlockName());

    this.containerDiv.appendChild(this._icon);
    this.containerDiv.appendChild(this._element);
    return this.containerDiv;
  }

  renderSettings(){
    return createConditionalRenderTune(this.api)
  }

  validate(blockData) {
    if (!blockData.text.trim()) {
      blockData.text = '';
    }
    return true;
  }

  save(toolsContent) {
    const text = toolsContent.innerText;
    return {
      text: text.trim(),
    };
  }

  static get sanitize() {
    return {
      level: false,
      text: {},
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  get data() {
    this._data.text = this._element.innerHTML;
    this._data.level = this.currentLevel.number;

    return this._data;
  }

  set data(data) {
    this._data = this.normalizeData(data);

    if (data.level !== undefined && this._element.parentNode) {
      const newHeader = this.getTag();

      newHeader.innerHTML = this._element.innerHTML;

      this._element.parentNode.replaceChild(newHeader, this._element);

      this._element = newHeader;
    }

    if (data.text !== undefined) {
      this._element.innerHTML = this._data.text || '';
    }
  }

  getTag() {
    const tag = document.createElement(this.currentLevel.tag);

    tag.innerHTML = this._data.text || '';

    tag.classList.add(this._CSS.wrapper);

    tag.contentEditable = this.readOnly ? 'false' : 'true';

    tag.dataset.placeholder = this.api.i18n.t(this._settings.placeholder || '');

    return tag;
  }

  get currentLevel() {
    let level = this.levels.find((levelItem) => levelItem.number === this._data.level);

    if (!level) {
      level = this.defaultLevel;
    }

    return level;
  }

  get levels() {
    const availableLevels = [
      {
        number: 2,
        tag: 'H2',
        svg: IconH2,
      },
    ];

    return this._settings.levels ? availableLevels.filter((l) => this._settings.levels.includes(l.number)) : availableLevels;
  }

  onPaste(event) {
    const content = event.detail.data;

    let level = this.defaultLevel.number;

    switch (content.tagName) {
      case 'H2':
        level = 2;
        break;
    }

    if (this._settings.levels) {
      level = this._settings.levels.reduce((prevLevel, currLevel) => {
        return Math.abs(currLevel - level) < Math.abs(prevLevel - level) ? currLevel : prevLevel;
      });
    }

    this.data = {
      level,
      text: content.innerHTML,
    };
  }

  static get pasteConfig() {
    return {
      tags: ['H2'],
    };
  }

  static get toolbox() {
    return {
      icon: IconHeading,
      title: 'Heading',
    };
  }
}
