const debug = process.env.NODE_ENV !== 'production';

function initialState() {
  return {
    comments: [], // needs to be array so that changes to it are observed. Tried dictionary and changes not observed
    user: '',
    proposalIdOrUid: ''
  };
}
const state = initialState();

let commentsApi = null;
export const getters = {
  comments: (state) => (questionId) => {
    questionId = questionId.toString();
    return state.comments.filter((x) => x.questionId === questionId);
  },
};

export const mutations = {
  dbComments(state, payload) {
    if (debug) console.log('comment.dbComments ', payload);

    payload.forEach((element) => {
      element.createdAt = new Date(element.createdAt);
    });

    state.comments = payload;
  },
  addComment(state, payload) {
    if (debug) console.log('comment.addComment ', payload);

    payload.createdAt = new Date(payload.createdAt);

    state.comments.push(payload);
  },
  deleteComment(state, commentid)
  {
      const arrayWithNotDeletedComments = state.comments.filter(obj => obj.id != commentid )
      state.comments = arrayWithNotDeletedComments;
  },
  resetStateWithPayload(state, payload) {
    if (debug) console.log('comment.setUser ', payload);

    state.user = payload.user;
    state.comments = [];
    state.proposalIdOrUid = payload.proposalIdentifier;
  }
};

const actions = {
  async INIT({ commit, state }, payload) {
    commit('resetStateWithPayload', payload)
    commentsApi = this._vm.$api.getCommentsApi(payload.isRestrictedPage || false);
    let res = await commentsApi.getProposalComments(state.proposalIdOrUid)
    commit('dbComments', res.data);
  },
  async NEW_COMMENT({ state, commit }, payload) {
    const res = await commentsApi.postComment(state.proposalIdOrUid, payload);
    commit('addComment', res.data);
    // TODO: add try catch and Should mark state as dirty and attempt to save all on form exit/next save
  },
  async DELETE_COMMENT({commit}, commentid) {
    await commentsApi.deleteComment(state.proposalIdOrUid, commentid);
    commit('deleteComment', commentid);
    // TODO: add try catch and Should mark state as dirty and attempt to save all on form exit/next save
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};