<template>
  <div :id="elementId">
    <label :for="elementId + '_in'" class="question-label">{{ question }}</label>
    <div>
      <date-picker
        :id="elementId + '_in'"
        v-model="dateValue"
        type="date"
        value-type="format"
        format="DD-MMM-YYYY"
      ></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { DatePicker },
  props: {
    id: Number,
    question: String,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    dateValue: {
      get() {
        return this.$store.getters['print/answer'](this.id);
      },
    },
  },
};
</script>

<style>
.is-invalid .mx-input {
  border-color: #f04124 !important;
}
.is-valid .mx-input {
  border-color: #28a745 !important;
}
</style>
