import _ from 'lodash';

/* eslint-disable no-unused-vars */
const debug = process.env.NODE_ENV !== 'production';

// also register component in EV3EditorViewItemHolder
const components = [
  { type: 'text', component: 'VText', name: 'Text' },
  { type: 'section', component: 'VSectionTitle', name: 'Title' },
  { type: 'subsection', component: 'EV3VQuestionSubSection', name: 'Subsection' },
  { type: 'qshort', component: 'VQShort', name: 'Short' },
  { type: 'qnumber', component: 'VQNumber', name: 'Number' },
  { type: 'qlong', component: 'VQLong', name: 'Multiline' },
  { type: 'qyn', component: 'VQYesNo', name: 'Yes/No' },
  { type: 'qoption', component: 'VQOption', name: 'Multiple Choice' },
  { type: 'qcheck', component: 'VQCheck', name: 'Checkbox' },
  { type: 'qtable', component: 'VQTable', name: 'Table' },
  { type: 'qselect', component: 'VQSelect', name: 'Dropdown' },
  { type: 'qdate', component: 'VQDate', name: 'Date' },
  { type: 'qupload', component: 'VQUpload', name: 'Upload' },
  { type: 'qcompanyhousename', component: 'VQCompanyHouseName', name: 'Company House Searchbar' },
  { type: 'qcharityname', component: 'VQCharityName', name: 'Charity Commission Searchbar' },
  { type: 'qsign', component: 'VQSign', name: 'Signature' },
];

const componentsDict = _.keyBy(components, (o) => o.type);


export default {
  component(type) {
    return type in componentsDict ? componentsDict[type].component : 'VNone';
  },
};

/* eslint-enable no-unused-vars */