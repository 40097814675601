const debug = process.env.NODE_ENV !== 'production';

export function findRecursively(item, id) {
  let result = findRecursivelyInternal(item, id);
  if(!result){
    if (debug) console.log("Could not find element with id ", id)
    throw "Could not find element with id " + id;
  }

  return result;
}

function findRecursivelyInternal(item, id){
  if (item.id === id) {
    return item;
  } else if (item.sections) {
    for (let index = 0; index < item.sections.length; index++) {
      const element = item.sections[index];
      let result = findRecursivelyInternal(element, id);
      if (result) {
        return result;
      }
    }
  }

  return null;
}

export function hierarchyRecursivelyIds(item, id) {
  let res = hierarchyRecursively(item, id).map(x => x.id);
  return res;
}

export function hierarchyRecursively(item, id) {
  if (item.id === id) {
    return [item];
  }
  if (!item.sections) {
    return null;
  }

  for (let index = 0; index < item.sections.length; index++) {
    const element = item.sections[index];

    let result = hierarchyRecursively(element, id);
    if (result) {
      return [item, ...result];
    }
  }
}