import _ from 'lodash';

/* eslint-disable no-unused-vars */
const debug = process.env.NODE_ENV !== 'production';

// also register component in EditorViewItemHolder and VSubsection
const components = [
  { type: 'text', component: 'VText', name: 'Text' },
  { type: 'section', component: 'VSectionTitle', name: 'Title' },
  { type: 'subsection', component: 'VQuestionSubSection', name: 'Subsection' },
  { type: 'qshort', component: 'VQShort', name: 'Short' },
  { type: 'qnumber', component: 'VQNumber', name: 'Number' },
  { type: 'qlong', component: 'VQLong', name: 'Multiline' },
  { type: 'qyn', component: 'VQYesNo', name: 'Yes/No' },
  { type: 'qoption', component: 'VQOption', name: 'Multiple Choice' },
  { type: 'qcheck', component: 'VQCheck', name: 'Checkbox' },
  { type: 'qtable', component: 'VQTable', name: 'Table' },
  { type: 'qselect', component: 'VQSelect', name: 'Dropdown' },
  { type: 'qdate', component: 'VQDate', name: 'Date' },
  { type: 'qupload', component: 'VQUpload', name: 'Upload' },
  { type: 'qcompanyhousename', component: 'VQCompanyHouseName', name: 'Company House Searchbar' },
  { type: 'qcharityname', component: 'VQCharityName', name: 'Charity Commission Searchbar' },
  { type: 'qsign', component: 'VQSign', name: 'Signature' },
];

const componentsDict = _.keyBy(components, (o) => o.type);
const componentsByNameDict = _.keyBy(components, (o) => o.name);
const componentsNamesArr = components.map(element => element.name);

export default {
  component(type) {
    return type in componentsDict ? componentsDict[type].component : 'VNone';
  },
  getComponents(){
    return componentsNamesArr;
  },
  name(type){
    return type in componentsDict ? componentsDict[type].name : 'Unknown';
  },
  type(name){
    return name in componentsByNameDict ? componentsByNameDict[name].type : 'Unknown';
  },
  questionWithSelection(type){
    return type === 'qoption' || type === 'qcheck' || type === 'qselect' || type === 'qyn';
  },
  questionWithOptions(type){
    return type === 'qoption' || type === 'qcheck' || type === 'qselect';
  },
  isNotQuestion(type){
    return type === 'text' || type === 'section' || type === 'subsection'|| type === 'subtitle';
  }
};

/* eslint-enable no-unused-vars */