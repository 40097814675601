<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <EV3JsonEditorUI class="json-editor" :form="form"></EV3JsonEditorUI>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" @click="$emit('close')">Close</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EV3JsonEditorUI from '@broker/editorV3/ui/EV3JsonEditorUI.vue';
export default {
  
  props: {
    form: {
      required: true,
      type: Object ,
    },
  },
  components: {
    EV3JsonEditorUI,
  },
};
</script>

<style scoped>
.json-editor {
  width: 90%;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.modal-container {
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
}

.modal-header {
  align-items: center;
  justify-content: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
