import _ from 'lodash'

export function enrichTableQuestion(newTable) {
  newTable.tableEditData = newTable.tableEditData || mapToEditorData(newTable.columns, newTable.tableData);
  
  // create header row
  newTable.columns = mapToColumnHeaders(newTable.tableEditData)
  newTable.isAddRowsEnabled = newTable.isAddRowsEnabled || false;

  // create rest of table
  newTable.tableData = [];
  if(newTable.tableEditData.length > 1){
    // map to columns
    for (let row = 1; row < newTable.tableEditData.length; row++) {
      const rowObj = {};
      newTable.tableData.push(rowObj);

      for (let col = 0; col < newTable.tableEditData[row].length; col++) {
        const cell = newTable.tableEditData[row][col];
        let header = newTable.columns[col];
        rowObj[header.field] = cell
      }  
    }
  }
}

export function mapToColumnHeaders(editData) {
  let headers = [];

  if(editData.length === 0)
    return [];

  const headerRow = editData[0];

  for (let idx = 0; idx < headerRow.length; idx++) {
    const element = headerRow[idx];
    let header = {
      headerName: element ,
      field: _.camelCase(element + '_' + idx), 
    };
    headers.push(header);
  }
  return headers;
}


export function mapToEditorData(columns, tableData){
  const editData = [];
  if(columns){
    let headers = columns.map(x => x.headerName)
    editData.push(headers);

    if(tableData){
      tableData.forEach(row => {
        let rowArr = Array(headers.length).fill('');
        for(let col in row){
          let headerIdx = columns.findIndex(x => x.field === col);
          rowArr[headerIdx] = row[col];
        }
        editData.push(rowArr);
      });
    }
  }

  return editData

}