<template>
  <div>
    <b-form-select class="mb-2" v-model="selected" :options="questionsChoices"></b-form-select>
    <b-form-select :value="selectedOpt" :options="selectedQuestionsAnswers" @input="onAnswerSelected"></b-form-select>
    <button class="btn btn-secondary mt-3" @click="resetConditional">Reset</button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      selected: null,
      selectedOpt: null,
    };
  },

  computed: {
    ...mapState('editorV3', ['ifQs']),
    ...mapGetters('editorV3', ['getSelectableQuestions', 'getQuestionInDbRepresentation']),
    questionsChoices() {
      let defaultVal = { value: null, text: 'Please select a question', disabled: true };
      let optionQuestions = this.getSelectableQuestions(this.id);
      let questions = [defaultVal]
      for(let i = 0; i <optionQuestions.length; i++){
        questions.push({ value: optionQuestions[i].id, text: optionQuestions[i].question });
      }

      return questions;
    },
    selectedQuestionsAnswers() {
      let defaultVal = { value: null, text: 'Please select an answer', disabled: true };
      if (!this.selected) {
        return [defaultVal];
      }

      let dependentQuestion = this.getQuestionInDbRepresentation(this.selected);
      if (dependentQuestion.options) {
        return [defaultVal, ...dependentQuestion.options];
      } else {
        return [defaultVal, 'Yes', 'No'];
      }
    },
  },
  methods: {
    onAnswerSelected(selAnswer) {
      if(!selAnswer){
        return;
      }
      const conditional = { targetId: this.id, triggerOption: selAnswer,  triggerId: this.selected };    
      this.$store.commit('editorV3/addIfQToStore', conditional);
    },
    resetConditional() {
      this.$store.commit('editorV3/removeIfQs', this.id);
      this.selected = null;
      this.selectedOpt = null;
    },
  },
  created() {
    const found = this.ifQs.find((element) => element.targetId === this.id);
    if(found){
      this.selected = found.triggerId;
      this.selectedOpt = found.triggerOption;

    }
  },
};
</script>

<style scoped></style>
