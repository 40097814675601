<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="lay-card mt-5 mb-5">
          <form class="mt-3" @submit.prevent="handleSubmit" novalidate>
            <div class="lay-card-top">
              <ClientDetailsForm
                ref="clientDetails"
              />
            </div>

            <div class="lay-card-bottom">
              <VEditButton class="eb-subtle" type="button" @click="handleCancel">Cancel</VEditButton>
              <VEditButton class="eb-success" type="submit">Save</VEditButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientDetailsForm from '@broker/clientProposals/ui/ClientDetailsForm.vue';

// see https://www.digitalocean.com/community/tutorials/vuejs-vue-form-handling
export default {
  components: {
    ClientDetailsForm
  },
  data() {
    return {
      isSubmitted: false,
    };
  },
  methods: {
    handleSubmit() {
      let clientDetailsForm = this.$refs.clientDetails;
      let clientData = this.$refs.clientDetails.client;
      
      if (this.$debug) console.log('submit client');
      clientDetailsForm.isSubmitted = true;

      if (clientDetailsForm.nameValid) {
        if(clientData.introducer){
          clientData.introducerId = clientData.introducer.id;
        }

        this.$api.company.postNewCompany(clientData).then(res => {
          this.$router.push(`/broker/client/${res.data}/proposals`);
        });
      }
    },
    handleCancel() {
      this.$router.push(`/broker/clients`);
    },
  }
};
</script>

<style></style>
