<template>
  <div>
    <modal name="setEmailsModal">
      <div class="container" style="padding: 2rem">
        <h5 class="text-center">People With Access</h5>
        <div class="text-center">
          <p>Fill in the below field to update the assigned admin users for the company.</p>
          <p class="text-muted">
            Add emails using a <b>semicolon (;)</b> to seperate emails
          </p>
        </div>
        <form @submit.prevent="showConfirmationModal" class="my-flex">
          <input
            v-model="adminEmail"
            class="emailInput form-control"
            id="adminInput"
            style="width: 74%"
            placeholder="Email Address(es)"
            required
            :aria-invalid="!emailValid"
            :class="stateClass(emailValid)"
            aria-required="true"
          />
          <button type="submit" for="adminInput" class="btn btn-success">Set Admin User</button>
        </form>
        <div class="text-center" style="padding-top: 1rem;">
          <p class="text-muted">
            <b>Current Admin:</b> {{assignedAdmin}}
          </p>
        </div>
      </div>
    </modal>

    <modal :height="250" :width="550" name="confirmationModal">
      <div class="container" style="padding: 2rem">
        <div class="text-center">
          <h5 style="padding-top: 1rem">Admin User access will be assigned to:</h5>
          <h5 style="padding-top: 0rem">{{ adminEmail }}</h5>
          <h5 style="padding-top: 1rem">Are you sure?</h5>
          <VEditButton style="margin-right: 1rem" class="eb-success" @click="submitNewAdmin">Yes</VEditButton>
          <VEditButton style="margin-left: 1rem" class="eb-danger" @click="hideConfirmationModal">No</VEditButton>
        </div>
      </div>
    </modal>

    <modal :height="250" :width="550" name="successModal">
      <div class="container" style="padding: 2rem">
        <div v-if="adminAssignSuccess === true" class="text-center">
          <h5 style="padding-top: 1rem">Admin User access succesfully assigned to:</h5>
          <h5 style="padding-top: 0rem">{{ adminEmail }}</h5>
          <i class="fa fa-check checkmark"></i>
        </div>
        <div v-if="adminAssignSuccess === false" class="text-center">
          <h5 style="padding-top: 1rem">Something went wrong!</h5>
          <h5 style="padding-top: 0rem">Unfortunately, the admin user was not assigned.</h5>
          <i class="fa fa-times timesIcon"></i>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      adminEmail: '',
      assignedAdmin: '',
      adminAssignSuccess: false,
      isSubmitted: false,
      mailRegex:
        // eslint-disable-next-line no-useless-escape
        /^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/,
    };
  },
  props: {
    clientId: Number,
  },
  computed: {
    emailValid() {
      return this.adminEmail.length > 2 && this.mailRegex.test(this.adminEmail)
    }
  },
  created () {
    this.getAssignedAdminEmail();
  },
  methods: {
    showSetEmailsModal() {
      this.$modal.show('setEmailsModal');
    },
    showConfirmationModal() {
      this.isSubmitted = true;
      if (!this.mailRegex.test(this.adminEmail)) return;
      this.$modal.show('confirmationModal');
    },
    hideConfirmationModal() {
      this.$modal.hide('confirmationModal');
    },
    async submitNewAdmin() {
      let timeout = setTimeout(() => (this.adminAssignSuccess = false), 6000);
      this.setAdminUser().then((res) => {
        res ? (this.adminAssignSuccess = true) : (this.adminAssignSuccess = false);
        clearTimeout(timeout);
        this.getAssignedAdminEmail();
      });
      this.$modal.show('successModal');
      this.$modal.hide('setEmailsModal');
      this.hideConfirmationModal();
    },
    async setAdminUser() {
      let res = await this.$api.company.setAdminEmail(this.clientId, this.adminEmail);
      return res.data;
    },
    async getAssignedAdminEmail() {
      let res = await this.$api.company.getAssignedAdmin(this.clientId);
      this.assignedAdmin = res.data;
    },
    stateClass(computedItem) {
      if (!this.isSubmitted) return '';

      return computedItem ? 'is-valid' : 'is-invalid';
    },
  },
};
</script>

<style>
.my-flex {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.checkmark {
  font-size: 4.0625rem;
  line-height: 1;
  color: #24b663;
}
.timesIcon {
  font-size: 4.0625rem;
  line-height: 1;
  color: #b62424;
}
</style>