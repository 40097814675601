import eQOptionQuestion from '@broker/editorV3/tools/eQOptionQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';

export default class eQCheck extends eQOptionQuestion {
  static get toolbox() {
    return {
      icon: toolsInfo.find((tool) => tool.type === 'qcheck').icon,
      title: 'Checklist',
    };
  }
}
