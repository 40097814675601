<template>
  <div class="container-fluid">
    <div class="row" style="margin-top: 1rem !important">
      <div class="col col-xl-6 col-lg-4 col-md-3">
        <VEditButton class="eb-fade" @click="onToLibrary">Back</VEditButton>
        <VEditButton class="eb-sec" @click="showUpload">{{ importLabel }}</VEditButton>
        <VEditButton class="eb-prim" @click="showCurrentJson">JSON</VEditButton>
        <VEditButton class="eb-prim" @click="showLastSavedJson">DB JSON</VEditButton>

        <AutonumberDropdown v-if="editorReady" @autonumber="autoNumber" @sectionalnumbering="sectionalNumbering" @removenumbering="removenumbering"></AutonumberDropdown>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-7 align-items-baseline" style="display: flex; align-items: center">
        <label class="mr-3">Save As:</label>
        <input type="text" v-model.lazy="saveAsName" class="col-md-10 form-name-edit form-control" />
      </div>
      <div class="col-xl-2 col-lg-2 col-md-1">
        <VEditButton class="eb-success" @click="saveToDatabase">Save</VEditButton>
      </div>
    </div>
    <hr />

    <!-- main content -->
    <div class="row" :class="baseline">
      <div id="editorjs" class="col-md pl-5">
        <VHeaderEdit
          :text="displayTitle"
          :allowEmpty="false"
          @text-updated="onUpdatedText"
          placeholder="Enter a title that will be displayed to respondents"
        ></VHeaderEdit>
      </div>
      <div class="display col-md">
        <h1>{{ displayTitle }}</h1>
        <EV3VFormDisplay :sections="getForm.sections"></EV3VFormDisplay>
      </div>

      <!-- Popups -->
      <EV3JsonEditor v-if="isJsonMode" @close="hideJson" :form="getJsonForm"></EV3JsonEditor>
      <ImportPopup v-if="isUpload" @upload="onFormUploaded" @close="showUpload" :templateId="getTemplateId"></ImportPopup>
      <ConditionalPopUp
          v-if="conditionalPreview"
          @close="closeConditionalRendering"
          :questionData="conditionalRenderQuestionData"
        ></ConditionalPopUp>
    </div>
  </div>
</template>

<script>
import EditorJS from '@editorjs/editorjs';
import { mapGetters, mapState } from 'vuex';

import EV3VFormDisplay from '@broker/editorV3/ui/render/EV3VFormDisplay.vue';
import ConditionalPopUp from '@broker/editorV3/ui/ConditionalPopUp.vue';
import { editorjsConfig } from '@broker/editorV3/tools/utils/editorConfig.js';
import ImportPopup from '@broker/editorV3/ui/ImportPopup.vue';
import EV3JsonEditor from '@broker/editorV3/ui/EV3JsonEditor.vue';
import AutonumberDropdown from '@broker/editorV3/ui/AutonumberDropdown.vue';

const UPDATE_TITLE = 'editorV3/updateTitle';

export default {
  components: {
    EV3VFormDisplay,
    ImportPopup,
    EV3JsonEditor,
    ConditionalPopUp,
    AutonumberDropdown
  },
  data() {
    return {
      isPreview: false,
      isUpload: false,
      isJsonMode: false,
      isLastSavedJson: false,
      conditionalPreview: false,
      editorObserver: null,
      subsectionObserver: null,
      conditionalRenderQuestionData: null,
      editor: new EditorJS({
        ...editorjsConfig,
        holder: 'editorjs', // ALOM: This is a ui detail so should be here rather than in the ui bit
        onReady: () => {
          this.onEditorReady();
        },
        onChange: async (api) => {
          setTimeout(() => {
            api.saver.save().then((editorData) => this.saveToStore(editorData));
          }, 200);
        },
      }),
      editorReady: false,
    };
  },
  props: ['formId', 'isNew'],
  watch: {
    $route: 'fetchData',
    // ALOM: Updating editorjs was inconsistent -> Leads to bugs. This should be the only thing allowed to call the render function
    updateEditorView: async function (newValue) {
      if (this.$debug) console.log('updateEditorView', newValue)
      if (newValue  === true) {
        if(this.editor.save){
          const current = await this.editor.save();
          if(isDifferent(current.blocks, this.editorJSData.blocks)){
            if (this.$debug) console.log('updatingEditorView')
            this.editor.blocks.render(this.editorJSData);
          }else{   
            if (this.$debug) console.log('updateEditorView: Not updating. Data is same')
          }
        }
        this.$store.commit('editorV3/setUpdateEditorView', false);
      }
    },
  },
  computed: {
    ...mapState('editorV3', ['editorJSData', 'displayTitle', 'formName', 'updateEditorView', 'dbId', 'lastDbForm']),
    ...mapGetters('editorV3', ['getForm', 'getQuestionInDbRepresentation']),
    saveAsName: {
      get() {
        return this.formName;
      },
      set(value) {
        if (this.$debug) console.log('onUpdatedFormName', value);
        this.$store.commit('editorV3/updateFormName', value);
      },
    },
    importLabel() {
      return this.isUpload ? 'Cancel' : 'Import';
    },
    baseline() {
      return this.editorJSData.blocks.length > 0 ? 'editor-display-container-baseline' : '';
    },
    getTemplateId(){
      return this.dbId;
    },
    getJsonForm(){
      return this.isLastSavedJson? this.lastDbForm : this.getForm
    }
  },
  methods: {
    togglePreview() {
      this.isPreview = !this.isPreview;
    },
    async saveToStore() {
      if (this.editor.save){
        let editorJsState = await this.editor.save();
        if (this.$debug) console.log('saveToStore', editorJsState);
        this.$store.dispatch('editorV3/SAVE_TO_STORE', editorJsState);
      }
    },
    async saveToDatabase() {
      if (this.$debug) console.log('onsave');
      
      await this.saveToStore(); // Alom -> it was weird that save to store was not happening before the save to db?
      await this.$store.dispatch('editorV3/SAVE_TO_DATABASE');
      if (this.isNew) {
        this.$router.push(`/broker/proposalwriter/formTemplate/${this.$store.state.editorV3.dbId}/edit`);
      }
      this.$toast('Saved');
    },
    async onEditorReady() {
      await this.fetchData();
      this.initialiseObservers();
      this.editorReady = true;
    },
    initialiseObservers() {
      let subsectionObserver = new MutationObserver(() => {
        this.saveToStore();
      });
      let observer = new MutationObserver((mutationList) => {
        mutationList.forEach((mutation) => {
          if (mutation.addedNodes.length > 0) {
            let subsection = mutation.addedNodes[0].querySelector('.subsection-container');
            if (subsection != null) {
              subsectionObserver.observe(subsection.parentNode, { childList: true });
            }
          }
        });
      });
      const editor = document.querySelector('.codex-editor__redactor');
      observer.observe(editor, { childList: true });
      this.editorObserver = observer;
      this.subsectionObserver = subsectionObserver;
    },
    // Alom Here we want one consistent way of updating the store. 
    async autoNumber() {
      await this.saveToStore();
      this.$store.dispatch('editorV3/AUTONUMBER_DEFAULT');
    },
    async sectionalNumbering() {
      await this.saveToStore();
      this.$store.dispatch('editorV3/AUTONUMBER_SECTIONAL');
    },
    async removenumbering(){
      await this.saveToStore();
      this.$store.dispatch('editorV3/AUTONUMBER_REMOVE');
    },
    closeConditionalRendering() {
      this.conditionalPreview = false;
      this.conditionalRenderQuestionData = null;
    },
    async fetchData() {
      if (this.isNew) {
        await this.$store.dispatch('editorV3/INIT_NEW_TEMPLATE');
      } else {
        await this.$store.dispatch('editorV3/INIT_DB_TEMPLATE', this.formId);
      }
    },
    onUpdatedText(value) {
      if (this.$debug) console.log('onUpdatedText', value);
      this.$store.commit(UPDATE_TITLE, value);
    },
    showUpload() {
      this.isUpload = !this.isUpload;
    },
    onFormUploaded(data) {
      this.$store.dispatch('editorV3/INIT_UPLOAD', data);
      this.isUpload = false;
    },
    onToLibrary() {
      this.$router.push('/broker/formTemplates');
    },
    hideJson() {
      this.isJsonMode = false
    },
    showCurrentJson() {
      this.isLastSavedJson = false
      this.isJsonMode = true
    },
    showLastSavedJson() {
      this.isLastSavedJson = true
      this.isJsonMode = true
    },
    cleanUpComponent() {
      this.editorObserver.disconnect();
      this.subsectionObserver.disconnect();
      if (this.editor.destroy) {
        this.editor.destroy();
      }
      this.$store.commit('editorV3/resetState');
      document.removeEventListener('conditional-render', this.handleConditionalRender)
    },
    async handleConditionalRender(e) {
      this.conditionalRenderingWithId(e.detail.id);
    },
    conditionalRenderingWithId(id) {
      this.conditionalRenderQuestionData = this.getQuestionInDbRepresentation(id);
      this.conditionalPreview = true;
    },

  },
  mounted() {
    document.addEventListener('conditional-render', this.handleConditionalRender);
  },
  destroyed() {
    this.cleanUpComponent();
  },
};

function isDifferent(left, right){
  if(!left || !right){
    return true;
  }

  if (left.length != right.length){
    return true;
  }

  for (let i = 0; i < left.length; i++) {
    const leftItem = left[i];
    const rightItem = right[i];
    if(leftItem.id !== rightItem.id || leftItem.type !== rightItem.type){
      return true;
    }

    if(leftItem.data.text != rightItem.data.text){
      return true;
    }

    if (leftItem.data.itemContent && rightItem.data.itemContent){
      let result = isDifferent(leftItem.data.itemContent, rightItem.data.itemContent)
      if(result === true)
        return true;
    }
  }

  return false;
}
</script>

<style scoped>
#editorjs,
.display {
  height: calc(100vh - 190px);
  overflow: scroll;
  overflow-x: hidden;
}

.display {
  margin-top: 0 !important;
}

.editor-display-container-baseline {
  align-items: baseline;
}
</style>
