import { render, staticRenderFns } from "./PrintItemHolder.vue?vue&type=template&id=fd240838&scoped=true"
import script from "./PrintItemHolder.vue?vue&type=script&lang=js"
export * from "./PrintItemHolder.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd240838",
  null
  
)

export default component.exports