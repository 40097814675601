/**
 * Build styles
 */
import '@broker/editorV3/tools/index.css';

import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import { createConditionalRenderTune} from '@broker/editorV3/tools/utils/conditionalRenderTune.js';


export default class eQOptionQuestions {
  /**
   * Notify core that read-only mode is supported
   *
   * @returns {boolean}
   */
  static get isReadOnlySupported() {
    return true;
  }

  /**
   * Allow to use native Enter behaviour
   *
   * @returns {boolean}
   * @public
   */
  static get enableLineBreaks() {
    return true;
  }

  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - tool constructor options
   * @param {ListData} params.data - previously saved data
   * @param {object} params.api - Editor.js API
   * @param {boolean} params.readOnly - read-only mode flag
   */
  constructor({ block, data, api, readOnly }) {
    this.qQuestion = new eQSimpleQuestion({ api, data, block });

    this._list = {
      wrapper: null,
    };
    this.containerDiv = document.createElement('div');
    this.block = block;
    this.api = api;
    this.readOnly = readOnly;

    /**
     * Tool's data
     *
     * @type {ListData}
     */
    this._data = {
      style: 'unordered',
      items: [],
    };

    this.data = data;
  }


  /**
   * Returns a container with a question text input and unordered list input
   *
   * @returns {Element}
   * @public
   */
  render() {
    const questionAndIconContainer = document.createElement('div');
    questionAndIconContainer.classList.add('question-icon-container-div');

    this.questionDiv = this.qQuestion.render();

    this._list.listWrapper = this.makeUnorderedList(this._data.style);
    this.fillListWithData();

    if (!this.readOnly) {
      this.enableKeyDownEventListener();
    }

    questionAndIconContainer.appendChild(this.questionDiv);
    this.containerDiv.appendChild(questionAndIconContainer);
    this.containerDiv.appendChild(this._list.listWrapper);

    return this.containerDiv;
  }

  renderSettings() {
    return [createConditionalRenderTune(this.api)];
  }

  enableKeyDownEventListener() {
    this.ctrlDown = false;
    this._list.listWrapper.addEventListener(
      'keydown',
      (event) => {
        const [ENTER, BACKSPACE, CTRL, a, A] = [13, 8, 17, 65, 97]; // key codes

        switch (event.keyCode) {
          case ENTER:
            this.getOutofList(event);
            break;
          case BACKSPACE:
            this.backspace(event);
            break;
          case CTRL:
            this.ctrlDown = true;
            break;
          case A:
            if (this.ctrlDown) {
              this.selectItem(event);
            }
            break;
          case a:
            if (this.ctrlDown) {
              this.selectItem(event);
            }
            break;
        }
      },
      false
    );
    this._list.listWrapper.addEventListener('keyup', (event) => {
      const CTRL = 17;
      switch (event.keyCode) {
        case CTRL:
          this.ctrlDown = false;
      }
    });

    this.questionDiv.addEventListener('keydown', (event) => {
      const [ENTER] = [13];
      if (event.keyCode === ENTER) {
        event.preventDefault();
        this._list.listWrapper.focus();
      }
    });
  }

  fillListWithData() {
    if (this._data.items.length) {
      this._data.items.forEach((item) => {
        this._list.listWrapper.appendChild(
          this._make('li', this.CSS.item, {
            innerText: item,
          })
        );
      });
    } else {
      this._list.listWrapper.appendChild(this._make('li', this.CSS.item));
    }
  }

  save(blockContent) {
    const questionBlock = blockContent.querySelector('.editor-question-block');
    const questionData = this.qQuestion.save(questionBlock);

    const data = { ...questionData, items: this.data.items };

    return data;
  }



  validate(savedData){
    return this.qQuestion.validate(savedData) && this.hasOptions(savedData)
  }

  hasOptions(savedData){
    return savedData.items.length > 0
  }

  /**
   * Sanitizer rules
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      style: {},
      items: {
        br: true,
      },
    };
  }

  /**
   * Creates main <ul>
   *
   * @returns {HTMLOListElement|HTMLUListElement}
   */
  makeUnorderedList() {
    const styleClass = this.CSS.wrapperUnordered;
    const tag = 'ul';

    return this._make(tag, [this.CSS.baseBlock, this.CSS.wrapper, styleClass], {
      contentEditable: !this.readOnly,
    });
  }

  /**
   * Styles
   *
   * @private
   */
  get CSS() {
    return {
      baseBlock: this.api.styles.block,
      wrapper: 'cdx-list',
      wrapperUnordered: 'cdx-list--unordered',
      item: 'cdx-list__item',
    };
  }

  /**
   * List data setter
   *
   * @param {ListData} listData
   */
  set data(listData) {
    if (!listData) {
      listData = {};
    }
    this._data.style = 'unordered';
    this._data.items = listData.items || [];

    const oldView = this._list.listWrapper;

    if (oldView) {
      oldView.parentNode.replaceChild(this.render(), oldView);
    }
  }

  /**
   * Return List data
   *
   * @returns {ListData}
   */
  get data() {
    this._data.items = [];

    const items = this._list.listWrapper.querySelectorAll(`.${this.CSS.item}`);

    for (let i = 0; i < items.length; i++) {
      const value = items[i].innerText.replace('<br>', ' ').trim();

      if (value) {
        this._data.items.push(value);
      }
    }

    return this._data;
  }

  /**
   * Helper for making Elements with attributes
   *
   * @param  {string} tagName           - new Element tag name
   * @param  {Array|string} classNames  - list or name of CSS classname(s)
   * @param  {object} attributes        - any attributes
   * @returns {Element}
   */
  _make(tagName, classNames = null, attributes = {}) {
    const el = document.createElement(tagName);

    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      el[attrName] = attributes[attrName];
    }

    return el;
  }

  /**
   * Returns current List item by the caret position
   *
   * @returns {Element}
   */
  get currentItem() {
    let currentNode = window.getSelection().anchorNode;

    if (currentNode.nodeType !== Node.ELEMENT_NODE) {
      currentNode = currentNode.parentNode;
    }

    return currentNode.closest(`.${this.CSS.item}`);
  }

  /**
   * Get out from List Tool
   * by Enter on the empty last item
   *
   * @param {KeyboardEvent} event
   */
  getOutofList(event) {
    const items = this._list.listWrapper.querySelectorAll('.' + this.CSS.item);
    /**
     * Save the last one.
     */
    if (items.length < 2) {
      return;
    }

    this.leaveListIfLastAndEmpty(items[items.length - 1], event);
  }

  leaveListIfLastAndEmpty(lastItem, event) {
    const currentItem = this.currentItem;
    if (currentItem === lastItem && !lastItem.textContent.trim().length) {
      /** Insert New Block and set caret */
      currentItem.parentElement.removeChild(currentItem);
      this.api.blocks.insert();
      this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex());
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Handle backspace
   *
   * @param {KeyboardEvent} event
   */
  backspace(event) {
    const items = this._list.listWrapper.querySelectorAll('.' + this.CSS.item);
    const firstItem = items[0];

    if (!firstItem) {
      return;
    }

    /**
     * Don't delete when list has only one item and is empty
     */
    if (listHasOnlyOneItemAndIsEmpty(items, firstItem)) {
      event.preventDefault();
    }

    function listHasOnlyOneItemAndIsEmpty() {
      return items.length < 2 && !firstItem.innerText.replace('<br>', ' ').trim();
    }
  }

  /**
   * Select LI content by CMD+A
   *
   * @param {KeyboardEvent} event
   */
  selectItem(event) {
    event.preventDefault();

    const selection = window.getSelection(),
      currentNode = selection.anchorNode.parentNode,
      currentItem = currentNode.closest('.' + this.CSS.item),
      range = new Range();

    range.selectNodeContents(currentItem);

    selection.removeAllRanges();
    selection.addRange(range);
  }

}
