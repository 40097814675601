<template>
  <div>
    <label :for='elementId' class='question-label'>{{question}}</label>
    <div>
      <date-picker :id='elementId' :value='dateValue' format='DD-MMM-YYYY'></date-picker>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker
  },
  props: {
    id: [Number, String],
    question: String,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    // ToDo: Get this from prop
    dateValue(){
      return this.$store.getters['editor/answer'](this.id);
    }
  }
};
</script>