import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
class eQYN extends eQSimpleQuestion {
  static get toolbox() {
    return {
      title: 'Yes/No',
      icon: toolsInfo.find((tool) => tool.type === 'qyn').icon,
    };
  }
}

export default eQYN;
