import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
class eQText extends eQSimpleQuestion {
  static get toolbox() {
    return {
      title: 'Text',
      icon: toolsInfo.find((tool) => tool.type === 'text').icon,
    };
  }

  static get sanitize() {
    return {
      text: false, // disallow HTML
      caption: {}, // only tags from Inline Toolbar
    };
  }

  static get isReadOnlySupported() {
    return true;
  }
}

export default eQText;
