<template>
  <dialog class="popup-container">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <h1>Conditional rendering</h1>
            <div class="modal-header pl-2">
              <h5>
                {{ questionData.question || questionData.text }}
              </h5>
              <SelectQIf class="mt-2" :id="questionData.id"></SelectQIf>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" @click="$emit('close')">Exit</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </dialog>
</template>

<script>
import SelectQIf from '@broker/editorV3/ui/SelectQIf.vue';
export default {
  components: {
    SelectQIf,
  },
  props: ['questionData'],
  mounted(){
    document.querySelector(".popup-container").showModal()
  }
};
</script>

<style scoped>
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
  max-height: 95%;
  overflow-y: auto;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.modal-container {
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header{
  display: flex;
  flex-direction: column;
}

h1 {
  text-align: center;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
li {
  list-style: none;
}
</style>
