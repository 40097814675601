<template>
  <button class='edit-button' @click='onClick' v-bind='$attrs'>
    <slot></slot>
  </button>
</template>

<script>
/*
See readme for documentation
*/
export default {
  methods: {
    onClick(event) {
      this.$emit('click', this.network, event);
    }
  }
};
</script>

<style scoped>

</style>

