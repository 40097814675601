import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

const debug = process.env.NODE_ENV !== 'production';

export default {
  // Common to both controllers
  async getProposalFill(proposalUniqueLink) {
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/proposal/print/${proposalUniqueLink}`, headers);
    if (debug) console.log('getProposalFill', res);
    return res;
  },
  async getProposalComments(proposalUniqueLink){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/proposal/print/${proposalUniqueLink}/comments`, headers)
    if (debug) console.log('getProposalFill', res);
    return res;
  },
};
