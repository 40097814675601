<template>
  <div>
    <label :for='elementId' class='question-label'>{{question}}</label>
    <input type='text' class='form-control' :id='elementId' :ref='elementId' :value='value' />
  </div>
</template>

<script>
export default {
  props: {
    id: [Number, String],
    question: String,
    type: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    value() {
      return this.$store.getters['editor/answer'](this.id);
    },
  }
};
</script>

<style scoped>
.question-section >>> .question-label {
  font-size: 1rem !important;
}
</style>