<template>
  <div class='mt-5 mb-5 pg-break-print'>
    <h2 :id='elementId'>{{text}}</h2>
  </div>
</template>

<script>
export default {
  name: "VSectionTitle",
  data() {
    return {
      displayComponents: []
    }
  },
  props: {
    type: String,
    id:[Number, String],
    text: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    }
  },
}
</script>

<style scoped>

</style>
