<template>
  <div v-if="state === 'Draft'">
    <a class="cool-alt-link" @click="onRenewal">Renewal</a>
    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="importAnswers">Copy Answers</a>
    <span class="pr-2 pl-2"> | </span>
  </div>
  <div v-else-if="state === 'Active'">
    <a class="cool-alt-link" @click="onRenewal">Renewal</a>
    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="importAnswers">Copy Answers</a>
    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" :href="proposalLink" target="_blank">View Answers</a>
  </div>
  <div v-else-if="state === 'Completed'">
    <a v-if="pdfGenerating === false && hasDownloads === true" class="cool-alt-link" @click="onDownload">Download</a>
    <span v-else-if="pdfGenerating === true" title="Please Refresh the page in a minute">Preparing Download. May take 1 minute</span>
    <span v-else-if="hasDownloads === false" title="The client needs to complete the form">No Completed Forms Found</span>

    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="onRenewal">Renewal</a>
    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link"  :href="proposalLink" target="_blank">View Answers</a>
  </div>
  <div v-else-if="state === 'Frozen'">
    <a v-if="pdfGenerating === false && hasDownloads === true" class="cool-alt-link" @click="onDownload">Download</a>
    <span v-else-if="pdfGenerating === true" title="Please Refresh the page in a minute">Preparing Download. May take 1 minute</span>
    <span v-else-if="hasDownloads === false" title="The client needs to complete the form">No Completed Forms Found</span>

    <span class="pr-2 pl-2"> | </span>
    <a class="cool-alt-link" @click="onRenewal">Renewal</a>
  </div>
</template>

<script> 

export default {
  props: {
    id: Number,
    state: String,
    uniqueLink: String,
    fullLink: String,
    clientLink: String,
    pdfGenerating: Boolean,
    hasDownloads: Boolean,
  },
  data() {
    return {
      demoFeatureEnabled: false,
    };
  },
  created() {
    this.demoFeatureEnabled = this.$store.getters['features/feature']('demo');
  },
  computed: {
    renameFormLink() {
      return `/broker/proposal/${this.id}/editMeta`;
    },
    editFormLink() {
      return `/broker/proposal/${this.id}/edit`;
    },
    proposalLink(){
      return this.$utils.str.isEmpty(this.clientLink) ? this.fullLink : this.clientLink;
    }
  },
  methods: {
    onRenewal(){
      this.$emit('renewal', this.id)
    },
    importAnswers(){
      this.$emit('importAnswers', this.id)
    },
    onDownload() {
      this.$emit('download', this.id);
    },
  },
};
</script>

<style scoped>
</style>
