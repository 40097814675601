import { isEmpty } from '@shared/services/utils/stringUtils.js';
const coolours = [
  '#41337a',
  '#6ea4bf',
  '#fab2ea',
  '#f07167',
  '#3F4B3B',
  '#2b2d42',
  '#5e548e',
  '#ef233c',
  '#e6beae',
  '#e7d8c9',
  '#577399',
  '#1a936f',
];

export default function generateCoolour(source) {
  let no = 0;
  if (typeof source === 'string' || source instanceof String){
    if(!isEmpty(no)){
      no = this.uniqueLink.charCodeAt(0);
    }
  }else if(!isNaN(source)){
    no = source;
  }
  
  return coolours[no % coolours.length];
}