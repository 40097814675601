<template>
  <div class="text-center" style="margin-top: 40%">
    <i class="fa fa-ban banmark cool-danger-alt"></i>
    <h2 class="landing-title pt-5">Restricted</h2>
    <h3 class="landing-subtitle pt-5">Your email address does not have permission to view this page.</h3>
    <h3 class="landing-subtitle">Please contact your broker if you believe you should have this permission.</h3>
  </div>
</template>

<script>
  export default {

  };
</script>


<style scoped>
.banmark {
  font-size: 7rem;
  line-height: 1;
}
</style>