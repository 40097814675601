<template>
  <transition
    appear
    name="custom-classes-transition"
    enter-active-class="animate__animated animate__slideInLeft"
    leave-active-class="animated bounceOutRight"
  >
    <li class="comment">
      <!-- Comment Avatar -->
      <div class="comment-avatar">
        <svg width="80" height="80" xmlns="http://www.w3.org/2000/svg" focusable="false" role="img" aria-label="Placeholder">
          <title>Placeholder - {{ author }}</title>
          <rect width="100%" height="100%" :fill="avatarColour" />
        </svg>
      </div>

      <!-- Comment Box -->
      <div class="comment-box">
        <div class="comment-text">{{ message }}</div>
        <div class="comment-footer d-flex justify-content-between">
          <span class="comment-author">{{ author }}</span>
          <span class="comment-date">{{ postedAt }}</span>
          <i class="fa fa-trash cool-danger delete-icon" @click="deleteComment" />
        </div>
      </div>
    </li>
  </transition>
</template>

<script>
import moment from 'moment';

export default {
  name: 'VComment',
  props: {
    id: [Number, String],
    author: String,
    createdAt: Date,
    message: String
  },
  methods: {
    deleteComment() {
      this.$emit('commentToDelete', this.id);
    }
  },
  computed: {
    postedAt() {
      return moment(this.createdAt).format('MMMM Do YYYY HH:mm');
    },
    avatarColour() {
      return this.author === 'Broker' ? '#41337a' : '#6ea4bf';
    }
  }
};
</script>

<style scoped>
.comment {
  margin-bottom: 10px;
  position: relative;
}

.comment-box:before {
  border-color: transparent rgba(0, 0, 0, 0.05);
  top: 22px;
}

.comment-text {
  color: #555f77;
  font-size: 15px;
  margin-bottom: 10px;
}

.comment-footer {
  color: #acb4c2;
  font-size: 13px;
}

.comment-box:before,
.comment-box:after {
  border-width: 10px 10px 10px 0;
  border-style: solid;
  border-color: transparent #fcfcfc;
  content: '';
  left: -10px;
  position: absolute;
  top: 20px;
}

.comment-box {
  background-color: #fcfcfc;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  margin-left: 100px;
  min-height: 60px;
  position: relative;
  padding: 15px;
}

.delete-icon:hover{
  cursor: pointer;
}
</style>
