import { getAuthInstance } from '@shared/services/auth/auth.js';


export async function getAuthHeaders() {
  if (!getAuthInstance().isAuthenticated) {
    throw "We do not have an auth token to use. Please check that authguard is triggered before you enter the page"
  }


  const accessToken = await getAuthInstance().getTokenSilently();
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  };
}
