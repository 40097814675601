<template>
  <div class='container'>
    <div class='lay-options-both'>
      <VEditButton class='eb-fade' @click='onBack'>Back</VEditButton>
    </div>
    <hr />
    <VChooseTemplate
      header="Choose a template"
      :templates="templates"
      @onSelectedTemplate="onSelectedTemplate"
    ></VChooseTemplate>
  </div>
</template>

<script>
import VEditButton from '@shared/components/common/VEditButton.vue';
import VChooseTemplate from '@broker/clientProposals/ui/VChooseTemplate.vue';

export default {
  components: {
    VEditButton,
    VChooseTemplate
  },
  props: ['clientId'],
  data() {
    return {
      templates: []
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData() {
      this.$api.template.getAll()
        .then(res => {
          this.templates = res.data;
        });
    },
    onBack() {
      this.$router.push({ path: `/broker/client/${this.clientId}/proposals` });
    },
    onSelectedTemplate(templateId){
      if(this.$debug) console.log('onSelectedTemplate', templateId)
      this.$api.company.postAddFormToClient(this.clientId, templateId)
        .then(() => {
          this.$router.push({ path: `/broker/client/${this.clientId}/proposals` });
        });

    }
  }
};
</script>

<style>
</style>
