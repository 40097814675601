import axios from '@shared/services/api/axiosConfig.js';
const debug = process.env.NODE_ENV !== 'production';

export default {
  // no auth
  async featureDemo(){
    const res = await axios.get('/api/utils/feature/demo')
    return res;
  },

  async featureBrokerageSettings(){
    const res = await axios.get('/api/utils/feature/brokeragesettings')
    if (debug) console.log(res);
    return res;
  },

  async featureIntroducerBroker(){
    const res = await axios.get('/api/utils/feature/introducerBroker')
    if (debug) console.log(res);
    return res;
  },
  async featurePrefillMaps(){
    const res = await axios.get('/api/utils/feature/PrefillMaps')
    if (debug) console.log(res);
    return res;
  }
}
