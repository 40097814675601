import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

const debug = process.env.NODE_ENV !== 'production';

export default {
  async getProposalComments(proposalUniqueLink){
    const headers = await getAuthHeaders();
    const res = await axios.get(`/api/proposal/${proposalUniqueLink}/comments`, headers)
    if (debug) console.log('getProposalFill', res);
    return res;
  },
  async postComment(proposalUniqueLink, payload){
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/proposal/${proposalUniqueLink}/comment`, payload, headers)
    return res;
  },
  async deleteComment(proposalUniqueLink, commentId){
    const headers = await getAuthHeaders();
    const res = await axios.delete(`/api/proposal/${proposalUniqueLink}/comment/${commentId}`, headers);
    return res;
  },
}
