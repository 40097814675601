<template>
  <div>
    <label :for="elementId" class="question-label">{{question}}</label>
    <div class="upload-area text-center">
        <i title="Upload Area" class='fas fa-upload fa-lg'></i>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    id: [Number, String],
    question: String,
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
  },
};
</script> 

<style scoped>
  .upload-area{
    margin: 0% 5%;
    width: 90%;
    height: 100px;
    border: 2px dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F1F1F1;
  }
</style>