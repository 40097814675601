import isVueStandalone from '@shared/services/api/environmentHelper.js'

export function apiPathStart(){
  return isVueStandalone() ? 'https://localhost:44373' : '';
}

export function rootUrl(){
  var root = window.location.protocol + "//" + window.location.host + "/"
  return root
}

export function downloadToComputer(data, mimeType, filename ){
  let blob = new Blob([data], { type: mimeType });
  let link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
  URL.revokeObjectURL(link.href);
}