const debug = process.env.NODE_ENV !== 'production';

function initialState(){
  return {
    formUid: '',
    proposalName: '',
    blurb: '',
    clientName: '',
    landingText: '',
    landingImage: null,
    proposalState: '',
    exitText: '',
    displayTitle: '',
    hasLandingPage: true,
    companyId: null, // wiped on survey
    restricted: false,
    sidebarColour: '',
    restrictedEmails: [],
    surveyHelp: null,
    loadingState: {
      metaRespStatus: 404, // 200, 403 (forbidden), 404 (not found)
      error: null
    }
  }
}

const state = initialState();
const getters = {

};

const mutations ={
  resetState(state){
    if (debug) console.log('resetState');
    Object.assign(state, initialState())
  },
  setMeta(state, res) {
    if (debug) console.log('setMeta triggered with', res.data);

    state.loadingState.metaRespStatus = 200;
    state.loadingState.error = null;

    state.proposalState = res.data.state;
    state.formUid = res.data.uniqueLink;
    state.proposalName = res.data.proposalName;
    state.blurb = res.data.blurb;
    state.clientName = res.data.clientName;
    state.landingText = res.data.landingText;
    state.landingImage = res.data.landingImage;
    state.exitText = res.data.exitText;
    state.displayTitle = res.data.displayTitle;
    state.hasLandingPage = res.data.hasLandingPage;
    state.companyId = res.data.companyId;
    state.restricted = res.data.restricted;
    state.sidebarColour = res.data.sidebarColour;
    state.surveyHelp = res.data.surveyHelp;

    // Only the elevated user api controller sets this value. So may not be sent when recieve from server
    if(res.data.restrictedEmails){
      state.restrictedEmails = res.data.restrictedEmails;
    }
  },
  setFailedLoad(state, err){
    if (debug) console.log('setFailedLoad triggered with', err);

    if(err.response && err.response.status){
      state.loadingState.metaRespStatus = err.response.status;
    } else {    
      // no response status code
      state.loadingState.metaRespStatus = 404;
    }
    state.loadingState.error = err;
  },
  setIsRedirect(state, isRedirect){
    if (debug) console.log('setIsRedirect triggered with', isRedirect);
    state.loadingState.isRedirect = isRedirect;
  },

};


const actions = {
  async FETCH_META({commit}, payload) {
    if (debug) console.log('FETCH_META', payload);

    let metaApi = this._vm.$api.getProposalApi(payload.isRestrictedPage);
    commit('resetState');
    try {
      let res = await metaApi.getProposalMeta(payload.proposalUniqueLink, payload.disableGlobalErrHandler);  
      commit('setMeta', res);
    } catch (error) {
      commit('setFailedLoad', error);
      throw error;
    }
    
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};