import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
class eQSignature extends eQSimpleQuestion {
  static get toolbox() {
    return {
      title: 'Signature',
      icon: toolsInfo.find((tool) => tool.type === 'qsign').icon,
    };
  }
}

export default eQSignature;
