import '@broker/editorV3/tools/index.css';

import createDiv from '@broker/editorV3/tools/utils/divarea.js';
import createIcon from '@broker/editorV3/tools/utils/icon.js';
import { createConditionalRenderTune } from '@broker/editorV3/tools/utils/conditionalRenderTune.js';
export class eQSimpleQuestion {
  constructor({ block, api, data }) {
    this.data = data.text ? data.text : '';
    this.api = api;
    this.block = block;
    this.containerDiv = document.createElement('div');
    this.containerDiv.classList.add('icon-and-block-container-div');
  }

  static get sanitize() {
    return {
      text: false,
      caption: {}, // only tags from Inline Toolbar
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  renderSettings(){
    return [createConditionalRenderTune(this.api)]
  }

  getBlockName() {
    return this.block.name;
  }

  render() {
    this._icon = createIcon(this.getBlockName());
    this._element = createDiv({
      text: this.data,
      api: this.api,
      placeholder: 'Insert text',
      classNames: 'editor-question-block',
    });

    this._element.addEventListener('keydown', (event) => {
      const [ENTER] = [13];
      if (event.keyCode === ENTER && event.shiftKey) {
        event.preventDefault();
      }
    });

    this.containerDiv.appendChild(this._icon);
    this.containerDiv.appendChild(this._element);

    return this.containerDiv;
  }

  save(blockContent) {
    if (blockContent === null) {
      return '';
    }

    const modifiedString = blockContent.innerText.replace(/\n.*/g, '');

    let result = {
      text: modifiedString.trim(),
    };

    return result;
  }

  validate(savedData) {
    if (!savedData.text.trim()) {
      return false;
    }
    return true;
  }
}
