import { render, staticRenderFns } from "./VQUpload.vue?vue&type=template&id=1901645d&scoped=true"
import script from "./VQUpload.vue?vue&type=script&lang=js"
export * from "./VQUpload.vue?vue&type=script&lang=js"
import style0 from "./VQUpload.vue?vue&type=style&index=0&id=1901645d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1901645d",
  null
  
)

export default component.exports