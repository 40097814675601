<template>
  <fieldset :id="elementId">
    <legend
      tabindex="-1"
      class="question-label col-form-label pt-0 bv-no-focus-ring"
    >
      {{ question }}
    </legend>
    <div role="radiogroup" tabindex="-1" v-if="selected === undefined || selected === null">
      <div
        v-for="(option, index) in options"
        :key="index"
        tabindex="-1"
        class="bv-no-focus-ring custom-control custom-radio"
      >
        <input
          :id="optionId(index)"
          type="radio"
          :value="option"
          class="custom-control-input"
          :name="elementId"
          autocomplete="off"
        />
        <label :for="optionId(index)" class="custom-control-label">
          <span>{{ option }}</span>
        </label>
      </div>
    </div>
    <div v-else>
      <ul class='fa-ul list'>
        <li class='list-item'>
          <span class='fa-li'>
            <i class='fas fa-check-circle'></i>
          </span>
          {{ selected }}
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script>

export default {
  props: {
    id: Number,
    question: String,
    type: String,
  },
  data() {
    return {
      options: ['Yes', 'No']
    };
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    selected() {
      return this.$store.getters['print/answer'](this.id);
    },
  },
  methods: {
    optionId(idx) {
      return this.elementId + '_' + idx;
    },
  },
};
</script>

<style scoped>

ul {
  list-style: none;
  padding: 0;
  margin-left: 24px;
  margin-inline-start: 27px;
}

</style>