<template>
  <div class="container">
    <div class="row">
      <div class="col-sm">
        <div class="lay-card mt-5 mb-5">
          <form class="mt-3" @submit.prevent="handleSubmit" novalidate>
            <div class="lay-card-top">
              <h5 class="card-title">Please enter the Introducers' details</h5>

              <div class="form-group">
                <label for="txtName">Name</label>
                <input
                  type="text"
                  class="form-control"
                  :class="stateClass(nameValid)"
                  id="txtName"
                  placeholder="Name"
                  v-model="introducer.name"
                  :aria-invalid="!nameValid"
                  aria-required="true"
                />
                <div class="invalid-feedback">Please provide a name</div>
              </div>

              <div class="form-group">
                <label for="txtEmail">Email</label>
                <input
                  type="text"
                  class="form-control"
                  :class="stateClass(emailValid)"
                  id="txtEmail"
                  placeholder="Email"
                  v-model="introducer.email"
                  aria-required="true"
                />
                <div class="invalid-feedback">Please provide a valid email address</div>
              </div>

              <div class="form-group">
                <label for="txtRetailBrokerage">Please provide a company name for the introducer</label>
                <input
                  type="text"
                  class="form-control"
                  :class="stateClass(introducerCompanyValid)"
                  id="txtRetailBrokerage"
                  placeholder="Enter the name of the retail broker's brokerage"
                  v-model="introducer.introducerCompany"
                  aria-required="true"
                />
                <div class="invalid-feedback">Please provide a company name  country</div>
              </div>
              <div class="form-group">
                <label for="txtCountry">Country</label>
                <input
                  type="text"
                  class="form-control"
                  id="txtCountry"
                  placeholder="Country"
                  v-model="introducer.country"
                  aria-required="false"
                />
              </div>
            </div>

            <div class="lay-card-bottom">
              <VEditButton class="eb-subtle" type="Input" @click="handleCancel">Cancel</VEditButton>
              <VEditButton class="eb-success" type="Submit">Save</VEditButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// see https://www.digitalocean.com/community/tutorials/vuejs-vue-form-handling
export default {
  data() {
    return {
      introducer: {
        name: '',
        email: '',
        introducerCompany: '',
        country: ''
      },
      isSubmitted: false
    };
  },
  computed: {
    nameValid() {
      return this.introducer.name.length > 2;
    },
    emailValid() {
      return this.introducer.email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    introducerCompanyValid() {
      return this.introducer.introducerCompany.length > 2;
    }
  },
  methods: {
    stateClass(computedItem) {
      if (!this.isSubmitted) return '';

      return computedItem ? 'is-valid' : 'is-invalid';
    },
    handleSubmit() {
      if (this.$debug) console.log('submit');
      this.isSubmitted = true;

      if (this.nameValid && this.emailValid && this.introducerCompanyValid) {
        this.$api.wholesale.postNewIntroducer(this.introducer).then(() => {
          this.$router.push(`/wholesale`);
        });
      }
    },
    handleCancel() {
      this.$router.push(`/wholesale`);
    }
  }
};
</script>

<style scoped></style>
