<template>
  <div class='container'>
    <div class='lay-options-both'>
      <VEditButton class='eb-fade' @click='onBack'>Back</VEditButton>
    </div>
    <hr />
    <ImportProposalUploader @upload="onFormUploaded" :templateId="templateId" :clientId="clientId">
    </ImportProposalUploader>
  </div>
</template>

<script>
import VEditButton from '@shared/components/common/VEditButton.vue';
import ImportProposalUploader from '@broker/clientProposals/ui/ImportProposalUploader.vue';

export default {
  components: {
    VEditButton,
    ImportProposalUploader
  },
  props: ['clientId', 'templateId'],
  methods: {
    onBack() {
      this.$router.push({ path: `/broker/client/${this.clientId}/importProposal` });
    },
    onFormUploaded() {
      this.$router.push({ path: `/broker/client/${this.clientId}/proposals` });
    },
  }
};
</script>

<style>
</style>
