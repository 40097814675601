<template>
  <div>
    <div class="row text-center pt-3">
      <div class="col-sm">
        <h3>{{ header }}</h3>
      </div>
    </div>
    <div class="list-group pt-3">
      <VChooseTemplateListItem
        v-for="template in templates"
        :key="template.id"
        v-bind="template"
        @click="onTemplateItemClick(template.id)"
      ></VChooseTemplateListItem>
    </div>
  </div>
</template>

<script>
import VChooseTemplateListItem from '@broker/clientProposals/ui/VChooseTemplateListItem.vue';

export default {
  components: {
    VChooseTemplateListItem
  },
  props: {
    header: String,
    templates: Array
  },
  methods: {
    onTemplateItemClick(templateId) {
      this.$emit('onSelectedTemplate', templateId);
    }
  }
};
</script>

<style scoped></style>
