<template>
  <div class='container'>
    <div class='lay-options-both'>
      <VEditButton class='eb-fade' @click='onBack'>Back</VEditButton>
      <VEditButton class='eb-prim' @click='onSave'>Save</VEditButton>
    </div>
    <hr />
    <div>
      <h2>This is a WIP page. Please do not use yet</h2>

      <div class="row mb-3">
        <label for="inputTemplateName" class="col-sm-6 col-form-label">Template Name</label>
        <div class="col-sm-6">
          <input type="text" class="form-control ml-3" v-model="templateName" 
              :class="stateClass(nameValid)"
                  :aria-invalid="!nameValid"
                  aria-required="true"
          
              id="inputTemplateName"/>
        </div>
      </div>

      <div class="row mb-3">
        <label for="inputSkip" class="col-sm-6 col-form-label">Skip Pages. Ex: 1,2,10,12,13</label>
        <div class="col-sm-6">
          <input type="text" class="form-control ml-3" 
                  v-model="skipPages" id="inputSkip"/>
        </div>
      </div>

      <div class="row mb-3">
        <label for="inputFontHeight" class="col-sm-6 col-form-label">Font Height Override (Leave blank for system to calculate)</label>
        <div class="col-sm-6">
          <input type="text" class="form-control ml-3" 
                  v-model="fontHeight" id="inputFontHeight"/>
        </div>
      </div>

      <div class="row mb-3">
        <label for="inputTemplateProcessor" class="col-sm-6 col-form-label">Template Processor (Optional)</label>
        <div class="col-sm-6">
          <input type="text" class="form-control ml-3" v-model="processor" 
                  aria-required="false"
              id="inputTemplateProcessor"/>
        </div>
      </div>

    </div>

    <ImportAiUploader @upload="onFormUploaded" :skipPages="skipPages">
    </ImportAiUploader>


    <div class="row">
      <div class="col">
        <textarea style="width: 100%; height: 70vh" v-model="internalTemplateBaml"
                  class="form-control"
                  :class="stateClass(templateValid)"
                  :aria-invalid="!templateValid"
                  aria-required="true"
        ></textarea>
      </div>
    </div>
    <VEditButton class='eb-fade' @click='onFixIds'>Fix Ids</VEditButton>
    <VEditButton class='eb-fade' @click='onGetSavedBamlWithIds'>Get Last Saved Baml With Ids</VEditButton>
  </div>
</template>

<script>
import VEditButton from '@shared/components/common/VEditButton.vue';
import ImportAiUploader from '@broker/aiTemplateEditor/ui/ImportAiUploader.vue';

export default {
  components: {
    VEditButton,
    ImportAiUploader
  },
  props: ['templateId', 'isNew'],
  created() {
    this.fetchData();
  },
  watch: {
    $route: 'fetchData',
  },
  mounted() {
    window.addEventListener('beforeunload', this.onBeforeLeaving);
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.onBeforeLeaving);
  },
  beforeRouteLeave(to, from, next) {
    if(!this.saved){
      const answer = window.confirm('You have unsaved changes, are you sure you want to leave?');
      if (!answer) {
        next(false);
      } else {
        next();
      }
    }
    else{
      next();
    }
  },
  data() {
    return {
      skipPages: '',
      internalTemplateBaml: '',
      templateName: '',
      saved: false,
      isSubmitted: false,
      fontHeight: null,
      processor: '',
    };
  },
  computed: {
    nameValid() {
      return this.templateName 
        && this.templateName.length > 2
        && !this.$utils.str.isEmpty(this.templateName)
        && !this.templateName.match(/[,\\/#!$?%^&*;:{}\][=`~()@]/);
    },
    templateValid() {
      const linesCount = (this.internalTemplateBaml.match(/\n/g) || '').length + 1
      return this.internalTemplateBaml 
      && this.internalTemplateBaml.length > 50 
      && !this.$utils.str.isEmpty(this.internalTemplateBaml) 
      && linesCount > 1;
    }
  },

  methods: {
    onBack() {
      this.$router.push({ path: `/broker/formTemplates` });
    },
    onFormUploaded(data) {
      this.internalTemplateBaml = data;
    },
    async fetchData() {
      if (!this.isNew) {
        let res = await this.$api.template.getAiTemplate(this.templateId);

        this.internalTemplateBaml = res.data.templateBaml;
        this.templateName = res.data.templateName;
        this.skipPages = res.data.uploadSkipPages;
        this.fontHeight = res.data.fontHeight;
        this.processor = res.data.processor;
      }
    },
    async onSave(){
      this.isSubmitted = true;

      if(!this.nameValid || !this.templateValid){
        return;
      }

      const withoutEmptyLines = this.internalTemplateBaml.replace(/^\s*\n/gm, "");

      let data = {
        templateBaml: withoutEmptyLines,
        templateName: this.templateName,
        uploadSkipPages: this.skipPages,
        fontHeight: this.fontHeight,
        processor: this.processor
      };

      let res = await this.$api.template.postAiEditTemplate(this.templateId ?? 0, data);
      this.saved = true;

      if (this.isNew) {
        this.$router.push(`/broker/aiTemplate/${res.data}/edit`);
      }
      this.$toast('Saved');
    },
    async onFixIds(){
      const withoutEmptyLines = this.internalTemplateBaml.replace(/^\s*\n/gm, "");

      let res = await this.$api.template.postFixIds(withoutEmptyLines);
      this.internalTemplateBaml = res.data
    },
    async onGetSavedBamlWithIds(){
      await this.$api.template.getAiBamlWithId(this.templateId);
    },
    stateClass(computedItem) {
      if (!this.isSubmitted) return '';

      return computedItem ? 'is-valid' : 'is-invalid';
    },
  }
};
</script>

<style>
</style>
