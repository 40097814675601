import { isOptionType, isSimpleType, isTextType } from '@broker/editorV3/utils/questionTypes.js';
import { mapToColumnHeaders } from '@broker/store/tableEditorFns.js';

export function convertToDbRepresentation(blocks, ifQs) {
  const form = {
    sections: [],
  };

  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];
    let currentBlock = {};
    if (block.type === 'qsubsection') {
      if (subsectionIsEmpty(block)) {
        continue;
      }
      let blocksArr = [];
      try {
        blocksArr = getSubsectionBlocks(block);
      } catch (error) {
        continue;
      }
      currentBlock = formatSubsection(block, blocksArr);
    } else if (block.type === 'qtable') {
      currentBlock = formatTable(block);
    } else {
      currentBlock = enrichBlock(block);
    }

    setBlockIfQAndOnChildren(currentBlock, ifQs);
    form.sections.push(currentBlock)
  }
  return form;
}


function subsectionIsEmpty(block) {
  if (block.data && block.data.itemContent) {
    const content = block.data.itemContent;
    const keys = Object.keys(content);
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (content[key].blocks && content[key].blocks.length > 0) {
        return false;
      }
    }
  }
  return true;
}

function setBlockIfQAndOnChildren(object, ifQs) {
  const matchingIfQ = ifQs.find((ifQ) => ifQ.targetId === object.id);

  if(object.type !== 'subsection'){
    if (matchingIfQ) {
      object.ifQ = {
        id: matchingIfQ.triggerId,
        option: matchingIfQ.triggerOption
      }
    }
  }else{
    // Find if children have qifs and add to object
    object.sections.map((child) => {
      const matchingIfQ = ifQs.find((ifQ) => ifQ.targetId === child.id);
      if (matchingIfQ) {
        child.ifQ = {
          id: matchingIfQ.triggerId,
          option: matchingIfQ.triggerOption
        }
      }
      return child;
    });
  }
}

function formatSubsection(block, blocksArr) {
  blocksArr = [...block.data.itemContent[1].blocks];
  let object = {
    id: block.id,
    text: block.data.text,
    type: 'subsection',
    sections: blocksArr.map((block) => {
      if (block.type === 'qtable') {
        return formatTable(block);
      } else {
        return enrichBlock(block);
      }
    }),
  };
  return object;
}

function formatTable(block) {
  let object = {
    id: block.id,
    type: block.type,
    question: block.data.text,
    tableEditData: block.data.tableData.content,
    isAddRowsEnabled: block.data.isAddRowsEnabled,
    columns: mapToColumnHeaders(block.data.tableData.content),
  };

  const columns = object.columns;
  const entries = [...block.data.tableData.content];
  const tableEntries = makeTableEntries(entries);
  object.tableData = makeTableData(columns, tableEntries);

  return object;
}

function makeTableData(cols, arrOfArrs) {
  let keys = cols.map((column) => {
    return column.field;
  });

  const arrayOfObjects = arrOfArrs.map((innerArray) => {
    const obj = {};
    innerArray.forEach((entry, index) => {
      obj[keys[index]] = entry;
    });
    return obj;
  });

  return arrayOfObjects;
}

function makeTableEntries(arr) {
  return arr.splice(1);
}


function getSubsectionBlocks(block) {
  let arr = [];

  if (block.data && block.data.itemContent) {
    const keys = Object.keys(block.data.itemContent);
    if (keys.length > 1) {
      throw new Error(`Incorrect number of keys ${keys.length}`);
    } else if (keys.length === 0) {
      throw new Error(`Subsection is empty`);
    } else {
      const key = keys[0];
      const itemContent = block.data.itemContent[key];
      if (itemContent.blocks && itemContent.blocks.length > 0) {
        arr = [...itemContent.blocks];
      } else {
        throw new Error(`Subsection blocks is empty`);
      }
    }
  }
  return arr;
}


function enrichBlock(block) {
  let object = {
    id: block.id,
    type: block.type,
  };
  if (isOptionType(block.type)) {
    object.question = block.data.text;
    object.options = block.data.items;
  } else if (isSimpleType(block.type)) {
    object.question = block.data.text;
  } else if (isTextType) {
    object.text = block.data.text;
  } else {
    throw new Error('Block type was not recognised', block.type);
  }

  return object;
}