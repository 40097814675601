<template>
    <div class="dropdown-container" @mouseenter="dropdown = true" @mouseleave="dropdown = false">
        <button class="edit-button eb-success">
          Number Form
        </button>
        <li v-if="dropdown" class="option">
          <ul v-for="(option, index) in options" :key="index" class="option-button button-container" @click="option.onClick">
            {{ option.label }}
          </ul>
        </li>
    </div>
</template>

<script>
export default {
    data(){
        return {
            dropdown: false,
            options: [
                {
                    label: "Continuous",
                    onClick: this.handleNormalAutonumber
                },
                {
                    label: "By Section",
                    onClick: this.handleSectionalNumbering
                },
                {
                    label: "Remove",
                    onClick: this.handleRemoveNumbering
                }
            ]
        }
    },
    props: ['editor'],
    methods: {
        handleNormalAutonumber(){
            this.$emit("autonumber");
        },
        handleSectionalNumbering(){
            this.$emit("sectionalnumbering");
        },
        handleRemoveNumbering(){
            this.$emit("removenumbering")
        },
        handleMouseEnter(){
            this.dropdown = true;
        }
    },

}
</script>

<style scoped>
.dropdown-container{
    display: inline-block;
}

.dropdown-container .option{
    position: absolute;
}
li{
    list-style: none;
}
li > ul{
    padding: 0;
    margin-bottom: 0;
}

.option{
    z-index: 2;
    background: linear-gradient(to bottom, #52b86a 0%, #28a745 100%);
    border-top: solid 0.2rem white;
    margin-left: 0.5rem;
    border-radius: 5px;
    width: 10rem;
}

.button-container:first-child{
    border-radius: 5px 5px 0 0;

}

.button-container:last-child{
    border-radius: 0 0 5px 5px;

}

.button-container:hover{
    background: #37964D;
}

.button-container:active{
    background:#1c8735
}

.option-button{
    background: #40ac59;
    color: white;
    font-size: 1rem;
    padding: 0.375rem 1.75rem;
    
    cursor: pointer;
    border: none;

    width: 100%;
}


</style>