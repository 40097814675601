import axios from 'axios';
import isVueStandalone from '@shared/services/api/environmentHelper.js';

import { createToastInterface } from 'vue-toastification';

const toast = createToastInterface({
  position: 'bottom-center'
});

function requestErrorIntercept(error) {
  if (error.request) {
    // The request was made but no response was received
    //safe to log, nothing sensitive, helps with debugging network issues in prod 
    console.log('axios error req', error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    //safe to log, nothing sensitive, helps with debugging network issues in prod 
    console.log('axios error req', error.message);
  }

  toast.error(error.toString());
  return Promise.reject(error);
}

function responseErrorIntercept(error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // eslint-disable-next-line no-prototype-builtins
  if (error.config.hasOwnProperty('disableGlobalErrHandler') && error.config.disableGlobalErrHandler === true) {
    return Promise.reject(error);
  }

  //safe to log, nothing sensitive, helps with debugging network issues in prod 
  console.log('axios error all', error);
  let toastErrMessage = error.toString();
  if (error.response) {
    // The request was made and the server responded with a status code
    //safe to log, nothing sensitive, helps with debugging network issues in prod 
    console.log('axios error data', error.response.data);
    if (error.response.data) {
      if (typeof error.response.data === 'string' || error.response.data instanceof String)
        toastErrMessage = `${toastErrMessage} : ${error.response.data.split('\n')[0]}`;

      if (error.response.data.title) 
        toastErrMessage = `${toastErrMessage} : ${error.response.data.title}`;
    }
    //safe to log, nothing sensitive, helps with debugging network issues in prod 
    console.log('axios error status', error.response.status);
  } else {
    //safe to log, nothing sensitive, helps with debugging network issues in prod 
    console.log('axios error msg', error.message);
  }

  toast.error(toastErrMessage);
  return Promise.reject(error);
}

function createAxios() {

  //safe to log, nothing sensitive, helps with debugging network issues in prod 
  console.log("process.env.VUE_APP_TYPE", process.env.VUE_APP_TYPE);
  //safe to log, nothing sensitive, helps with debugging network issues in prod 
  console.log('Axios isVueStandalone', isVueStandalone());
  
  let axiosInstance = axios;
  if (isVueStandalone()) {
    const baseURL = process.env.VUE_APP_TYPE === 'broker-app' ? 'https://localhost:44373/' :  'https://localhost:44362/';
    axiosInstance = axios.create({
      baseURL: baseURL
    });
  }

  axiosInstance.interceptors.request.use(req => req, requestErrorIntercept);
  axiosInstance.interceptors.response.use(response => response, responseErrorIntercept);

  return axiosInstance;
}

const createdAxios = createAxios();
export default createdAxios;