

export function createConditionalRenderTune(api){
  return {
    icon: `<svg width="40" height="20" viewBox="0 0 40 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="39" height="19" rx="9.5" fill="#FF1E1E" stroke="black"/>
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white" stroke="black"/>
    </svg>`,
    label: 'Conditionally render',
    closeOnActivate: true,
    onActivate: async () => {
      const index = parseInt(api.blocks.getCurrentBlockIndex());
      const block = api.blocks.getBlockByIndex(index);
      const blockData = await block.save();
      const validate = await block.validate(blockData.data)

      if(validate){
        const event = new CustomEvent('conditional-render', { bubbles: true, detail: { id: blockData.id } });
        document.dispatchEvent(event)
      }
    },
  }
}