import { render, staticRenderFns } from "./VNone.vue?vue&type=template&id=041fd3c2&scoped=true"
import script from "./VNone.vue?vue&type=script&lang=js"
export * from "./VNone.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "041fd3c2",
  null
  
)

export default component.exports