<template>
  <div class="container">
    <div class="lay-options-right">
      <VEditButton class="eb-sec" @click="onNewRetailBroker">New Introducer</VEditButton>
    </div>
    <hr />
    <div class="row text-center pt-3">
      <div class="col-sm">
        <h3>Introducers</h3>
      </div>
    </div>
     <div class="row text-center pt-3 pb-3">
      <div class="col-sm">
        <p>Introducers for the wholesale business. <br/> Link for an introducer to create a proposal is: <br/> <a :href="wholesaleLink">{{wholesaleLink}}</a></p>
      </div>
    </div>
    <div class="list-group pt-3 pb-3">
      <ag-grid-vue
        style="width: 100%; height: 75vh"
        class="ag-theme-alpine"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="retailBrokers"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      retailBrokers: null,
      columnDefs: null,
      wholesaleLink: null
    };
  },
  watch: {
    $route: 'init'
  },
  beforeMount() {
    this.gridOptions = {
    };

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };

    this.columnDefs = [
      { headerName: 'Name', field: 'name', flex: 2, minWidth: 180 },
      { headerName: 'Company', field: 'introducerCompany', flex: 1, minWidth: 150 },
      { headerName: 'Email', field: 'email', flex: 2, minWidth: 180 },
      //{ headerName: 'Country', field: 'country', flex: 1, minWidth: 150 },
      { headerName: 'Draft', field: 'draftProposals', width: 100 },
      { headerName: 'Active', field: 'activeProposals', width: 100 },
      { headerName: 'Completed', field: 'completedProposals', width: 100 },
      {
        headerName: 'Activated',
        field: 'active',
        width: 170,
        cellRenderer: function(params) {
          var text = params.data.active === true ? 'Enabled' : 'Inactive';
          let searchParams = new URLSearchParams();
          searchParams.append('name', params.data.name);
          searchParams.append('email', params.data.email);
          searchParams.append('introducerCompany', params.data.introducerCompany);
          searchParams.append('active', params.data.active);

          return `${text} - <a href='/wholesale/introducer/${params.data.id}?${searchParams.toString()}'>change</a>`;
        }
      }
    ];

    this.init();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  methods: {
    async init() {
      if (this.$debug) console.log('init');

      const introducersRes = await this.$api.wholesale.getAllIntroducers();
      this.retailBrokers = await introducersRes.data;

      const linkRes = await this.$api.wholesale.getWholesaleLink();
      this.wholesaleLink = await linkRes.data;
    },
    onNewRetailBroker() {
      this.$router.push({ path: '/wholesale/new' });
    }
  }
};
</script>

<style>
.header {
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
