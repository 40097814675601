import { eQSimpleQuestion } from '@broker/editorV3/tools/eQSimpleQuestion.js';
import toolsInfo from '@broker/editorV3/tools/utils/toolsInfo.js';
class eQUpload extends eQSimpleQuestion {
  static get toolbox() {
    return {
      title: 'Upload',
      icon: toolsInfo.find((tool) => tool.type === 'qupload').icon,
    };
  }
}

export default eQUpload;
