import axios from '@shared/services/api/axiosConfig.js';
import { getAuthHeaders } from '@shared/services/auth/authApiServices.js';

const debug = process.env.NODE_ENV !== 'production';

export default {
  async postRestrictProposal(proposalId, newState) {
    if (debug) console.log('postRestrictProposal', proposalId, newState);
    const headers = await getAuthHeaders();
    const res = await axios.post(`/api/proposal/${proposalId}/restrict/${newState}`, null, headers);
    return res;
  }
};