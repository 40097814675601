<template>
  <QShortReadonly v-if="showShortForm" v-bind="$props"></QShortReadonly>
  <QLongReadonly v-else v-bind="$props"></QLongReadonly>
</template>

<script>
import QLongReadonly from '@shared/components/questions/ui/print/QLongReadonly.vue';
import QShortReadonly from '@shared/components/questions/ui/print/QShortReadonly.vue';
export default {
  components: { QShortReadonly, QLongReadonly },
  props: {
    id: Number,
    question: String,
    type: String
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    showShortForm(){
      if (!this.value || !this.value.length){
        return true;
      }
      return this.value.length < 140
    },
    value: {
      get() {
        return this.$store.getters['print/answer'](this.id);
      },
    }
  }
};
</script>

<style scoped></style>
