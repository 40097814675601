import { containerStyle } from '@broker/editorV3/tools/utils/editorConfig.js';
import { isOptionType, isSimpleType } from '@broker/editorV3/utils/questionTypes.js';

export function convertToEditorjs(sections) {
  const form = {
    blocks: [],
  };
  const ifQObjs = []

  for (let i = 0; i < sections.length; i++) {
    let block = sections[i];

    let obj = {
      id: block.id,
      data: {},
    };

    if(block.ifQ){
      let conditional = getIfQRepresentation(block);
      ifQObjs.push(conditional);
    }

    if (block.type === 'section') {
      obj.type = 'section';
      obj.data.text = block.text;
      form['blocks'].push(obj);
    } else if (block.type === 'text') {
      obj.type = 'text';
      obj.data.text = block.text;
      form['blocks'].push(obj);
    } else if (isSimpleType(block.type)) {
      obj = formatSimpleTypeToEditorJs(block, obj);
      form['blocks'].push(obj);
    } else if (isOptionType(block.type)) {
      obj = formatOptionTypeToEditorJs(block, obj);
      form['blocks'].push(obj);
    } else if (block.type === 'qtable') {
      obj = formatTableToEditorJs(block, obj);
      form['blocks'].push(obj);
    } else if (block.type === 'subsection') {
      const {obj: subsectionObj, subSectionIfQObjs} = formatSubsectionToEditorJs(block);
      ifQObjs.push(...subSectionIfQObjs)
      form['blocks'].push(subsectionObj);
    }
  }
  return {form, ifQObjs};
}

function getIfQRepresentation(block){
  return { targetId: block.id, triggerOption: block.ifQ.option, 
    triggerId: block.ifQ.id };

}

export function formatSubsectionToEditorJs(block) {
  let obj = {
    id: block.id,
    data: {},
  };
  const translatedToEditorblocks = [];
  const subSectionIfQObjs = []

  for (let index = 0; index < block.sections.length; index++) {
    const element = block.sections[index];
    if(element.ifQ){
      let conditional = getIfQRepresentation(element);
      subSectionIfQObjs.push(conditional);
    }
    const translatedToEditorBlock = createBlockData(element)
    translatedToEditorblocks.push(translatedToEditorBlock)
  }

  obj.type = 'qsubsection';
  obj.data.text =  block.text ? block.text : ''
  obj.data.layout = {
    type: 'container',
    id: '',
    className: 'subsection-container',
    style: containerStyle,
    children: [
      {
        type: 'item',
        id: '',
        className: 'subsection',
        style: 'width:100%; display: inline-block; ',
        itemContentId: '1',
      },
    ],
  };
  obj.data.itemContent = {
    1: {
      blocks: translatedToEditorblocks
    },
  };
  return {obj, subSectionIfQObjs };
}

function createBlockData(block) {
  let object = {
    data: {},
    id: block.id,
  };

  if (block.type === 'qtable') {
    object = formatTableToEditorJs(block, object)
  } else if (isOptionType(block.type)) {
    object = formatOptionTypeToEditorJs(block, object)
  } else if (isSimpleType(block.type)){
    object = formatSimpleTypeToEditorJs(block, object)
  }
  else if (block.type === 'section' || block.type === 'text') {
    object.type = block.type
    object.data.text = block.text;
  }
  return object;
}

export function formatSimpleTypeToEditorJs(block, obj) {
  obj.type = block.type;
  obj.data.text = block.question;
  return obj;
}

export function formatOptionTypeToEditorJs(block, obj) {
  obj.type = block.type;
  obj.data.text = block.question;
  obj.data.items = block.options;
  return obj;
}

export function formatTableToEditorJs(block, obj) {
  obj.type = 'qtable';
  obj.data.text = block.question
  obj.data.isAddRowsEnabled = block.isAddRowsEnabled;
  obj.data.tableData = generateTableData(block);
  return obj;
}


function generateTableData(block) {
  var tableData;

  tableData = {
    withHeadings: false,
    content: getContent(block),
  };

  return tableData;
}

function getContent(block){
  let content = []
  if(block.tableEditData){
    content.push(block.tableEditData[0])
    content.push(...block.tableEditData.slice(1))
  }
  else if (block.tableData){
    content.push(formatTableColumnsForEditorJs(block.columns))
    content.push(...formatTableRowsForEditorJs(block.tableData))
  }
  else{
    throw new Error("invalid table data")
  }
  return content
}

function formatTableColumnsForEditorJs(columns) {
  const columnArr = columns.map((column) => {
    return column.headerName;
  });
  return columnArr;
}

function formatTableRowsForEditorJs(rows) {
  const rowArrs = rows.map((row) => {
    return Object.values(row);
  });
  return rowArrs;
}
