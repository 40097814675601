<template>
  <div>
    <p :id="elementId" class="subsection-label">{{ text }}</p>
    <div class="question-section">
      <div v-for="content in displayComponents" :key="content.id" class="form-group question-group-print">
        <ProposalItemHolder :proposalItem="content"></ProposalItemHolder>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'QuestionSubSection',
  props: {
    id: [Number, String],
    text: String,
    sections: Array,
    type: String
  },
  computed: {
    ...mapState('fill', ['answers','componentsMode' ]),
    elementId() {
      return this.type + this.id;
    },
    displayComponents() {
      return this.$store.getters['fill/filteredItems'](this.id);
    },
  }
};
</script>

<style scoped>
.question-section >>> .question-label {
  font-size: 1rem !important;
}

.question-section >>> .subtitle-label {
  font-size: 1rem !important;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.5;
}
</style>
