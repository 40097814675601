<template>
  <div :id="elementId">
    <label :for="elementId + '_in'" class="question-label">{{ question }}</label>
    <div>
      <VueSignaturePad height="300px" 
        style="border: 2px solid gray;"
        :ref="signatureRef"
        :options="{ onEnd }"
      />
      <div class="row pt-1">
        <div class="col">
          <button @click="clear" class="btn btn-info m-1">Clear</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'QSign',
  props: {
    id: [Number, String],
    question: String,
    type: String
  },
  mounted(){
    let data = this.$store.getters['fill/answer'](this.id);
    if (data) {
      this.$refs[this.signatureRef].fromDataURL(data)
    }
  },
  computed: {
    elementId() {
      return this.type + this.id;
    },
    signatureRef() {
      return 'Sig' + this.elementId;
    },
    validatedClass() {
      return this.$store.getters['fill/validationState'](this.id);
    }
  },
  methods: {
    commitFocus() {
      this.$store.commit('fill/setFocus', this.id);
    },
    onEnd() {
      if (this.$debug) console.log("On Sign End");
      this.save();
    },
    save() {
      let { data } = this.$refs[this.signatureRef].saveSignature();
      if (this.$debug) console.log("Signature data url", data);

      if (!data) data = "";

      let payload = { id: this.id, value: data };
      this.$store.dispatch('fill/SAVE_ANSWER', payload);
    },
    clear() {
      this.$refs[this.signatureRef].undoSignature();
      let payload = { id: this.id, value: "" };
      this.$store.dispatch('fill/SAVE_ANSWER', payload);
    }
  }
};
</script>
