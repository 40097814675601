<template>
  <div class='mt-5 mb-5 pg-break-print'>
    <div v-if="sections.length === 0" class="empty-message">
      Preview is empty, try adding something
    </div>
    <div v-for='content in sections' :key='content.id' class="form-group">
      <div>
        <EV3EditorViewItemHolder :proposalItem="content" :allowSections="true"></EV3EditorViewItemHolder>
      </div>
    </div>
  </div>
</template>

<script>
import EV3EditorViewItemHolder from '@broker/editorV3/ui/render/EV3EditorViewItemHolder.vue';

export default {
  components: {
    EV3EditorViewItemHolder
  },
  name: "EV3VFormDisplay",
  props: {
    sections: Array,
  },
}
</script>

<style scoped>
.empty-message{
  text-align: center;
  font-style: italic;
  margin-top: 2em;
}
</style>
