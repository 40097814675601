<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <div>
              <div class="row mb-3">
                <label for="inputSkip" class="col-sm-6 col-form-label">Skip Pages. Ex: 1,2,10,12,13</label>
                <div class="col-sm-6">
                  <input type="text" class="form-control ml-3" v-model="skipPages" id="inputSkip"/>
                </div>
              </div>
              <div class="row mb-3">
                <label for="inputTitleFont" class="col-sm-6 col-form-label">Title Font Height</label>
                <div class="col-sm-6">
                  <input type="number" class="form-control ml-3" v-model="titleFontHeight" id="inputTitleFont"/>
                </div>
              </div>
              <div class="row mb-3">
                <label for="inputSubTitleFont" class="col-sm-6 col-form-label">Subtitle Font Height</label>
                <div class="col-sm-6">
                  <input type="number" class="form-control ml-3" v-model="subtitleFontHeight" id="inputSubTitleFont"/>
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="ignoreGrids" id="chkIgnoreGrids"/>
                <label class="form-check-label" for="chkIgnoreGrids">Ignore Grids</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="isActuris" id="chkIsActuris"/>
                <label class="form-check-label" for="chkIsActuris">Acturis Import</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="isBasicReading" id="chkIsBasicReading"/>
                <label class="form-check-label" for="chkIsBasicReading">Basic Reading Rules</label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="qHierarchy" id="chkIsQuestionHierarchy"/>
                <label class="form-check-label" for="chkIsQuestionHierarchy">Include Parent Text in Question</label>
              </div>
              <br>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="isImportMerge" id="chkIsImportMerge"/>
                <label class="form-check-label" for="chkIsImportMerge">Import Into Current Template</label>
              </div>
            </div>
          </div>
          <EV3ImportUploader 
            @upload="assignData" :skipPages="skipPages" :ignoreGrids="ignoreGrids" :acturis="isActuris" :qHierarchy="qHierarchy"
            :titleFontHeight="titleFontHeight" :subtitleFontHeight="subtitleFontHeight" :isBasicReading="isBasicReading" 
            :isImportMerge="isImportMerge" :templateId="templateId">
          </EV3ImportUploader>
          <div class="modal-footer">
            <button class="btn btn-secondary" @click="$emit('close')">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EV3ImportUploader from '@broker/editorV3/ui/EV3ImportUploader.vue';
export default {
  components: {
    EV3ImportUploader,
  },
  props: [
    'templateId'
  ],
  data() {
    return {
      skipPages: '1',
      ignoreGrids: true,
      isActuris: true,
      isBasicReading: false,
      titleFontHeight: 10.5,
      subtitleFontHeight: 7.5,
      qHierarchy: false,
      isImportMerge: false
    };
  },
  methods: {
    assignData(data) {
      this.$emit('upload', data);
    },
  },
  computed: {
    ignoreGridsBtnText() {
      return this.ignoreGrids ? 'Ignoring Grids' : 'Including Grids';
    },
    acturisBtnText() {
      return this.isActuris ? 'Acturis Import' : 'Regular Import' ;
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.modal-container {
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  margin: 20px 0;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
