import { render, staticRenderFns } from "./EV3JsonEditorUI.vue?vue&type=template&id=50a5408a&scoped=true"
import script from "./EV3JsonEditorUI.vue?vue&type=script&lang=js"
export * from "./EV3JsonEditorUI.vue?vue&type=script&lang=js"
import style0 from "./EV3JsonEditorUI.vue?vue&type=style&index=0&id=50a5408a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50a5408a",
  null
  
)

export default component.exports