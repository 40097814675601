import Vue from 'vue';
import Router from 'vue-router';
import { authGuard } from '@shared/services/auth/authGuard.js';

// Broker area
import BrokerClientListPage from '@broker/brokerclientlist/pages/BrokerClientListPage.vue';
import AddClientPage from '@broker/brokerclientlist/pages/AddClientPage.vue';
import AddProposalClientPage from '@broker/brokerclientlist/pages/AddProposalClientPage.vue';
import PickRenewalTemplatePage from '@broker/clientProposals/pages/PickRenewalTemplatePage.vue';
import PickImportAnswersPage from '@broker/clientProposals/pages/PickImportAnswersPage.vue';
import ConfirmRenewalPage from '@broker/clientProposals/pages/ConfirmRenewalPage.vue';
import ProposalListPage from '@broker/clientProposals/pages/ProposalListPage.vue';
import FormTemplatesLibraryPage from '@broker/formsLibrary/pages/FormTemplatesLibraryPage.vue';
import AddFormToClientPage from '@broker/clientProposals/pages/AddFormToClientPage.vue';
import ImportProposalPage from '@broker/clientProposals/pages/ImportProposalPage.vue';
import ImportProposalUploadPage from '@broker/clientProposals/pages/ImportProposalUploadPage.vue';

import AiTemplateUploadPage from '@broker/aiTemplateEditor/pages/AiTemplateUploadPage.vue';

import UnauthorisedPage from '@broker/unauthorised/UnauthorisedPage.vue';
import BrokerageSettingsPage from '@broker/brokerage/pages/BrokerageSettingsPage.vue';
import ProposalWriterPage from '@broker/editorV3/pages/ProposalWriterPage.vue';

import WholesaleIntroducersListPage from '@broker/wholesale/pages/WholesaleIntroducersListPage.vue';
import WholesaleAddIntroducerPage from '@broker/wholesale/pages/WholesaleAddIntroducerPage.vue';
import WholesaleActivatePage from '@broker/wholesale/pages/WholesaleActivatePage.vue';

import ExcelExportPage from '@broker/excel/pages/ExcelExportPage.vue';

// System
import Page404 from '@shared/components/coreUi/Page404.vue';
import PuppetPrintPage from '@broker/puppet/pages/PuppetPrintPage.vue';

// Demo
import MappingEditorPage from '@broker/demo/pages/MappingEditorPage.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      component: Page404,
      beforeEnter: authGuard,
    },
    {
      path: '/unauthorised',
      name: 'Unauthorised',
      component: UnauthorisedPage,
    },
    {
      path: '/',
      name: 'Root',
      component: BrokerClientListPage,
      alias: '/home',
      beforeEnter: authGuard,
    },
    // Broker
    {
      path: '/broker/clients',
      component: BrokerClientListPage,
      beforeEnter: authGuard,
    },
    {
      path: '/broker/client/new',
      component: AddClientPage,
      beforeEnter: authGuard,
    },
    {
      path: '/broker/newproposal',
      component: AddProposalClientPage,
      beforeEnter: authGuard,
    },
    {
      path: '/broker/client/:clientId/addForm',
      component: AddFormToClientPage,
      props: ({ params }) => ({ clientId: parseInt(params.clientId) }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/client/:clientId/importProposal',
      component: ImportProposalPage,
      props: ({ params }) => ({ clientId: parseInt(params.clientId) }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/client/:clientId/importProposal/:templateId',
      component: ImportProposalUploadPage,
      props: ({ params }) => ({ clientId: parseInt(params.clientId),templateId: parseInt(params.templateId) }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/client/:clientId/proposals',
      component: ProposalListPage,
      props: ({ params }) => ({ clientId: parseInt(params.clientId) }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/proposal/:proposalId/renewal',
      component: PickRenewalTemplatePage,
      props: ({ params }) => ({ proposalId: parseInt(params.proposalId) }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/proposal/:proposalId/renewal/confirm/:templateId',
      component: ConfirmRenewalPage,
      props: ({ params }) => ({ proposalId: parseInt(params.proposalId), templateId: parseInt(params.templateId) }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/proposal/:proposalId/importAnswers',
      component: PickImportAnswersPage,
      props: ({ params }) => ({ proposalId: parseInt(params.proposalId) }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/formTemplates',
      component: FormTemplatesLibraryPage,
      beforeEnter: authGuard,
    },
    {
      path: '/broker/brokerage/editBrokerage',
      component: BrokerageSettingsPage,
      beforeEnter: authGuard,
    },

    {
      path: '/wholesale',
      component: WholesaleIntroducersListPage,
      beforeEnter: authGuard,
    },
    {
      path: '/wholesale/new',
      component: WholesaleAddIntroducerPage,
      beforeEnter: authGuard,
    },
    {
      path: '/wholesale/introducer/:id',
      component: WholesaleActivatePage,
      props: ({ params, query }) => ({
        id: parseInt(params.id),
        name: query.name,
        email: query.email,
        introducerCompany: query.introducerCompany,
        active: query.active == 'true',
      }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/excel',
      component: ExcelExportPage,
      beforeEnter: authGuard,
    },

    // System
    {
      path: '/puppet/:proposalUniqueLink',
      component: PuppetPrintPage,
      props: true,
      beforeEnter: authGuard,
    },

    // Demo
    {
      path: '/demo/mapping/:templateId',
      component: MappingEditorPage,
      props: ({ params }) => ({ templateId: parseInt(params.templateId) }),
      beforeEnter: authGuard,
    },

    //Editor version 3
    {
      path: '/broker/proposalwriter/new',
      component: ProposalWriterPage,
      props: () => ({ isNew: true }),
      beforeEnter: authGuard,
    },

    {
      path: '/broker/proposalwriter/formTemplate/:formId/edit',
      component: ProposalWriterPage,
      props: ({ params }) => ({ formId: parseInt(params.formId), isNew: false }),
      beforeEnter: authGuard,
    },

    // AI Importing
    {
      path: '/broker/aiTemplate/new',
      component: AiTemplateUploadPage,
      props: () => ({ isNew: true }),
      beforeEnter: authGuard,
    },
    {
      path: '/broker/aiTemplate/:templateId/edit',
      component: AiTemplateUploadPage,
      props: ({ params }) => ({ templateId: parseInt(params.templateId), isNew: false }),
      beforeEnter: authGuard,
    },
  ],
});
