import { render, staticRenderFns } from "./VCommentsAppendixQuestion.vue?vue&type=template&id=60bd4d9c&scoped=true"
import script from "./VCommentsAppendixQuestion.vue?vue&type=script&lang=js"
export * from "./VCommentsAppendixQuestion.vue?vue&type=script&lang=js"
import style0 from "./VCommentsAppendixQuestion.vue?vue&type=style&index=0&id=60bd4d9c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60bd4d9c",
  null
  
)

export default component.exports