<template>
  <div class="container">
    <div class="lay-options-both">
      <VEditButton class="eb-fade" @click="onBack">Back</VEditButton>
    </div>
    <hr />
    <VCompanyPicker :companies="companies" @selectedCompany="onCompanySelected"> 
    </VCompanyPicker> 
    <VChooseTemplate
      header="Choose a proposal to copy answers from"
      :templates="proposals"
      @onSelectedTemplate="onSelectedProposal"
    ></VChooseTemplate>
  </div>
</template>

<script>
import VCompanyPicker from '@broker/clientProposals/ui/VCompanyPicker.vue';
import VChooseTemplate from '@broker/clientProposals/ui/VChooseTemplate.vue';

export default {
  components: {
    VCompanyPicker,
    VChooseTemplate
  },
  props: ['proposalId'],
  data() {
    return {
      companies: [],
      selectedCompanyName:'',
      proposals:[]
    };
  },
  created() {
    this.getAllCompanies();
  },
  methods: {
    getAllCompanies() {
      this.$api.company.getAllCompanies().then(res => {
        this.companies = res.data;
      });
    },
    onBack() {
      this.$router.go(-1);
    },
    onCompanySelected(companyId){
      if(this.$debug) console.log('onCompanySelected', companyId)
      // fetch proposals for company
      this.selectedCompanyName ='';
      this.proposals = [];

      const currentFormId = this.proposalId;
      this.$api.company
        .getProposalsForClient(companyId)
        .then(res => {
          this.selectedCompanyName = res.data.name;
          this.proposals = res.data.proposals.filter(prop => currentFormId != prop.id);
        })
    },
    async onSelectedProposal(fromProposalId){
      if(this.$debug) console.log('onSelectedProposal', fromProposalId)

      //returns companyId
      let res = await this.$api.proposal.postImportAnswers(this.proposalId, fromProposalId)
      this.$router.push({ path: `/broker/client/${res.data}/proposals` });

    }
  }
}
</script>

<style>

</style>