<template>
  <div class="comment-form pt-1">
      <!-- Comment Avatar -->
      <div class="comment-avatar">
        <svg width='80' height='80' xmlns='http://www.w3.org/2000/svg' focusable='false' role='img' aria-label='Placeholder'>
          <title>Placeholder - {{author}}</title>
          <rect width='100%' height='100%' :fill='avatarColour' />
        </svg>
       
      </div>

      <form class="form" name="form" novalidate @submit.prevent="sendComment">
        <div class="form-row">
          <textarea
                    class="input"
                    :placeholder='textPlaceHolder'
                    required
                    v-model='comment'
                    :disabled='isOffline'></textarea>
        </div>
        <div class="form-row">
          <small :class="charactersLeftErrClass">Characters Left: {{charactersLeft}}</small>
          <VEditButton class='button-submit' :class='buttonClass'
            :disabled='isInvalid || isOffline'
            type='submit'
            :title="submitBtnTitle"
          >Comment</VEditButton>
        </div>
      </form>
    </div>
</template>

<script>

import VEditButton from '@shared/components/common/VEditButton.vue';

export default {
  name: 'NewComment',
  components: {VEditButton},
  data() {
    return {
      comment: ''
    };
  },
  props: {
    author: { type: String },
    isOffline: Boolean
  },
  methods: {
    sendComment() {
      if(this.$debug) console.log('sendComment', this.comment)
      this.$emit('comment', this.comment)
      this.comment = "";
    }
  },
  computed: {
    isInvalid() {
      return isEmpty(this.comment) || this.comment.length > 500;
    },
    textPlaceHolder(){
      return this.isOffline ? 'We are offline. Unable to send comments' : 'Add a new comment...'
    },
    buttonClass(){
      return this.isInvalid ? 'eb-sec': 'eb-prim'
    },
    avatarColour() {
      return this.author === 'Broker' ? '#41337a': '#6ea4bf';
    },
    charactersLeft(){
      return 500 - this.comment.length;
    },
    charactersLeftErrClass(){
      return this.comment.length > 500 ? 'text-danger': 'text-muted'
    },
    submitBtnTitle() {
      if(isEmpty(this.comment)){
        return 'Enter some text';
      }else if(this.comment.length >= 500){
        return 'Comment must be less than 500 characters';
      }else{
        return 'Submit comment'
      }
    }
  }
};

function isEmpty(str){
  return (str.length === 0 || !str.trim());
}
</script>

<style scoped>
.form{ margin-left: 100px; }
.form .form-row{ margin-bottom: 10px; }
.form .form-row:last-child{ margin-bottom: 0; }

.input{
  background-color: #fcfcfc;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .15);
  color: #555f77;
  font-family: inherit;
  font-size: 14px;
  padding: 5px 10px;
  outline: none;
  width: 100%;
  height: 100px;
  padding: 15px;

  -webkit-transition: 350ms box-shadow;
  -moz-transition: 350ms box-shadow;
  -ms-transition: 350ms box-shadow;
  -o-transition: 350ms box-shadow;
  transition: 350ms box-shadow;
}

.button-submit{
  margin-left: auto;
}

.button-tdd{
  margin-left: auto;
  padding: 6px 15px;
}

.comment-form {
  position: relative;
}

</style>
