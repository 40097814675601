<template>
  <div class="container">
    <div class="lay-options-right">
      <VEditButton class="eb-sec" @click="onGenerateReport" :disabled='downloadDisabled'>Download Answers</VEditButton>
    </div>
    <hr />
    <div class="row text-center pt-3">
      <div class="col-sm">
        <h3>Excel Export</h3>
      </div>
    </div>
    <div class="list-group pt-3 pb-3">
      <ag-grid-vue
        style="width: 100%; height: 75vh"
        class="ag-theme-alpine"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="propsals"
        rowSelection="multiple"
      @selection-changed="onSelectionChanged"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      propsals: null,
      columnDefs: null,
      downloadDisabled: true
    };
  },
  watch: {
    $route: 'getProposals'
  },
  beforeMount() {
    this.gridOptions = {};

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
    };

    this.columnDefs = [
      { headerName: 'Client Name', field: 'clientName', flex: 1, checkboxSelection: true },
      { headerName: 'Proposal Name', field: 'proposalName', flex: 1, },
    ];

    this.getProposals();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },

  methods: {
    onSelectionChanged() {
      if(this.gridApi){
        this.downloadDisabled =  this.gridApi.getSelectedRows().length === 0;
      }else{
        this.downloadDisabled = true;
      }
    },
    getProposals() {
      this.$api.excel.getAllProposals().then(res => {
        this.propsals = res.data;
      });
    },
    async onGenerateReport() {
      let selectedRows = this.gridApi.getSelectedRows();
      if (this.$debug) console.log("selectedRows",selectedRows)
      let ids = [];
      selectedRows.forEach(function (selectedRow) {
        ids.push(selectedRow.id);
      });

      await this.$api.excel.download(ids);
    },
  }
};
</script>

<style>
.header {
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
