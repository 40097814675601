import { render, staticRenderFns } from "./ImportAiUploader.vue?vue&type=template&id=6cbe6432&scoped=true"
import script from "./ImportAiUploader.vue?vue&type=script&lang=js"
export * from "./ImportAiUploader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cbe6432",
  null
  
)

export default component.exports