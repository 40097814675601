<template>
  <nav class="navbar navbar-static-top navbar-expand-lg navbar-dark bg-dark">
    <router-link class="navbar-brand" to="/">
      <img src="/assets/image/BROKIT_Logo_v2_WHITE_16x9.png" alt="" width="117" height="40" />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      v-b-toggle="'navbarSupportedContent'"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <b-collapse class="navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" :class="isClientsLinkActive ? 'active' : ''">
          <router-link class="nav-link" to="/">
            Clients
            <span v-if="isClientsLinkActive" class="sr-only">(current)</span>
          </router-link>
        </li>
        <NavBarLink to="/broker/formTemplates">Templates</NavBarLink>
        <NavBarLink to="/broker/excel">Export</NavBarLink>
      </ul>
      <div v-if="!$auth.loading">
        <span class="navbar-text mr-2" v-if="$auth.isAuthenticated">{{ $auth.user.name }}</span>
        <!-- <VEditButton class="eb-subtle" @click="goToBrokerageSettingsPage">My Brokerage Setting</VEditButton> -->
        <!-- show login when not authenticated -->
        <VEditButton v-if="!$auth.isAuthenticated" @click="login" class="eb-subtle">Sign in</VEditButton>
        <!-- show logout when authenticated -->
        <VEditButton v-if="$auth.isAuthenticated" @click="logout" class="eb-subtle">Log out</VEditButton>
      </div>

      <button class="btn btn-success my-2 my-sm-0" disabled v-if="environmentMenuBar === 'local-standalone'">
        <i class="fas fa-vials"></i> Local Standalone
      </button>
      <button class="btn btn-primary my-2 my-sm-0" disabled v-if="environmentMenuBar === 'local'">
        <i class="fas fa-flask"></i> Local
      </button>
      <button class="btn btn-primary my-2 my-sm-0" disabled v-if="environmentMenuBar === 'UAT'">
        <i class="fas fa-flask"></i> UAT
      </button>
    </b-collapse>
  </nav>
</template>

<script>
import NavBarLink from '@shared/components/coreUi/NavBarLink.vue';

export default {
  components: { NavBarLink },
  props: {
    environmentMenuBar: String,
  },
  computed: {
    isClientsLinkActive() {
      return this.$route.path === '/' || this.$route.path === '/broker/clients';
    },
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    goToBrokerageSettingsPage() {
      this.$router.push('broker/brokerage/editBrokerage');
    },
  },
};
</script>

<style scoped></style>
