import { render, staticRenderFns } from "./PreFillItem.vue?vue&type=template&id=067db9c8&scoped=true"
import script from "./PreFillItem.vue?vue&type=script&lang=js"
export * from "./PreFillItem.vue?vue&type=script&lang=js"
import style0 from "./PreFillItem.vue?vue&type=style&index=0&id=067db9c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "067db9c8",
  null
  
)

export default component.exports