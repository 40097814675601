<template>
  <div class="container">
    <div class="lay-options-right">
      <VEditButton class="eb-sec" @click="onNewProposal">New Proposal</VEditButton>
      <VEditButton class="eb-sec" @click="onNewClient">New Client</VEditButton>
    </div>
    <hr />
    <div class="row text-center pt-3">
      <div class="col-sm">
        <h3>Clients</h3>
      </div>
    </div>
    <div class="list-group pt-3 pb-3">
      <ag-grid-vue
        style="width: 100%; height: 75vh"
        class="ag-theme-alpine"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="companies"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    AgGridVue
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      companies: null,
      columnDefs: null
    };
  },
  watch: {
    $route: 'getAllCompanies'
  },
  beforeMount() {
    this.gridOptions = {};

    this.defaultColDef = {
      sortable: true,
      filter: true,
      resizable: true,
      floatingFilter: true,
    };

    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'name',
        flex: 1,
        minWidth: 250,
        cellRenderer: (params) => `<a href='/broker/client/${params.data.id}/proposals'>${params.data.name}</a>`
      },
      { headerName: 'Wholesale Introducer', field: 'introducer', flex: 1, minWidth: 150 },
      { headerName: 'Description', field: 'description', flex: 2, minWidth: 150 },
      { headerName: 'Draft', field: 'draftProposals', width: 100 },
      { headerName: 'Active', field: 'activeProposals', width: 100 },
      { headerName: 'Completed', field: 'completedProposals', width: 120 }
    ];

    this.getAllCompanies();
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  methods: {
    getAllCompanies() {
      this.$api.company.getAllCompanies().then(res => {
        this.companies = res.data;
      });
    },
    onNewClient() {
      this.$router.push({ path: '/broker/client/new' });
    },
    onNewProposal() {
      this.$router.push({ path: '/broker/newproposal' });
    },
  }
};
</script>

<style>
.header {
  margin-top: 50px;
  margin-bottom: 20px;
}
</style>
