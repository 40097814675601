import { getAuthInstance } from '@shared/services/auth/auth.js';

export const authGuard = (to, from, next) => {
  const authService = getAuthInstance();

  const checkAuth = () => {
    if (authService.isAuthenticated) {
      return next();
    }
    else if (authService.error !== null){
      return next({ name: 'Unauthorised' });
    } 
    
    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  }

  // If loading has already finished, check our auth state
  if (!authService.loading) {
    return checkAuth();
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch("loading", loading => {
    if (loading === false) {
      return checkAuth();
    }
  });
};
